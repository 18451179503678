import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  DestroyRef,
  inject,
  HostBinding,
} from '@angular/core';
import {
  TimeAllocation,
  TimesheetLine,
} from 'src/app/shared/models/entities/base/timesheet.model';
import { AppService } from 'src/app/core/app.service';
import { TimeInputType } from 'src/app/shared/models/enums/time-input-type';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { TransitionService } from '@uirouter/core';
import { ChromeService } from 'src/app/core/chrome.service';
import { AllocationInfoService } from './allocation-info.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { TimesheetCardService } from '../../core/timesheet-card.service';
import { round } from 'lodash';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { NotificationService } from 'src/app/core/notification.service';
import { StopwatchState } from 'src/app/shared/models/entities/base/stopwatch.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MessageService } from 'src/app/core/message.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

/** Сведения о ячейке таймшита. */
@Component({
  selector: 'wp-allocation-info',
  templateUrl: './allocation-info.component.html',
  styleUrls: ['./allocation-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllocationInfoComponent implements OnInit {
  @HostBinding('class.issue-mode')
  public issueMode = false;
  public issuesForm: UntypedFormArray;
  public issueTimeAllocations: TimeAllocation[];
  public containerId: string;
  public isShown = false;
  public allocation: TimeAllocation;
  public line: TimesheetLine;
  public readonly: boolean;
  public showStartButton: boolean;
  public showStopButton: boolean;
  public swExecuted: boolean;
  public isPause: boolean;
  public element = null;
  public buttonTitles: string[];
  public buttonHints: string[];
  public containerStyles: Dictionary<string> = {};
  public form: UntypedFormGroup;
  public dayScheduleDuration: number;
  public routeMode = RouteMode;

  private width = 275;
  private destroyRef = inject(DestroyRef);

  constructor(
    public service: AllocationInfoService,
    public blockUI: BlockUIService,
    public timesheetCardService: TimesheetCardService,
    public navigationService: NavigationService,
    private stopwatchService: StopwatchService,
    private changeDetector: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private app: AppService,
    private translate: TranslateService,
    private chrome: ChromeService,
    private customFieldService: CustomFieldService,
    private notification: NotificationService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dataService: DataService,
    private notificationService: NotificationService,
    transitionService: TransitionService,
  ) {
    transitionService.onSuccess({}, this.service.close);
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      hours: null,
      description: '',
    });
    this.issuesForm = this.fb.array([]);

    this.customFieldService.enrichFormGroup(this.form, 'TimeAllocation');
    // Локализация кнопочек.
    switch (this.app.session.timeInputType) {
      case TimeInputType.HoursAndMinutes:
        this.buttonTitles = ['0:15', '0:30', '1:00', '1:30', '2:00', '4:00'];
        break;

      case TimeInputType.Decimal: {
        const m = this.translate.instant(
          'timesheets.card.entryInfo.hotKeys.minutes',
        );
        const h = this.translate.instant(
          'timesheets.card.entryInfo.hotKeys.hours',
        );
        this.buttonTitles = [
          '15' + m,
          '30' + m,
          '1' + h,
          '1.5' + h,
          '2' + h,
          '4' + h,
        ];
        break;
      }
      case TimeInputType.SchedulePercent:
        this.buttonTitles = ['5%', '10%', '25%', '50%', '75%', '100%'];
        break;
    }

    switch (this.app.session.timeInputType) {
      case TimeInputType.SchedulePercent:
        this.buttonHints = this.buttonTitles;
        break;
      default:
        this.buttonHints = [
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint0_25'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint0_5'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint1'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint1_5'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint2'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint4'),
        ];
    }

    this.service.open$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((param) => {
        this.containerId = param.containerId;
        this.isShown = true;
        this.allocation = param.allocation;
        this.line = param.line;
        this.form.reset();
        this.form.patchValue(param.allocation, { emitEvent: false });
        this.readonly = param.readonly;
        this.dayScheduleDuration = param.dayScheduleDuration;
        this.issueMode = param.issueMode;

        if (this.issueMode) {
          this.issuesForm.clear();
          this.issueTimeAllocations = this.line.allTimeAllocations.filter(
            (v) => v.date === this.allocation.date,
          );

          this.issueTimeAllocations.forEach((entry) => {
            const group = this.fb.group({});
            this.customFieldService.enrichFormGroup(group, 'TimeAllocation');
            group.patchValue(entry);
            this.issuesForm.push(group);
          });

          this.issuesForm.disable();
        }

        this.readonly ? this.form.disable() : this.form.enable();

        this.updateContainer();
        this.updateStopwatchState();
      });

    this.service.close$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.close();
      });

    this.chrome.mainAreaSize$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.isShown) {
          this.updateContainer();
        }
      });

    this.chrome.scroll$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.isShown) {
          this.updateContainer();
        }
      });

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const patch: Partial<TimeAllocation> = {
          description: this.form.value.description,
        };
        this.customFieldService.assignValues(
          patch,
          this.form.value,
          'TimeAllocation',
        );
        this.service.patchAllocation(patch);
        this.changeDetector.markForCheck();
      });

    this.service.redraw$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateContainer();
      });

    this.stopwatchService.stopwatch$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateStopwatchState();
      });
  }

  // TODO: change the position update to Renderer2.
  private updateContainer(): void {
    const element = document.getElementById(this.containerId);

    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect();
    this.containerStyles['left'] =
      rect.left + rect.width / 2 - this.width + 30 + 'px';
    this.containerStyles['top'] = rect.bottom + 13 + 'px';
    this.containerStyles['width'] = this.width + 'px';

    // Если ячейка левее фиксированной таблицы - скрыть контейнер.
    const fixTableRect = document
      .querySelector('[name=left]')
      .getBoundingClientRect();
    if (fixTableRect.right >= rect.right) {
      this.close();
    }

    this.changeDetector.detectChanges();
  }

  public close(): void {
    this.isShown = false;
    this.changeDetector.markForCheck();
  }

  public updateStopwatchState(): void {
    if (!this.allocation) {
      return;
    }

    // Решаем показывать ли блок секундомера.
    this.showStopButton =
      this.stopwatchService.stopwatch?.timeSheetLineId === this.line.id &&
      this.stopwatchService.stopwatch.date === this.allocation.date;

    // Показываем кнопку запуска секундомера только на текущий день.
    this.showStartButton =
      this.app.session.useStopwatch &&
      DateTime.fromISO(this.allocation.date).equals(
        DateTime.now().startOf('day').startOf('day'),
      ) &&
      !this.stopwatchService.stopwatch;

    this.isPause =
      this.stopwatchService.stopwatch?.state === StopwatchState.Paused;

    this.changeDetector.markForCheck();
  }

  // Установить время.
  public setHours(option: number): void {
    const isPercent =
      this.app.session.timeInputType === TimeInputType.SchedulePercent;
    let hours = 0;

    switch (option) {
      case 1:
        hours = isPercent ? 5 : 0.25;
        break;
      case 2:
        hours = isPercent ? 10 : 0.5;
        break;
      case 3:
        hours = isPercent ? 25 : 1;
        break;
      case 4:
        hours = isPercent ? 50 : 1.5;
        break;
      case 5:
        hours = isPercent ? 75 : 2;
        break;
      case 6:
        hours = isPercent ? 100 : 4;
        break;
    }

    if (isPercent) {
      hours = round((this.dayScheduleDuration * hours) / 100, 4);
    }

    this.service.patchAllocation({ hours });
  }

  /*КОМАНДЫ СЕКУНДОМЕРА*/

  /** Запуск счетчика времени. */
  public start(): void {
    this.swExecuted = true;

    this.stopwatchService.start(this.line.id).then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Приостановка счетчика времени. */
  public pause(): void {
    this.swExecuted = true;
    this.stopwatchService.pause().then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Возобновление счетчика времени. */
  public resume(): void {
    this.swExecuted = true;

    this.stopwatchService.resume().then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Завершение счетчика времени. */
  public stop(type: any): void {
    this.swExecuted = true;
    this.blockUI.start();

    this.stopwatchService.stop(type).then(
      () => {
        this.blockUI.stop();
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.blockUI.stop();
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  public deleteTimeEntry(
    timeEntryId: string,
    hours: number,
    index: number,
  ): void {
    this.messageService
      .confirm(
        this.translateService.instant('shared2.messages.deleteConfirmation'),
      )
      .then(() => {
        this.dataService
          .collection('TimeAllocations')
          .entity(timeEntryId)
          .delete()
          .subscribe({
            next: () => {
              this.notificationService.successLocal(
                'shared2.messages.deleteCompleted',
              );
              this.issueTimeAllocations.splice(index, 1);
              this.issuesForm.removeAt(index);
              this.service.patchAllocation({ hours });
              this.changeDetector.detectChanges();
            },
            error: (error: Exception) => {
              this.notificationService.error(error.message);
            },
          });
      })
      .catch(() => null);
  }
}
