<wp-loading-indicator [loading]="isLoading$ | async" [isSpinnerMode]="true" />

@if (!(isLoading$ | async)) {
  @if (slotInfo.length) {
    <table class="slot-info__block table">
      <colgroup>
        <col style="width: 100px" />
        <col style="width: 110px" />
        <col style="width: 100%" />
        <col style="width: 30px" />
      </colgroup>

      <tbody class="slot-info__list">
        @for (slot of slotInfo; track slot.id) {
          <tr class="slot-info__list-item">
            <td
              class="slot-info__list-item-date link-primary text-decoration-underline"
              (click)="onEdit(slot)"
            >
              {{ slot.date | date: 'shortDate' }}
            </td>
            <td class="text-end">{{ slot.amount | wpCurrency: projectCurrencyCode }}</td>
            <td class="text-truncate slot-info__list-item-text" [title]="slot.description ?? ''">
              {{ slot.description }}
            </td>
            <td class="slot-info__list-item-remove text-center">
              @if (editAllowed(slot)) {
                <i
                  title="{{ 'shared.actions.delete' | translate }}"
                  (click)="onRemove(slot)"
                  class="bi bi-trash3"
                ></i>
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  } @else {
    <div class="slot-info__block">
      <p class="slot-info__block-title d-flex justify-content-center text-body-secondary">
        <span>{{ 'shared.noDisplayData' | translate }}</span>
      </p>
    </div>
  }
  @if (createAllowed) {
    <div class="slot-info__block">
      <p class="slot-info__block-title d-flex justify-content-end">
        <button class="btn btn-default" (click)="onCreate()">
          <i class="bi bi-plus-lg bi-15"></i>
          {{ 'shared.actions.create' | translate }}
        </button>
      </p>
    </div>
  }
}
