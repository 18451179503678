@if (!disabled) {
  <div class="input-group" ngbDropdown placement="bottom-end" (openChange)="scrollToMiddle($event)">
    <input
      ngbDatepicker
      [popperOptions]="popperOptions"
      #d="ngbDatepicker"
      class="form-control"
      title="{{ getTitle() }}"
      name="dp"
      placeholder="{{ placeholder }}"
      autocomplete="off"
      [formControl]="dateControl"
      [markDisabled]="markDisabled"
      (blur)="onBlur()"
      wpIntersectable
      (onIntersect)="closePopper()"
      (focus)="onFocus()"
      [dayTemplate]="checkIsHighlighted ? customDay : null"
    />

    <button
      class="btn btn-default input-group-text"
      (click)="d.toggle()"
      type="button"
      data-test="calendar"
    >
      <i class="bi bi-calendar3" aria-hidden="true"></i>
    </button>

    @if (includeTime) {
      <button
        type="button"
        class="btn btn-default input-group-text"
        id="timeDropdown"
        ngbDropdownToggle
      >
        <i class="bi bi-clock" aria-hidden="true"></i>
      </button>
      <div
        ngbDropdownMenu
        aria-labelledby="timeDropdown"
        class="dropdown-menu-custom"
        #dropdownMenu
      >
        @for (time of timeOptions; track time) {
          <button id="time-{{ time.replace(':', '-') }}" ngbDropdownItem (click)="updateTime(time)">
            {{ time }}
          </button>
        }
      </div>
    }
  </div>
}

@if (disabled && value) {
  <p class="trim" title="{{ getTitle() }}">
    {{ getTitle() }}
  </p>
}

@if (disabled && !value) {
  <p class="text-body-secondary text-uppercase">
    {{ 'shared.empty' | translate }}
  </p>
}

<ng-template
  #customDay
  let-date
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
>
  <div
    class="custom-day btn-light"
    [class.highlightedDay]="markHighlighted(date)"
    [class.bg-primary]="selected"
    [class.text-white]="selected"
    [class.outside]="!selected && (date.month !== currentMonth || disabled)"
  >
    {{ date.day }}
  </div>
</ng-template>
