import { Component, ChangeDetectionStrategy } from '@angular/core';

import { BoardCardService } from 'src/app/settings-app/boards/card/board-card.service';

@Component({
  selector: 'tmt-board-card-filter',
  template: `<tmt-filter-conditions-builder
    [source]="boardCardService.board()?.entityType"
    [(filters)]="boardCardService.filters"
  />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardCardFilterComponent {
  constructor(public boardCardService: BoardCardService) {}
}
