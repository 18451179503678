@if (controlValue.type === 'assignment') {
  <div
    class="resource-group"
    (mouseenter)="changeRowStateView(true)"
    (mouseleave)="changeRowStateView(false)"
    [class.disabled]="!controlValue.isActive"
  >
    <div class="collapser cursor-pointer" (click)="expandGroup(controlValue.isExpanded)">
      <span
        [ngClass]="{
          'bi bi-chevron-down': controlValue.isExpanded,
          'bi bi-chevron-right': !controlValue.isExpanded
        }"
      ></span>
    </div>

    @if (controlValue.resource?.type === resourceType.user) {
      <img class="avatar-bg" [src]="controlValue.resource.id | avatar: 60" />
      <div class="trim">
        <div
          id="{{ controlValue.resource.id }}"
          class="wp-action text"
          title="{{ controlValue.name }}"
          (click)="openUserInfo(controlValue.resource.id)"
        >
          <span id="{{ controlValue.resource.id }}-name">{{ controlValue.name }}</span>
        </div>
        <div class="role" title="{{ controlValue.role?.name }}">
          {{ controlValue.role?.name }}
        </div>
      </div>
    }

    @if (!controlValue.resource || controlValue.resource?.type === resourceType.generic) {
      @if (controlValue.name) {
        <img src="/assets/images/avatar/role.svg" />
      }
      <div class="trim">
        <div
          class="text"
          title="{{
            controlValue.name ?? 'projects.projects.card.resources.unassigned' | translate
          }}"
        >
          {{ controlValue.name ?? 'projects.projects.card.resources.unassigned' | translate }}
        </div>
        <div class="role" title="{{ controlValue.role?.name }}">
          {{ controlValue.role?.name }}
        </div>
      </div>
    }

    <wp-planner-row-commands [rowState]="rowState" />
  </div>
}

@if (controlValue.type === 'task' && controlValue.taskId) {
  <div
    (mouseenter)="changeRowStateView(true)"
    (mouseleave)="changeRowStateView(false)"
    id="{{ controlValue.id }}"
    class="task"
    [ngClass]="{ disabled: !controlValue.isActive }"
  >
    <div title="{{ controlValue.name }}" class="text">
      {{ controlValue.name }}
    </div>
    @if (rowState && controlValue.id) {
      <wp-planner-row-commands [rowState]="rowState" />
    }
  </div>
}

@if (controlValue.type === 'otherActual') {
  <div class="resource-group">
    <div
      class="resource-group__other-actual-toggle h-100 d-flex align-item justify-content-center align-items-center"
      (click)="toggleShowOtherActual()"
    >
      <span
        class="bi bi-eye-fill"
        [ngClass]="{ 'text-gray opacity-75': !projectResourceService.showOtherActual }"
      ></span>
    </div>

    <div
      class="trim text"
      title="{{ controlValue.name }}"
      [ngClass]="{ 'text-gray opacity-75': !projectResourceService.showOtherActual }"
    >
      {{ controlValue.name }}
    </div>
    @if (controlValue.verboseHint) {
      <i
        class="bi bi-question-circle text-gray d-flex ms-auto me-3"
        [ngbTooltip]="controlValue.verboseHint"
      ></i>
    }
  </div>
}
