<div class="feed__toolbar">
  <wp-filter />

  <div class="group">
    @if (!workLogService.readonly) {
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'components.workLogComponent.actions.create' | translate }}"
        (click)="workLogService.createTimeEntry()"
        data-test="create"
      >
        <i class="bi bi-plus-lg bi-15"></i>
        {{ 'components.workLogComponent.actions.create' | translate }}
      </button>
    }
  </div>
</div>

<div class="feed__content">
  <wp-loading-indicator [loading]="workLogService.isLoading()" />

  @for (item of workLogService.timeEntries(); track item.id) {
    <tmt-work-log-item [timeEntry]="item" [readonly]="workLogService.isTimeEntryReadonly(item)" />
  } @empty {
    <div class="text-center text-body-secondary pt-2">
      {{ 'shared2.messages.noDisplayData' | translate }}
    </div>
  }
</div>
