import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { filter } from 'rxjs';
import {
  ResourceGeneralCellAssignment,
  ResourceGeneralCellTask,
  ResourceGeneralCellType,
} from 'src/app/projects/card/project-resources/models/project-resources-grid-view.model';

import {
  ResourceViewGroup,
  ResourceViewGroupLine,
} from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';
import { Guid } from 'src/app/shared/helpers/guid';

@Injectable()
export class ProjectResourcesGridDataService {
  public formArray = this.fb.array([]);
  constructor(
    public dataService: ProjectResourceDataService,
    private fb: UntypedFormBuilder,
    private projectResourceService: ProjectResourceService,
  ) {
    projectResourceService.changes$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateGrid());
    projectResourceService.loading$
      .pipe(
        filter((isLoading) => isLoading),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.formArray.clear());
  }

  /**
   * Toggles the view of group lines for a given group ID.
   * If the group is expanded, it inserts form groups for each line.
   * If the group is not expanded, it removes form groups for each line.
   *
   * @param groupId The ID of the group to toggle.
   */
  public toggleGroupLineView(groupId: string): void {
    const group = this.dataService.groups.find((g) => g.id === groupId);
    const formGroupIndex = this.formArray.controls.findIndex(
      (g) => g.value.general.id === group.id,
    );
    if (group.isExpanded) {
      // If the group is expanded and has no lines, insert a single empty form group for the group.
      if (!group.lines.length) {
        this.formArray.insert(
          formGroupIndex + 1,
          this.getResourceFormGroup(
            this.prepareResourceGeneralCell('task', group),
          ),
        );
      } else {
        // If the group is expanded and has lines, insert a form group for each line.
        group.lines.forEach((line, index) => {
          const preparedGridViewLine = this.prepareResourceGeneralCell(
            'task',
            group,
            line,
          );
          this.formArray.insert(
            formGroupIndex + index + 1,
            this.getResourceFormGroup(preparedGridViewLine),
          );
        });
      }
    } else {
      // If the group is not expanded and has no lines, remove the form group for the group.
      if (!group.lines.length) {
        this.formArray.removeAt(formGroupIndex + 1);
      } else {
        // If the group is not expanded and has lines, remove the form group for each line.
        group.lines.forEach(() => {
          const formGroupIndex = this.formArray.controls.findIndex(
            (g) =>
              g.value.general.groupId === group.id &&
              g.value.general.type === 'task',
          );
          this.formArray.removeAt(formGroupIndex);
        });
      }
    }
  }

  /**
   * Updates the grid by clearing the existing form array and rebuilding it based on the current state of groups and lines.
   *
   * This method iterates through each group in the data service, prepares a form group for the group itself, and adds it to the form array.
   * If the group is expanded, it then iterates through each line in the group, prepares a form group for the line, and adds it to the form array.
   * Additionally, if the application is in forecast mode and there is an 'otherActual' group with total hours, a form group is prepared and added for this group as well.
   */
  private updateGrid(): void {
    this.formArray.clear(); // Clear the existing form array to start fresh
    this.dataService.groups.forEach((group) => {
      // Prepare and add a form group for the group itself
      const preparedGridViewGroup = this.prepareResourceGeneralCell(
        'assignment',
        group,
      );
      this.formArray.push(this.getResourceFormGroup(preparedGridViewGroup));
      // If the group is not expanded, skip adding lines
      if (!group.isExpanded) return;

      if (!group.lines.length) {
        // If the group is expanded and has no lines, insert a single empty form group for the group.
        this.formArray.push(
          this.getResourceFormGroup(
            this.prepareResourceGeneralCell('task', group),
          ),
        );
      } else {
        // Add form groups for each line in the expanded group
        group.lines.forEach((line) => {
          const preparedGridViewLine = this.prepareResourceGeneralCell(
            'task',
            group,
            line,
          );
          this.formArray.push(this.getResourceFormGroup(preparedGridViewLine));
        });
      }
    });
    // Add a form group for the 'otherActual' group if in forecast mode and it has total hours
    if (
      this.dataService.isForecastMode &&
      this.dataService.otherActualGroup?.totalHours
    ) {
      const otherActualGroup = this.prepareResourceGeneralCell(
        'otherActual',
        this.dataService.otherActualGroup,
      );
      this.formArray.push(this.getResourceFormGroup(otherActualGroup));
    }
  }

  /**
   * This method creates a form group for a resource.
   * If a group is provided, it uses the group's data. Otherwise, it initializes the form group with null values.
   *
   * @param group - The group to create the form group for. If not provided, the form group will be initialized with null values.
   * @returns The created form group.
   */
  private getResourceFormGroup(
    group?: Partial<ResourceGeneralCellAssignment | ResourceGeneralCellTask>,
  ): UntypedFormGroup {
    const formGroup = this.fb.group({
      id: Guid.generate(),
      general: group ?? null,
      totalHours: group?.totalHours ?? null,
    });
    return formGroup;
  }

  /**
   * Prepares a resource general cell based on the provided type, group, and optional line.
   *
   * This method constructs a partial object that combines properties from both ResourceGeneralCellAssignment and ResourceGeneralCellTask.
   * The properties included depend on the type parameter, which can be 'assignment', 'otherActual', or 'task'.
   *
   * @param type - The type of the resource general cell to prepare. Determines which properties are included.
   * @param group - The group to which the resource general cell belongs.
   * @param line - Optional. The line within the group to which the resource general cell belongs.
   * @returns A partial object that combines properties from ResourceGeneralCellAssignment and ResourceGeneralCellTask.
   */
  private prepareResourceGeneralCell(
    type: ResourceGeneralCellType,
    group: ResourceViewGroup,
    line?: ResourceViewGroupLine,
  ): Partial<ResourceGeneralCellAssignment & ResourceGeneralCellTask> {
    const isAssignmentOrOtherActual =
      type === 'assignment' || type === 'otherActual';
    const isTask = type === 'task';
    return {
      // Data for assignment and task types
      id: line?.id ?? group.id,
      type,
      name: isAssignmentOrOtherActual ? group.name : line?.name ?? null,
      totalHours: isAssignmentOrOtherActual
        ? group.totalHours
        : line?.totalHours ?? null,
      totalCost: isAssignmentOrOtherActual
        ? group.totalCost
        : line?.totalCost ?? null,
      extraTotal: isAssignmentOrOtherActual
        ? group.extraTotal
        : line?.extraTotal ?? null,
      isActive: isAssignmentOrOtherActual
        ? group.isActive
        : line?.isActive ?? null,

      // Data for assignment type (includes otherActual)
      verboseHint: isAssignmentOrOtherActual ? group.verboseHint : null,
      isEditable: isAssignmentOrOtherActual ? group.isEditable : null,
      isExpanded: isAssignmentOrOtherActual ? group.isExpanded : null,
      isOther: isAssignmentOrOtherActual ? group.isOther : null,
      resource: isAssignmentOrOtherActual ? group.resource : null,
      role: isAssignmentOrOtherActual ? group.role : null,
      teamMember: isAssignmentOrOtherActual ? group.teamMember : null,

      // Data for task type
      groupId: isTask ? group.id : null,
      taskId: isTask ? line?.taskId : null,
      taskNumber: isTask ? line?.taskNumber : null,
      isSummaryTask: isTask ? line?.isSummaryTask : null,
    };
  }
}
