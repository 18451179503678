import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectResourcesComponent } from './project-resources.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { UndoRedoButtonsComponent } from 'src/app/shared/services/undo-redo/undo-redo-buttons/undo-redo-buttons.component';
import { ResourceTotalCellComponent } from 'src/app/projects/card/project-resources/resource-total-cell/resource-total-cell.component';
import { MovePlanFormComponent } from 'src/app/projects/card/project-resources/move-plan-form/move-plan-form.component';
import { ProjectResourceRightSideComponent } from 'src/app/projects/card/project-resources/project-resource-right-side/project-resource-right-side.component';
import { ProjectResourcesCalendarRightGroupComponent } from 'src/app/projects/card/project-resources/project-resource-right-side/project-resources-calendar-right-group/project-resources-calendar-right-group.component';
import { ProjectResourceToolbarComponent } from 'src/app/projects/card/project-resources/project-resource-toolbar/project-resource-toolbar.component';
import { ProjectResourcesCalendarTogglerComponent } from 'src/app/projects/card/project-resources/project-resources-calendar-toggler/project-resources-calendar-toggler.component';
import { ResourceGeneralCellComponent } from 'src/app/projects/card/project-resources/resource-general-cell/resource-general-cell.component';
import { ResourceTotalHoursCellComponent } from 'src/app/projects/card/project-resources/resource-total-hours-cell/resource-total-hours-cell.component';
import { ResourcesViewFormComponent } from 'src/app/projects/card/project-resources/resources-view-form/resources-view-form.component';
import { PlannerModule } from 'src/app/shared-features/planner/planner.module';

@NgModule({
  declarations: [
    ProjectResourcesComponent,
    ResourceGeneralCellComponent,
    ResourceTotalHoursCellComponent,
    ResourceTotalCellComponent,
    MovePlanFormComponent,
    ProjectResourcesCalendarTogglerComponent,
    ResourcesViewFormComponent,
    ProjectResourcesCalendarRightGroupComponent,
    ProjectResourceRightSideComponent,
    ProjectResourceToolbarComponent,
  ],
  imports: [
    ScheduleNavigationModule,
    UndoRedoButtonsComponent,
    CommonModule,
    SharedModule,
    GridModule,
    PlannerModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.resources',
          url: '/resources',
          component: ProjectResourcesComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ProjectResourcesModule {}
