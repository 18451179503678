import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal,
} from '@angular/core';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { Exception } from 'src/app/shared/models/exception';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';

import { ProjectTaskInfo, TaskInfoParams } from './task-info.model';

@Component({
  selector: 'tmt-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskInfoComponent implements OnInit {
  @Input() public params: TaskInfoParams;

  public taskInfo: ProjectTaskInfo;
  public isLoading = signal<boolean>(true);
  public notFound = signal<boolean>(false);
  public hoursProgress = signal<{
    hours: number;
    type: 'danger' | 'info';
  } | null>(null);

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private infoPopupService: InfoPopupService,
  ) {}

  public ngOnInit(): void {
    this.dataService
      .collection('ProjectTasks')
      .entity(this.params.projectTaskId)
      .function('GetInfo')
      .get<ProjectTaskInfo>({ userId: this.params.userId })
      .subscribe({
        next: (taskInfo) => {
          this.taskInfo = taskInfo;
          const hours =
            this.taskInfo.plannedHours > 0
              ? this.taskInfo.actualHours / this.taskInfo.plannedHours
              : 0;
          this.hoursProgress.set({
            hours,
            type: hours > 1 ? 'danger' : 'info',
          });

          this.isLoading.set(false);
          this.infoPopupService.update();
        },
        error: (error: Exception) => {
          this.isLoading.set(false);

          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.notFound.set(false);
          } else {
            this.notificationService.error(error.message);
          }

          this.infoPopupService.update();
        },
      });
  }
}
