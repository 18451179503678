import { DestroyRef, inject, Inject, Injectable } from '@angular/core';
import { sumBy, uniqBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { Slot } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

import { ProjectResourcesHelper } from 'src/app/projects/card/project-resources/core/project-resources.helper';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import {
  ResourcePlanData,
  ResourcePlanEntry,
  ResourcePlanGroupData,
  ResourcePlanTaskData,
  TaskInfo,
} from 'src/app/projects/card/project-resources/models/project-resources-data.model';
import {
  ResourceViewGroup,
  ResourceViewGroupLine,
  ResourceViewGroupLineEntry,
  ResourceViewGroupLineTotal,
  ResourceViewTeamMember,
} from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ProjectResourceDataService {
  public undoRedoSessionId: string;

  // Cached Data
  public groups: ResourceViewGroup[] = [];
  public teamMembers: ResourceViewTeamMember[] = [];
  public otherActualGroup: ResourceViewGroup | null; // for forecast plan mode

  /** Prevents sending of entriesToSave to saving queueService. */
  public isAddToSavePrevented: boolean;

  private _isForecastMode: boolean;
  public get isForecastMode(): boolean {
    return this._isForecastMode;
  }
  public set isForecastMode(isForecastMode) {
    this._isForecastMode = isForecastMode;
  }

  private _readonly: boolean;
  public get readonly(): boolean {
    return this._readonly;
  }

  // Subscriptions
  private loadingSubscription: Subscription;

  // Queue
  private entriesQueueId = Guid.generate();
  private entriesToSave: ResourcePlanEntry[] = [];
  private entriesToSaveScale: PlanningScale;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public projectId,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private autosave: SavingQueueService,
    private notification: NotificationService,
    private translate: TranslateService,
  ) {}

  /** Service initialization. */
  public init(): void {
    this.autosave.delayDuration = 0;

    this.autosave.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.entriesToSave = this.entriesToSave.filter(
          (entryToSave: ResourcePlanEntry) =>
            !data.entries.some(
              (entry: ResourcePlanEntry) =>
                entry.date === entryToSave.date &&
                entry.taskId === entryToSave.taskId &&
                entry.teamMemberId === entryToSave.teamMemberId,
            ),
        );
        if (this.isAddToSavePrevented) {
          this.saveCurrentEntriesToSave();
        }
      });
  }

  /** Sends current entriesToSave to saving queue service. */
  public saveCurrentEntriesToSave(): void {
    if (!this.autosave.isSaving) {
      this.isAddToSavePrevented = false;
      if (this.entriesToSave.length) {
        this.autosave.addToQueue(
          this.entriesQueueId,
          this.versionDataService
            .projectCollectionEntity(
              this.versionCardService.projectVersion,
              this.projectId,
            )
            .action('UpdateResourcePlan')
            .execute(
              {
                scale: this.entriesToSaveScale,
                entries: this.entriesToSave,
              },
              undefined,
              {
                undoRedoSessionId: this.undoRedoSessionId,
              },
            ),
        );
      }
    }
  }

  /**
   *  Updates project task dates in the groups lines.
   *
   * @param tasks tasks for updating.
   * @param planningScale planning scale of entries view.
   */
  public updateTasksDates(
    tasks: TaskInfo[],
    planningScale: PlanningScale,
  ): void {
    tasks.forEach((task) => {
      this.groups.forEach((group) => {
        group.lines.forEach((line) => {
          if (line.taskId === task.id) {
            line.entries.forEach((entry) => {
              entry.taskStartDate = task.startDate ?? entry.taskStartDate;
              entry.taskEndDate = task.endDate ?? entry.taskEndDate;
              entry.taskDurationPercent =
                ProjectResourcesHelper.getTaskDurationPercent(
                  planningScale,
                  entry.date,
                  entry.taskStartDate,
                  entry.taskEndDate,
                );
              entry.backgroundStyle = ProjectResourcesHelper.getBackgroundStyle(
                entry.taskDurationPercent,
              );
            });
          }
        });
      });
    });
  }

  /**
   * Updates readonly state.
   *
   * @param resourcePlanEditAllowed - determines if edit allowed.
   */
  public updateReadOnly(resourcePlanEditAllowed: boolean): void {
    this._readonly =
      !resourcePlanEditAllowed ||
      !this.versionCardService.projectVersion.editAllowed;
  }

  /**
   * Loads data from specific interval.
   *
   * @param interval - interval to load data.
   * @param planningScale - data planning scale.
   * @param slots - calendar slots.
   */
  public loadFrame(
    interval: Interval,
    planningScale: PlanningScale,
    slots: Slot[],
  ): Promise<ResourceViewGroup[]> {
    return this.loadResourceGroups(interval, planningScale, slots, false);
  }

  /** Loads resources. */
  public loadResourceGroups(
    interval: Interval,
    scale: PlanningScale,
    slots: Slot[],
    rebuild = true,
  ): Promise<ResourceViewGroup[]> {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    // Save groups states
    const expandedGroupIds = this.groups
      .filter((g) => g.isExpanded)
      .map((g) => g.id);

    const params = {
      scale: `WP.PlanningScale'${scale}'`,
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
      isForecast: this.isForecastMode ? 'true' : 'false',
    };

    return new Promise((resolve, reject) => {
      this.loadingSubscription = this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.projectId,
        )
        .function('GetResourcePlan')
        .query<ResourcePlanData>(params)
        .subscribe({
          next: (planData) => {
            if (rebuild) {
              this.groups = [];
              this.otherActualGroup = null;
            }
            this.updateTeamMembers(planData);
            this.buildGroups(planData, scale, slots);

            // Return groups states
            this.groups.forEach(
              (g) => (g.isExpanded = expandedGroupIds.includes(g.id)),
            );

            resolve(this.groups);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            reject();
          },
        });
    });
  }

  /**
   * Adds entry to saving queue.
   *
   * @param entry - entry to save.
   * @param line - line entry is from.
   * @param group - group entry is from.
   * @param scale - current scale.
   */
  public addEntryToQueue(
    entry: ResourceViewGroupLineEntry,
    line: ResourceViewGroupLine,
    group: ResourceViewGroup,
    scale: PlanningScale,
  ): void {
    const queueEntry: ResourcePlanEntry = {
      date: entry.date,
      hours: entry.hours ?? null,
      cost: entry.cost,
      taskId: line.taskId,
      teamMemberId: group.teamMember.id,
    };
    if (queueEntry.hours === null) {
      queueEntry.hours = 0;
      queueEntry.cost = 0;
      // NOTE: subtract cost of deleted entry
      line.totalCost -= entry.cost;
      entry.cost = 0;
    }

    this.entriesToSave = this.entriesToSave.filter(
      (entryToSave: ResourcePlanEntry) =>
        entryToSave.date !== queueEntry.date ||
        entryToSave.teamMemberId !== queueEntry.teamMemberId ||
        entryToSave.taskId !== queueEntry.taskId,
    );
    this.entriesToSave.push(queueEntry);
    this.entriesToSaveScale = scale;

    if (!this.isAddToSavePrevented) {
      this.autosave.addToQueue(
        this.entriesQueueId,
        this.versionDataService
          .projectCollectionEntity(
            this.versionCardService.projectVersion,
            this.projectId,
          )
          .action('UpdateResourcePlan')
          .execute(
            {
              scale,
              entries: this.entriesToSave,
            },
            undefined,
            {
              undoRedoSessionId: this.undoRedoSessionId,
            },
          ),
      );
      this.isAddToSavePrevented = true;
    }
  }

  /** Trigger save all entries in queue. */
  public async save(): Promise<void> {
    await this.autosave.save();
    this.entriesToSave = [];
  }

  /**
   * Updates team members. Adds new if not present in memory.
   *
   * @param planData - server-side data.
   * @private
   */
  private updateTeamMembers(planData: ResourcePlanData): void {
    planData.teamMembers.forEach((tm) => {
      const cachedTeamMember = this.teamMembers.find((ctm) => ctm.id === tm.id);
      if (cachedTeamMember) {
        return;
      }

      const resource = tm.resourceId
        ? planData.resources.find((t) => t.id === tm.resourceId)
        : null;
      this.teamMembers.push({
        id: tm.id,
        resource,
        name: resource?.name,
      });
    });
  }

  /**
   * Builds and organizes groups based on plan data, scale, and slots.
   *
   * @param planData - The server-side data containing the plan information.
   * @param scale - The scale at which the planning is being done.
   * @param slots - An array of slots.
   */
  private buildGroups(
    planData: ResourcePlanData,
    scale: PlanningScale,
    slots: Slot[],
  ): void {
    let viewGroups: ResourceViewGroup[] = [];
    planData.groups.forEach((dataGroup) => {
      const group = this.buildGroup(planData, dataGroup);
      group.totals = this.buildTotals(planData, group, scale, slots);

      const lines = this.buildLines(planData, dataGroup, group, scale, slots);
      group.lines.push(...lines);
      group.extraTotal = sumBy(group.lines, 'extraTotal');

      const leadTaskLine = group.lines.find((l) => !l.taskNumber);
      group.lines = (leadTaskLine ? [leadTaskLine] : []).concat(
        group.lines.filter((l) => l.taskNumber).sort(naturalSort('name')),
      );
      viewGroups.push(group);
    });
    if (this.isForecastMode) {
      // Other Actual Group
      this.otherActualGroup = this.buildOtherGroup(planData, scale, slots);
    } else {
      this.otherActualGroup = null;
    }

    const unassignedGroup = viewGroups.filter((g) => !g.resource);
    const genericGroups = viewGroups
      .filter((g) => g.resource?.type === ResourceType.generic)
      .sort(naturalSort('name'));
    const userGroups = viewGroups
      .filter((g) => g.resource?.type === ResourceType.user)
      .sort(naturalSort('name'));
    viewGroups = [...unassignedGroup, ...genericGroups, ...userGroups];
    this.groups = viewGroups;
  }

  /**
   * Builds a group based on the plan data and group data.
   *
   * @param planData - The server-side data containing the plan information.
   * @param groupData - The data of the group to be built.
   * @returns The built group.
   */
  private buildGroup(
    planData: ResourcePlanData,
    groupData: ResourcePlanGroupData,
  ): ResourceViewGroup {
    const resource = planData.resources.find(
      (r) => r.id === groupData.resourceId,
    );
    const role = planData.roles.find((r) => r.id === groupData.roleId);
    const teamMember = planData.teamMembers.find(
      (tm) => tm.id === groupData.teamMemberId,
    );

    const cachedGroup = this.groups.find(
      (g) => g.id === groupData.teamMemberId,
    );
    const group =
      cachedGroup ??
      ({
        id: teamMember.id,
        name: resource?.name,
        isEditable: true,
        isActive: groupData.isActive,
        totalHours: 0,
        totalCost: 0,
        extraTotal: 0,

        resource,
        role,
        teamMember,

        lines: [],
        totals: [],
      } as ResourceViewGroup);

    const frameSchedule =
      groupData.resourceId && resource.schedule?.length
        ? resource.schedule
        : planData.fteSchedule;
    group.schedule = uniqBy(
      [...(group.schedule ?? []), ...frameSchedule],
      'date',
    );
    return group;
  }

  /**
   * Builds the total hours and costs for each slot in the group.
   *
   * @param planData - The server-side data containing the plan information.
   * @param group - The view group for which to build the totals.
   * @param scale - The current planning scale (e.g., day, week, month).
   * @param slots - The date slots for which to build the totals.
   * @returns An array of ResourceViewGroupLineTotal objects, each representing the total hours and costs for a slot.
   * @private
   */
  private buildTotals(
    planData: ResourcePlanData,
    group: ResourceViewGroup,
    scale: PlanningScale,
    slots: Slot[],
  ): ResourceViewGroupLineTotal[] {
    const totals = [];

    // Determine if we are in forecast mode and if there are any slots in the past or today.
    let isActual = this.isForecastMode
      ? slots.some(
          (slot) => slot.date.toISODate() <= DateTime.now().toISODate(),
        )
      : false;

    slots.forEach((slot) => {
      // Check if there's a cached total for this slot.
      const cachedTotal = this.isForecastMode
        ? group.totals.find(
            (ct) =>
              ct.slotId === slot.id && !totals.map((t) => t.id).includes(ct.id),
          )
        : group.totals.find((t) => t.slotId === slot.id);

      if (cachedTotal) {
        totals.push(cachedTotal);
        // If in forecast mode and the slot is today, mark it as not actual.
        if (slot.today) {
          isActual = false;
        }
        return;
      }

      // Create a new total for the slot.
      const slotDate = slot.date.toISODate();
      const total = {
        id: Guid.generate(),
        slotId: slot.id,
        date: slotDate,
        hours: 0,
        nonWorking: false,
        isActual, // For forecast mode
      } as ResourceViewGroupLineTotal;
      // If in forecast mode and the slot is today, mark it as not actual.
      if (slot.today) {
        isActual = false;
      }

      // Find the schedule hours for the slot date.
      const viewSchedule = group.schedule.find((s) => s.date === slotDate);
      total.scheduleHours = viewSchedule?.hours ?? 0;
      // Mark the slot as non-working if the scale is day and there are no schedule hours.
      if (scale === PlanningScale.Day) {
        total.nonWorking = !viewSchedule?.hours;
      }

      // Find the total FTE hours for the slot date.
      const slotTotal = group.totals.find((t) => t.date === slotDate);
      const dataFteSchedule = planData.fteSchedule.find(
        (s) => s.date === slotDate,
      );
      total.fteHours = slotTotal?.fteHours ?? dataFteSchedule?.hours;
      totals.push(total);
    });
    return totals;
  }

  /**
   * Builds lines for the view group based on plan data and slots.
   *
   * @param planData - Server-side data for the resource plan.
   * @param dataGroup - Server-side data for the resource plan group.
   * @param group - View group for which lines are being built.
   * @param scale - Current planning scale.
   * @param slots - Date slots for the planning period.
   * @returns An array of ResourceViewGroupLine objects.
   * @private
   */
  private buildLines(
    planData: ResourcePlanData,
    dataGroup: ResourcePlanGroupData,
    group: ResourceViewGroup,
    scale: PlanningScale,
    slots: Slot[],
  ): ResourceViewGroupLine[] {
    const lines = [];

    // Iterate through each task data in the data group.
    dataGroup.tasks.forEach((taskData: ResourcePlanTaskData) => {
      const task = planData.tasks.find((t) => t.id === taskData.taskId);
      const cachedLine = group.lines.find((l) => l.taskId === taskData.taskId);
      const line =
        cachedLine ??
        ({
          id: Guid.generate(),
          totalHours: taskData.totalHours,
          taskId: taskData.taskId,
          taskNumber: task.number,
          isActive: taskData.isActive,
          extraTotal: 0, // Total hours for the task outside the selected period.
          totalCost: taskData.totalCost,
          name: task.number ? task.number + ' ' + task.name : task.name,
          entries: [],
          taskStartDate: task.startDate,
          taskEndDate: task.endDate,
          isSummaryTask: task.isSummary,
        } as ResourceViewGroupLine);
      // If the line is new, add it to the lines array.
      if (!cachedLine) {
        lines.push(line);
      }
    });

    // Rebuild slots for both new and existing lines.
    [...group.lines, ...lines].forEach((line) => {
      const taskData = dataGroup.tasks.find((t) => t.taskId === line.taskId);
      const entries = this.buildEntries(
        planData,
        group,
        taskData,
        line,
        scale,
        slots,
      );
      line.extraTotal = line.totalHours - sumBy(entries, 'hours');
      line.entries = entries;
    });

    return lines;
  }

  /**
   * Builds entries for a resource view group line based on the given parameters.
   *
   * @param planData - The server-side data for the resource plan.
   * @param group - The resource view group for which the entries are being built.
   * @param taskData - The task data for the specific task being processed.
   * @param line - The resource view group line for which the entries are being built.
   * @param scale - The current planning scale.
   * @param slots - The date slots for which entries are being built.
   * @returns An array of ResourceViewGroupLineEntry objects representing the entries for the given line.
   * @private
   */
  private buildEntries(
    planData: ResourcePlanData,
    group: ResourceViewGroup,
    taskData: ResourcePlanTaskData,
    line: ResourceViewGroupLine,
    scale: PlanningScale,
    slots: Slot[],
  ): ResourceViewGroupLineEntry[] {
    const entries = [];

    // Determine if the entries should start as actual or forecast based on the current mode and slot dates.
    let isActual = this.isForecastMode
      ? slots.some(
          (slot) => slot.date.toISODate() <= DateTime.now().toISODate(),
        )
      : false;

    slots.forEach((slot) => {
      const slotDate = slot.date.toISODate();
      const cachedEntry = line.entries.find(
        (e) =>
          e.date === slotDate &&
          (!this.isForecastMode || !entries.map((x) => x.id).includes(e.id)),
      );
      if (cachedEntry) {
        entries.push(cachedEntry);
        // Adjust isActual flag for forecast mode if the current slot is today.
        if (slot.today) {
          isActual = false;
        }
        return;
      }

      const entry = {
        id: Guid.generate(),
        date: slotDate,
        hours: 0,
        cost: 0,
        isActual,
        taskStartDate: line.taskStartDate,
        taskEndDate: line.taskEndDate,
        taskDurationPercent: null,
      } as ResourceViewGroupLineEntry;

      let plannedEntry = (taskData?.planEntries ?? []).find(
        (t) => t.date === slotDate,
      );

      // Adjust plannedEntry for the current period if in forecast mode and the slot is today.
      if (this.isForecastMode && slot.today) {
        const currentPeriodEntries = (taskData?.planEntries ?? []).filter(
          (t) => t.date === slotDate,
        );

        plannedEntry = currentPeriodEntries?.find(
          (e) => !!e.isActual === isActual,
        );
      }

      if (plannedEntry) {
        entry.hours = plannedEntry.hours;
        entry.cost = plannedEntry.cost;
        entry.isActual = isActual; // Adjust isActual for forecast mode.
      }

      // Adjust isActual flag for forecast mode if the current slot is today.
      if (slot.today) {
        isActual = false;
      }

      const viewSchedule = group.schedule.find((s) => s.date === slotDate);
      entry.scheduleHours = viewSchedule?.hours ?? 0;

      // Determine non-working days based on the planning scale.
      if (scale === PlanningScale.Day) {
        entry.nonWorking = !viewSchedule?.hours;
      }

      const slotTotal = group.totals.find((t) => t.date === slotDate);
      const dataFteSchedule = planData.fteSchedule.find(
        (s) => s.date === slotDate,
      );
      entry.fteHours = slotTotal?.fteHours ?? dataFteSchedule?.hours;
      entry.limitHours = group.resource?.type === 'Generic' ? 2400 : 24;
      entries.push(entry);
    });

    return entries;
  }

  /**
   * Builds the 'Other Actual' group for the resource view.
   *
   * @param planData The resource plan data containing information about the 'Other Actual' group.
   * @param scale The planning scale used for the project.
   * @param slots The array of slots for which the 'Other Actual' group is being built.
   * @returns The constructed or updated 'Other Actual' group.
   */
  private buildOtherGroup(
    planData: ResourcePlanData,
    scale: PlanningScale,
    slots: Slot[],
  ): ResourceViewGroup {
    const otherActualGroup =
      this.otherActualGroup ??
      ({
        id: Guid.generate(),
        name: this.translate.instant(
          'projects.projects.card.resources.columns.otherActual.header',
        ),
        verboseHint: this.translate.instant(
          'projects.projects.card.resources.columns.otherActual.verboseHint',
        ),
        lines: [],
        totals: [],
        schedule: planData.fteSchedule,
        totalHours: planData.otherActual.totalHours,
        totalCost: planData.otherActual.totalCost,
        isActive: true,
        isOther: true,
      } as ResourceViewGroup);
    // Extend schedule if frame was loaded
    otherActualGroup.schedule = uniqBy(
      [...(otherActualGroup.schedule ?? []), ...planData.fteSchedule],
      'date',
    );

    otherActualGroup.totals = this.buildTotals(
      planData,
      otherActualGroup,
      scale,
      slots,
    );
    slots.forEach((slot) => {
      const actualEntries = planData.otherActual.entries.filter(
        (e) => e.date === slot.date.toISODate(),
      );
      if (!actualEntries.length) {
        return;
      }

      const actualTotals = otherActualGroup.totals.filter((t) => t.isActual);
      const actualTotalSlot = actualTotals.find(
        (at) => at.date === slot.date.toISODate(),
      );
      actualTotalSlot.hours = sumBy(actualEntries, 'hours');
      actualTotalSlot.cost = sumBy(actualEntries, 'cost');
    });
    return otherActualGroup;
  }
}
