import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PnlSettings } from '../shared/pnl-settings.model';
import { PnlStatementGroupType } from '../shared/pnl-statement-group-type.enum';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { DatePeriodType } from 'src/app/shared/models/enums/date-period-type.enum';
import { PnlStatementEntry } from '../shared/pnl-statement-entry.model';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { DateService } from 'src/app/core/date.service';
import { Subject } from 'rxjs';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { PnlService } from '../pnl.service';
import { UntypedFormBuilder } from '@angular/forms';
import { FinancePipe } from 'src/app/shared/pipes/finance.pipe';
import { PercentPipe } from '@angular/common';
import { DateTime } from 'luxon';
import { takeUntil } from 'rxjs/operators';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { AppConfigService } from 'src/app/core/app-config.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';
import { PnlStatementType } from 'src/app/shared-features/pnl/shared/pnl-statement-type.enum';
import { ProjectBillingTypes } from 'src/app/shared/models/enums/project-billing-type';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  PnlCell,
  PnlRow,
  PnlRowType,
} from 'src/app/shared-features/pnl/pnl/models/pnl-row.model';
import { PnlColumn } from 'src/app/shared-features/pnl/pnl/models/pnl-column.model';
import { PnlCurrencyMode } from 'src/app/shared-features/pnl/shared/pnl-currency-mode.enum';
import { PnlHeaderRow } from 'src/app/shared-features/pnl/pnl/models/pnl-header-row.model';
import { PnlPeriod } from 'src/app/shared-features/pnl/pnl/models/pnl-period.model';
import { PnlStatementEntryKind } from 'src/app/shared-features/pnl/shared/pnl-statement-entry-kind.enum';
import _ from 'lodash';
import { PnlStatement } from 'src/app/shared-features/pnl/shared/pnl-statement.model';
import { PnlDrillDownService } from 'src/app/shared-features/pnl/pnl/core/pnl-drill-down.service';
import { RecurringExpenseRuleDto } from 'src/app/shared/models/entities/projects/recurring-expense-rule-dto.model';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { FinancialAccountsService } from 'src/app/core/financial-accounts.service';
import { FinancialAccountType } from 'src/app/shared/models/entities/finance/financial-account-type.enum';

/**
 * Represents PnL report tab content.
 * */
@Component({
  selector: 'wp-pnl',
  templateUrl: './pnl.component.html',
  styleUrls: ['./pnl.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PnlDrillDownService],
})
export class PnlComponent implements OnInit, OnDestroy {
  @Input() showFilter: boolean;
  @Input() projectId: string;
  @Input() projectVersion: ProjectVersion;
  @Input() programId: string;
  @Input() organizationId: string;

  public get loading() {
    return this._loading;
  }

  public get thereIsNoData() {
    return this._thereIsNoData;
  }

  public get isPeriodRequired() {
    return this._isPeriodRequired;
  }

  public get headerRows() {
    return this._headerRows;
  }

  public get visibleRows() {
    return this._rows.filter(
      (row) => !row.groupId || row.isGroupExpanded !== false,
    );
  }

  public get groupTypes() {
    return this._groupTypes;
  }

  public get leftTableWidth() {
    return this._leftTableWidth;
  }

  public get isGeneral() {
    return !this.projectId && !this.programId && !this.organizationId;
  }

  public columns: Array<PnlColumn>;

  public settings: PnlSettings;
  public projectsQuery: any;

  public form = this.fb.group({
    showEstimate: false,
    showBudget: false,
    showActual: false,
    showForecast: false,
    hideExtendedPnl: true,
    hideEmptyExpenseTypes: false,
    task: null,
    project: null,
    currency: null,
  });

  accounts: FinancialAccount[];
  expenseRules: RecurringExpenseRuleDto[];
  grossMarginCells: { value: number }[];
  revenueCells: { value: number }[];
  totalExpensesCells: { value: number }[];
  capitalCostCells: { value: number }[];

  protected rowType = PnlRowType;

  private _loading = true;
  private _thereIsNoData = false;
  public _isPeriodRequired = false;
  private _showExpenseRules = false;
  private _headerRows: Array<PnlHeaderRow> = [];
  private _rows: Array<PnlRow> = [];
  private _rowIds: Dictionary<string> = {
    expensesGroup: null,
    grossMargin: null,
    extendedGrossMargin: null,
    profitability: null,
    extendedProfitability: null,
    workingCapital: null,
    outstandingCost: null,
    capitalCost: null,
    corporateTax: null,
  };
  private _groupTypes: Array<{ title: string; value: PnlStatementGroupType }>;
  private _pnlTypes: Array<{ title: string; value: PnlStatementType }>;
  private _pnlCurrencies: Array<{ title: string; value: PnlCurrencyMode }>;

  private _leftTableWidth = 274;

  private readonly localizationStrings = {
    estimate: null,
    plan: null,
    actual: null,
    forecast: null,
    quarterSuffix: null,
    total: null,
    cost: null,
    timeOff: null,
    innerExpenseLine: null,
    revenue: null,
    expenses: null,
    grossMargin: null,
    profitability: null,
    workingCapital: null,
    outstandingCost: null,
    capitalCost: null,
    corporateTax: null,
  };

  private readonly ruleDeviationBaseHints = {
    laborCost: '',
    revenue: '',
    workingCapital: '',
  };

  private maxPeriod: PnlPeriod;
  private periods: Array<PnlPeriod>;
  private pnlStatement: PnlStatement;
  private extendedKinds: string[] = [
    PnlStatementEntryKind.WorkingCapital,
    PnlStatementEntryKind.OutstandingCost,
    PnlStatementEntryKind.CapitalCost,
  ];

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();
  private reloading$ = new Subject<void>();

  constructor(
    public service: PnlService,
    private message: MessageService,
    private blockUI: BlockUIService,
    private httpClient: HttpClient,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private data: DataService,
    private notification: NotificationService,
    private dateService: DateService,
    private financePipe: FinancePipe,
    private percentPipe: PercentPipe,
    private localConfigService: LocalConfigService,
    private cdRef: ChangeDetectorRef,
    private drillDownService: PnlDrillDownService,
    private financialAccountService: FinancialAccountsService,
  ) {}

  ngOnInit(): void {
    this.initVars();
    this.settings = this.localConfigService.getConfig(PnlSettings);
    this.form.patchValue(this.settings);
    this.checkIfPeriodIsRequired();
    this.initSubscriptions();
    this.reload();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.reloading$.next();
  }

  /**
   * Tracks row cell by its id for template <code>ngFor</code>.
   *
   * @param index Row cell index.
   * @param cell Row cell.
   * @returns Cell ID.
   * */
  public trackCellById(index: number, cell: any): string {
    return cell.id;
  }

  /**
   * Gets expandable row icon CSS class.
   *
   * @param row Row.
   * @returns CSS class.
   * */
  public getExpandableRowIconClass(row: PnlRow): string {
    return row.isExpanded === true ? 'bi-chevron-down' : 'bi-chevron-right';
  }

  /**
   * Row icon expand click event handler.
   * Toggles expandable row and its dependent rows expanded state.
   *
   * @param row Expandable row.
   * */
  public onGroupExpand(row: PnlRow): void {
    row.isExpanded = !row.isExpanded;
    this._rows
      .filter((r) => !!r.groupId && r.groupId === row.id)
      .forEach((r) => (r.isGroupExpanded = row.isExpanded));
  }

  /**
   * Gets localized PnL group type option by its value.
   *
   * @param groupType PnL group type option value.
   * @returns Localized option title.
   * */
  public getGroupTypeTitle(groupType?: PnlStatementGroupType): string {
    return this._groupTypes.find((v) => v.value === groupType)?.title;
  }

  /**
   * Gets localized PnL type option by its value.
   *
   * @param pnlType PnL type option value.
   * @returns Localized option title.
   * */
  public getPnlTypeTitle(pnlType: PnlStatementType): string {
    return this._pnlTypes.find((v) => v.value === pnlType)?.title;
  }

  /**
   * Gets localized PnL currency option title by its value.
   *
   * @param currencyMode PnL currency option value.
   * @returns Localized option title.
   * */
  public getPnlCurrencyTitle(currencyMode: PnlCurrencyMode): string {
    return this._pnlCurrencies.find((v) => v.value === currencyMode)?.title;
  }

  /**
   * PnL Excel report download button click event handler.
   * Calls Excel report API and runs file download.
   * */
  public downloadPnlReport() {
    const methodName = !this.isGeneral
      ? 'GetPnlStatementsExcel'
      : 'GetGeneralPnlStatementsExcel';
    const url = `${AppConfigService.config.api.url}/PnlReport/${methodName}`;

    this.blockUI.start();

    const filter = this.getFilter();

    this.httpClient
      .post(
        url,
        {
          groupType: this.settings.groupType,
          pnlType: this.settings.pnlType,
          includeEstimate: this.settings.showEstimate,
          includeBudget: this.settings.showBudget,
          includeActual: this.settings.showActual,
          includeForecast: this.settings.showForecast,
          showExtended: !this.settings.hideExtendedPnl,
          hideEmptyExpenses: this.settings.hideEmptyExpenseTypes,
          showInBaseCurrency:
            !this.projectId || this.settings.currency === PnlCurrencyMode.base,
          filter: {
            from: filter.from,
            to: filter.to,
            projectId: filter.projectId,
            projectVersionId: filter.projectVersionId,
            projectTaskId: filter.projectTaskId,
            programId: filter.programId,
            organizationId: filter.organizationId,
            projectStateIds: filter.projectStateIds ?? [],
            billingTypeIds: filter.billingTypeIds,
          },
        },
        {
          responseType: 'blob',
        },
      )
      .subscribe({
        next: (data) => {
          saveAs(data, `P&L Report.xlsx`);
          this.blockUI.stop();
        },
        error: async (error) => {
          const isPromise = !!error && typeof error.then === 'function';

          if (isPromise) {
            const errorText = await error;
            const errorObj = JSON.parse(errorText);
            this.message.error(errorObj.error.message);
            this.blockUI.stop();
          } else {
            this.message.error(error.message);
            this.blockUI.stop();
          }
        },
      });
  }

  /**
   * Saves updated PnL group type option in settings and reloads tab.
   *
   * @param groupType Updated PnL group type option.
   * */
  public setGroupType(groupType: any) {
    this.settings.groupType = groupType;
    this.saveSettings();
    this.reload();
  }

  /**
   * Saves updated PnL currency option in settings and reloads tab.
   *
   * @param currency Updated PnL currency option.
   * */
  public setPnlCurrency(currency: any) {
    this.settings.currency = currency;
    this.saveSettings();
    this.reload();
  }

  /**
   * Saves updated PnL type option in settings and reloads tab.
   *
   * @param pnlType Updated PnL type option.
   * */
  public setPnlType(pnlType: any) {
    this.settings.pnlType = pnlType;
    this.saveSettings();
    this.reload();
  }

  /**
   * PnL filter change event handler.
   * Saves updated filter in settings and reloads tab.
   *
   * @param filter Updated filter.
   * */
  public onFilterChange(filter: any) {
    this.settings.filter = filter;
    this.checkIfPeriodIsRequired();
    this.saveSettings();
    if (!this._isPeriodRequired) {
      this.reload();
    }
  }

  /**
   * Inits variables.
   * */
  private initVars(): void {
    this._showExpenseRules = !!this.projectId;
    Object.keys(this._rowIds).forEach((key) => {
      this._rowIds[key] = Guid.generate();
    });
    this._groupTypes = [
      null,
      PnlStatementGroupType.Month,
      PnlStatementGroupType.Quarter,
      PnlStatementGroupType.Year,
    ].map((v) => ({
      title: this.translate.instant(
        `shared.pnlStatement.groupType.${_.camelCase(v ?? 'without')}`,
      ),
      value: v,
    }));
    this._pnlTypes = [PnlStatementType.DocumentDate, PnlStatementType.Date].map(
      (v) => ({
        title: this.translate.instant(
          `shared.pnlStatement.pnlType.${_.camelCase(v)}`,
        ),
        value: v,
      }),
    );
    this._pnlCurrencies = [PnlCurrencyMode.base, PnlCurrencyMode.project].map(
      (v) => ({
        title: this.translate.instant(
          `shared.pnlStatement.currency.${_.camelCase(v)}`,
        ),
        value: v,
      }),
    );
    Object.keys(this.localizationStrings).forEach((key) => {
      this.localizationStrings[key] = this.translate.instant(
        `shared.pnlStatement.${key}`,
      );
    });
    Object.keys(this.ruleDeviationBaseHints).forEach((key) => {
      this.ruleDeviationBaseHints[key] = this.translate.instant(
        `shared.pnlStatement.calculationBaseHint.${key}`,
      );
    });

    if (this.programId) {
      this.projectsQuery = {
        filter: {
          programId: { type: 'guid', value: this.programId },
        },
      };
    }

    if (this.organizationId) {
      this.projectsQuery = {
        filter: {
          organizationId: { type: 'guid', value: this.organizationId },
        },
      };
    }
  }

  /**
   * Inits subscriptions.
   * */
  private initSubscriptions(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.settings.showEstimate = this.form.value.showEstimate;
      this.settings.showBudget = this.form.value.showBudget;
      this.settings.showActual = this.form.value.showActual;
      this.settings.showForecast = this.form.value.showForecast;
      this.settings.hideExtendedPnl = this.form.value.hideExtendedPnl;
      this.settings.hideEmptyExpenseTypes =
        this.form.value.hideEmptyExpenseTypes;
      this.saveSettings();
      this.reload();
    });

    this.service.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.cdRef.detectChanges());

    this.service.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  /**
   * Saves PnL settings in Local storage.
   * */
  private saveSettings() {
    this.localConfigService.setConfig(PnlSettings, this.settings);
  }

  /**
   * Reloads tab.
   * */
  private reload() {
    if (this._isPeriodRequired) {
      return;
    }

    this.reloading$.next();
    this._loading = true;
    this._thereIsNoData = false;
    this.service.detectChanges();

    const expandedRows: Dictionary<boolean> = {};
    this.saveRowExpandedStates(expandedRows);

    const groupType = this.settings.groupType
      ? `WP.PnlStatementGroupType'${this.settings.groupType}'`
      : null;
    const pnlType = `WP.PnlStatementType'${this.settings.pnlType}'`;

    const filterObject = this.getFilter();

    const urlParams = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@filter': JSON.stringify(filterObject),
    };

    const params: Dictionary<any> = {
      groupType,
      pnlType,
      includeEstimate: this.settings.showEstimate,
      includeBudget: this.settings.showBudget,
      includeActual: this.settings.showActual,
      includeForecast: this.settings.showForecast,
      showInBaseCurrency:
        !this.projectId || this.settings.currency === PnlCurrencyMode.base,
      showExtended: !this.settings.hideExtendedPnl,
      filter: '@filter',
    };

    const methodName = !this.isGeneral
      ? 'GetPnlStatement'
      : 'GetGeneralPnlStatement';

    this.data.model
      .function(methodName)
      .get<PnlStatement>(params, null, urlParams)
      .pipe(takeUntil(this.reloading$))
      .subscribe({
        next: (pnlStatement) => {
          this.pnlStatement = pnlStatement;
          this.accounts = this.pnlStatement.accounts
            .filter(
              (account) =>
                ![
                  FinancialAccount.laborCostId,
                  FinancialAccount.timeOffCostId,
                ].includes(account.id) &&
                account.includedInBalance &&
                account.typeId === FinancialAccountType.expenses.id,
            )
            .slice();
          this.expenseRules = this.pnlStatement.expenseRules.slice();

          if (this.settings.hideEmptyExpenseTypes) {
            this.accounts = this.accounts.filter((t) =>
              this.pnlStatement.entries.some((e) => e.accountId === t.id),
            );
            this.expenseRules = this.expenseRules.filter((r) =>
              this.pnlStatement.entries.some((e) => e.expenseRuleId === r.id),
            );
          }

          this._thereIsNoData =
            !this.pnlStatement.entries.length &&
            !this.pnlStatement.extendedEntries.length;
          this._loading = false;
          this.buildStatement();
          if (!this._thereIsNoData) {
            this.restoreRowExpandedStates(expandedRows);
          }
          this.service.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this._loading = false;
          this.service.detectChanges();
        },
      });
  }

  /**
   * Saves rows expanded state in dictionary.
   *
   * @param expandedRows Rows state dictionary.
   * */
  private saveRowExpandedStates(expandedRows: Dictionary<boolean>): void {
    this._rows
      .filter(
        (row) =>
          row.type === PnlRowType.expandableGroupItem &&
          row.isExpanded === true,
      )
      .forEach((row) => {
        expandedRows[row.id] = true;
      });
  }

  /**
   * Restores rows expanded state from dictionary.
   *
   * @param expandedRows Rows state dictionary.
   * */
  private restoreRowExpandedStates(expandedRows: Dictionary<boolean>): void {
    this._rows
      .filter((row) => row.type === PnlRowType.expandableGroupItem)
      .forEach((row) => {
        if (expandedRows[row.id] === true) {
          this.onGroupExpand(row);
        }
      });
  }

  /**
   * Builds PnL report table.
   * */
  private buildStatement(): void {
    this._rows = [];
    this.fillPeriods();
    this.buildHeader();

    if (!this.columns.length) {
      this._thereIsNoData = true;
    }

    if (this._thereIsNoData) {
      return;
    }

    // Revenue values (for Profitability calculation).
    this.revenueCells = this.getCellsArray();
    // Total Expenses values.
    this.totalExpensesCells = this.getCellsArray();
    this.grossMarginCells = this.getCellsArray();
    this.capitalCostCells = this.getCellsArray();

    this.buildRow(
      PnlStatementEntryKind.Revenue,
      null,
      null,
      null,
      this.localizationStrings.revenue,
    );

    this._rows.push(this.getSeparatorRow());

    const groupHeaderRow: PnlRow = {
      id: this._rowIds.expensesGroup,
      type: PnlRowType.group,
      header: this.localizationStrings.expenses,
      cells: [],
    };

    this._rows.push(groupHeaderRow);

    this.buildLaborAndTimeOffRows(true);

    this.accounts.sort(naturalSort('name')).forEach((account) => {
      const rowId = this.buildRow(
        PnlStatementEntryKind.Expenses,
        null,
        account.id,
        null,
        account.name,
      );

      if (
        this._showExpenseRules &&
        this.expenseTypeRowHasRules(account.id, false)
      ) {
        this.buildExpenseRuleRows(rowId, account.id);
      }
    });

    groupHeaderRow.cells = this.totalExpensesCells.map((cell) => ({
      id: Guid.generate(),
      displayValue: this.financePipe.transform(cell.value),
    }));

    this._rows.push(this.getSeparatorRow());

    const balanceCells = this.grossMarginCells.map((cell) => ({
      id: Guid.generate(),
      value: cell.value,
    }));
    const grossMarginRow: PnlRow = {
      id: this._rowIds.grossMargin,
      type: PnlRowType.grossMargin,
      header: this.localizationStrings.grossMargin,
      cells: balanceCells.map((cell) => ({
        id: cell.id,
        displayValue: this.financePipe.transform(cell.value),
      })),
    };

    this._rows.push(grossMarginRow);

    const profitabilityRow: PnlRow = {
      id: this._rowIds.profitability,
      type: PnlRowType.profitability,
      header: this.localizationStrings.profitability,
      cells: this.revenueCells.map((cell, index) => ({
        id: Guid.generate(),
        displayValue:
          cell.value > 0
            ? this.percentPipe.transform(
                this.grossMarginCells[index].value / cell.value,
                '0.2-2',
              )
            : '',
      })),
    };

    this._rows.push(profitabilityRow);

    if (!this.settings.hideExtendedPnl) {
      this._rows.push(this.getSeparatorRow());

      this.buildLaborAndTimeOffRows(false);

      this.pnlStatement.accounts
        .filter(
          (account) =>
            !account.includedInBalance &&
            account.typeId === FinancialAccountType.expenses.id &&
            ![
              FinancialAccount.laborCostId,
              FinancialAccount.timeOffCostId,
            ].includes(account.id),
        )
        .sort(naturalSort('name'))
        .forEach((account) => {
          const rowId = this.buildRow(
            PnlStatementEntryKind.Expenses,
            null,
            account.id,
            null,
            account.name,
            true,
          );

          if (
            this._showExpenseRules &&
            this.expenseTypeRowHasRules(account.id, true)
          ) {
            this.buildExpenseRuleRows(rowId, account.id, true);
          }
        });

      this.extendedKinds.forEach((kind) =>
        this.buildRow(
          PnlStatementEntryKind[kind],
          null,
          null,
          null,
          this.localizationStrings[_.lowerFirst(kind)],
          true,
        ),
      );

      this._rows.push(this.getSeparatorRow());

      const extendedGrossMarginRow: PnlRow = {
        id: this._rowIds.extendedGrossMargin,
        type: PnlRowType.extendedGrossMargin,
        header: this.localizationStrings.grossMargin,
        cells: balanceCells.map((cell, index) => ({
          id: Guid.generate(),
          displayValue: this.financePipe.transform(
            cell.value + this.capitalCostCells[index].value,
          ),
        })),
      };

      this._rows.push(extendedGrossMarginRow);

      const extendedProfitabilityRow: PnlRow = {
        id: this._rowIds.extendedProfitability,
        type: PnlRowType.extendedProfitability,
        header: this.localizationStrings.profitability,
        cells: balanceCells.map((cell, index) => ({
          id: Guid.generate(),
          displayValue:
            this.revenueCells[index].value > 0
              ? this.percentPipe.transform(
                  (cell.value + this.capitalCostCells[index].value) /
                    this.revenueCells[index].value,
                  '0.2-2',
                )
              : '',
        })),
      };

      this._rows.push(extendedProfitabilityRow);
    }
  }

  /**
   * Gets new PnL report separator row.
   *
   * @returns Separator row.
   * */
  private getSeparatorRow(): PnlRow {
    return {
      id: Guid.generate(),
      type: PnlRowType.separator,
      header: '',
      cells: this.columns.map(
        () =>
          <PnlCell>{
            id: Guid.generate(),
            displayValue: '',
          },
      ),
    };
  }

  /**
   * Builds PnL report header.
   * */
  private buildHeader() {
    this._headerRows = [];
    this.columns = [];
    const typesCount = this.getTypesCount();
    const hasPeriodTypes =
      typesCount > 1 || (!this.settings.groupType && typesCount === 1);

    // Add periods to header.
    if (this.settings.groupType) {
      const periodsRow: PnlHeaderRow = {
        id: Guid.generate(),
        cells: [],
      };
      this.periods.forEach((period) => {
        periodsRow.cells.push({
          id: Guid.generate(),
          header: this.getFormattedPeriod(period.date),
          period: period.iso,
          colspan: this.getColSpan(hasPeriodTypes, period),
        });
      });

      // Add Total column.
      periodsRow.cells.push({
        id: Guid.generate(),
        header: this.localizationStrings.total,
        colspan: this.getColSpan(hasPeriodTypes),
      });

      this._headerRows.push(periodsRow);
    }

    // Add period types to header.
    if (hasPeriodTypes) {
      const periodTypesRow: PnlHeaderRow = {
        id: Guid.generate(),
        cells: [],
      };

      if (this.settings.groupType) {
        this.periods.forEach((period) => {
          this.addTypes(period, periodTypesRow);
        });
      }

      // Add Total column (if periods selected) or all values without periods.
      this.addTypes(null, periodTypesRow);

      this._headerRows.push(periodTypesRow);
    } else {
      if (this.settings.groupType) {
        this.periods.forEach((period) => {
          this.addTypes(period);
        });
      }

      // Add Total column.
      this.addTypes(null);
    }
  }

  /**
   * Builds PnL report expense type inner line and rule rows.
   *
   * @param groupId Expense type row ID.
   * @param accountId Expense type ID.
   * @param isExtendedEntries Indicates whether to use extended entries.
   * */
  private buildExpenseRuleRows(
    groupId: string,
    accountId: string | null,
    isExtendedEntries = false,
  ): void {
    const entries = isExtendedEntries
      ? this.pnlStatement.extendedEntries
      : this.pnlStatement.entries;
    const ruleIds = _.uniq(
      entries
        .filter(
          (e) =>
            e.accountId === accountId &&
            e.kind === PnlStatementEntryKind.ExpenseRule,
        )
        .map((e) => e.expenseRuleId),
    );
    const rules = this.expenseRules.filter((r) =>
      ruleIds.some(
        (id) =>
          id === r.id &&
          (id === r.crossId ||
            (!this.projectVersion && r.projectId === this.projectId) ||
            (!!this.projectVersion && r.versionId === this.projectVersion.id)),
      ),
    );
    const hasInnerLine = entries.some(
      (e) =>
        e.accountId === accountId &&
        e.kind === PnlStatementEntryKind.InnerExpenseLine,
    );

    if (hasInnerLine) {
      this.buildRow(
        PnlStatementEntryKind.InnerExpenseLine,
        groupId,
        accountId,
        null,
        this.localizationStrings.innerExpenseLine,
        isExtendedEntries,
      );
    }

    rules.sort(naturalSort('name')).forEach((rule) => {
      this.buildRow(
        PnlStatementEntryKind.ExpenseRule,
        groupId,
        accountId,
        rule,
        rule.name,
        isExtendedEntries,
      );
    });
  }

  /**
   * Builds PnL report row.
   *
   * @param kind Entry kind.
   * @param groupId Row group ID (for expansion).
   * @param accountId Expense type ID.
   * @param expenseRule Expense rule.
   * @param header Localized row header.
   * @param isExtendedEntries Indicates whether to use extended entries.
   * @returns New row ID.
   * */
  private buildRow(
    kind: PnlStatementEntryKind,
    groupId: string | null,
    accountId: string | null,
    expenseRule: RecurringExpenseRuleDto | null,
    header: string,
    isExtendedEntries = false,
  ): string {
    const isExpenseTypeRow =
      kind === PnlStatementEntryKind.Expenses && !!accountId;
    const isGroupRow =
      isExpenseTypeRow ||
      kind === PnlStatementEntryKind.LaborCost ||
      kind === PnlStatementEntryKind.TimeOffCost;
    let rowId = Guid.generate();
    let rowType = PnlRowType.row;

    if (isGroupRow || isExpenseTypeRow) {
      rowType =
        isExpenseTypeRow &&
        this._showExpenseRules &&
        this.expenseTypeRowHasRules(accountId, isExtendedEntries)
          ? PnlRowType.expandableGroupItem
          : PnlRowType.groupItem;
      rowId = isExpenseTypeRow ? accountId : rowId;
    } else if (this.extendedKinds.includes(kind)) {
      rowId = this._rowIds[_.lowerFirst(kind)];
      rowType = PnlRowType[_.lowerFirst(kind)];
    }

    const statementRow: PnlRow = {
      id: rowId,
      type: rowType,
      header,
      groupId,
      cells: [],
      isExtendedGroup: isExtendedEntries,
    };

    if (isExpenseTypeRow) {
      statementRow.isExpanded = false;
    }

    if (groupId) {
      statementRow.isGroupExpanded = false;
    }

    for (
      let columnIndex = 0;
      columnIndex < this.columns.length;
      columnIndex++
    ) {
      if (!this.columns[columnIndex]) {
        return;
      }

      const expenseRuleCrossId = expenseRule?.crossId ?? null;
      const date = this.columns[columnIndex].period;
      const type = this.columns[columnIndex].type;
      const filteredEntries = (
        isExtendedEntries
          ? this.pnlStatement.extendedEntries
          : this.pnlStatement.entries
      ).filter((entry) => {
        if ((date && entry.date !== date) || (type && entry.type !== type)) {
          return false;
        }

        return (
          entry.kind === kind &&
          (!accountId || entry.accountId === accountId) &&
          (!expenseRuleCrossId ||
            entry.expenseRuleCrossId === expenseRuleCrossId)
        );
      });

      let value = this.getCellValue(filteredEntries, kind);

      /** Don't show outstanding cost and working capital values in Total column. */
      if (
        this.settings.groupType &&
        !this.columns[columnIndex].period &&
        (kind === PnlStatementEntryKind.OutstandingCost ||
          kind === PnlStatementEntryKind.WorkingCapital)
      ) {
        value = null;
      }

      const fn = this.drillDownService.getDrillDownFn(
        this.settings.pnlType,
        this.settings.groupType,
        kind,
        this.columns[columnIndex].type,
        this.settings.currency,
        this.columns[columnIndex].period,
        this.isGeneral ? this.settings.filter : null,
        this.projectId,
        this.organizationId,
        this.programId,
        this.form.value.task?.id,
        accountId,
      );

      const cell: PnlCell = {
        id: Guid.generate(),
        fn,
        displayValue: this.financePipe.transform(value),
      };

      if (this._showExpenseRules) {
        cell.deviatesFromRuleBase = filteredEntries.some(
          (e) => e.deviatesFromRuleBase === true,
        );
        cell.baseHint = cell.deviatesFromRuleBase
          ? this.ruleDeviationBaseHints[
              _.camelCase(expenseRule.calculationBase)
            ] ?? ''
          : null;
      }

      statementRow.cells.push(cell);

      if (value !== null) {
        if (kind === PnlStatementEntryKind.Revenue) {
          // Add value to Revenue row.
          if (this.revenueCells[columnIndex].value === null) {
            this.revenueCells[columnIndex].value = 0;
          }
          this.revenueCells[columnIndex].value += value;
        } else if (kind === PnlStatementEntryKind.CapitalCost) {
          // Add values to capital cost cells.
          if (this.capitalCostCells[columnIndex].value === null) {
            this.capitalCostCells[columnIndex].value = 0;
          }
          this.capitalCostCells[columnIndex].value += value;
        } else if (isGroupRow) {
          // Add value to Total Expenses row.
          this.totalExpensesCells[columnIndex].value += value;
        }

        if (
          kind !== PnlStatementEntryKind.InnerExpenseLine &&
          kind !== PnlStatementEntryKind.ExpenseRule
        ) {
          if (this.grossMarginCells[columnIndex].value === null) {
            this.grossMarginCells[columnIndex].value = 0;
          }
          this.grossMarginCells[columnIndex].value += value;
        }
      }
    }

    this._rows.push(statementRow);

    return statementRow.id;
  }

  /**
   * Adds types for specific period to PnL report complex header row.
   *
   * @param period PnL column period. Not passed for Total column.
   * @param headerRow PnL header row. Not passed for empty group type option.
   * */
  private addTypes(period?: PnlPeriod, headerRow?: PnlHeaderRow): void {
    if (this.settings.showEstimate) {
      if (headerRow) {
        headerRow.cells.push({
          id: Guid.generate(),
          header: this.localizationStrings.estimate,
        });
      }
      this.columns.push({
        period: period?.iso,
        type: KpiType.Estimate,
      });
    }

    if (this.settings.showBudget) {
      if (headerRow) {
        headerRow.cells.push({
          id: Guid.generate(),
          header: this.localizationStrings.plan,
        });
      }
      this.columns.push({
        period: period?.iso,
        type: KpiType.Plan,
      });
    }

    if (this.settings.showActual) {
      if (headerRow) {
        headerRow.cells.push({
          id: Guid.generate(),
          header: this.localizationStrings.actual,
        });
      }
      this.columns.push({
        period: period?.iso,
        type: KpiType.Actual,
      });
    }

    if (
      this.settings.showForecast &&
      (!this.settings.groupType || !this.isPeriodInThePast(period))
    ) {
      if (headerRow) {
        headerRow.cells.push({
          id: Guid.generate(),
          header: this.localizationStrings.forecast,
        });
      }
      this.columns.push({
        period: period?.iso,
        type: KpiType.Forecast,
      });
    }
  }

  /**
   * Gets column span value for PnL header row cell.
   *
   * @param hasPeriodTypes Indicates whether group type is selected or any data type option is selected or not.
   * @param period PnL column period. Not passed for Total column.
   * @returns Column span value.
   * */
  private getColSpan(hasPeriodTypes: boolean, period?: PnlPeriod): number {
    if (!hasPeriodTypes) {
      return 1;
    } else {
      return this.getTypesCount(period);
    }
  }

  /**
   * Gets data type option count.
   *
   * @param period PnL column period. Not passed for Total column.
   * @returns Data type option count.
   * */
  private getTypesCount(period?: PnlPeriod): number {
    let count = 0;

    if (this.settings.showEstimate) {
      count++;
    }
    if (this.settings.showActual) {
      count++;
    }
    if (this.settings.showBudget) {
      count++;
    }
    if (
      this.settings.showForecast &&
      (!period || !this.isPeriodInThePast(period))
    ) {
      count++;
    }

    return count;
  }

  /**
   * Gets PnL report row cell aggregated amount value from PnL statement entries.
   *
   * @param filteredEntries Cell PnL statement entries.
   * @param kind Entry kind.
   * @returns Aggregated amount value.
   * */
  private getCellValue(
    filteredEntries: PnlStatementEntry[],
    kind: PnlStatementEntryKind,
  ): number {
    const value =
      !this.extendedKinds.includes(kind) ||
      kind === PnlStatementEntryKind.CorporateTax ||
      kind === PnlStatementEntryKind.CapitalCost
        ? filteredEntries.reduce((acc, x) => acc + x.amount, 0)
        : filteredEntries.reverse()[0]?.amount ?? 0;

    return kind === PnlStatementEntryKind.Revenue ||
      this.extendedKinds.includes(kind)
      ? value
      : value * -1;
  }

  /**
   * Gets PnL row cell formatted period.
   *
   * @param date PnL column period date.
   * @returns Formatted period.
   * */
  private getFormattedPeriod(date: DateTime): string {
    switch (this.settings.groupType) {
      case PnlStatementGroupType.Month:
        return date.toFormat('LLLL yyyy');
      case PnlStatementGroupType.Quarter:
        return `${date.quarter}${
          this.localizationStrings.quarterSuffix
        } ${date.toFormat('yyyy')}`;
      case PnlStatementGroupType.Year:
        return date.toFormat('yyyy');
      default:
        return '';
    }
  }

  /**
   * Gets empty PnL report row cell array.
   *
   * @returns Empty cell array.
   * */
  private getCellsArray(): { value: number }[] {
    const arr = [];
    this.columns.forEach(() => {
      arr.push({ value: null });
    });
    return arr;
  }

  /**
   * Determines whether period is in the past or not.
   *
   * @param period PnL column period. Not passed for Total column.
   * @returns <code>true</code> if period is defined and is in the past, <code>false</code> otherwise.
   * */
  private isPeriodInThePast(period: PnlPeriod): boolean {
    let date = period?.date ?? this.maxPeriod?.date;
    if (!date) {
      return false;
    }

    switch (this.settings.groupType) {
      case PnlStatementGroupType.Month:
        date = date.plus({ months: 1 });
        break;
      case PnlStatementGroupType.Quarter:
        date = date.plus({ quarters: 1 });
        break;
      case PnlStatementGroupType.Year:
        date = date.plus({ years: 1 });
        break;
    }

    return date < DateTime.now();
  }

  /**
   * Fills periods to show in header.
   * */
  private fillPeriods(): void {
    this.periods = [];
    this.maxPeriod = null;
    if (!this.settings.groupType) {
      return;
    }

    const datePeriod = this.pnlStatement.datePeriod;
    const minDate = datePeriod.from;
    const maxDate = datePeriod.to;

    const minPeriod: PnlPeriod = {
      iso: minDate,
      date: DateTime.fromISO(minDate),
    };
    const maxPeriod: PnlPeriod = {
      iso: maxDate,
      date: DateTime.fromISO(maxDate),
    };

    const currentPeriod = minPeriod;

    while (currentPeriod.date <= maxPeriod.date) {
      if (
        this.settings.showEstimate ||
        this.settings.showBudget ||
        this.settings.showActual ||
        !this.isPeriodInThePast(currentPeriod)
      ) {
        this.periods.push(Object.assign({}, currentPeriod));
      }

      switch (this.settings.groupType) {
        case PnlStatementGroupType.Month:
          currentPeriod.date = currentPeriod.date.plus({ months: 1 });
          break;
        case PnlStatementGroupType.Quarter:
          currentPeriod.date = currentPeriod.date.plus({ quarters: 1 });
          break;
        case PnlStatementGroupType.Year:
          currentPeriod.date = currentPeriod.date.plus({ years: 1 });
          break;
      }
      currentPeriod.iso = currentPeriod.date.toISODate();
    }

    this.maxPeriod = maxPeriod;
  }

  /**
   * Gets PnL filter object initialized by Local storage settings and component input parameters.
   *
   * @returns PnL filter object.
   * */
  private getFilter(): any {
    const filterObject = {
      from: null,
      to: null,
      programId: null,
      projectId: null,
      projectVersionId: null,
      projectTaskId: null,
      organizationId: null,
      projectStateIds: [],
      billingTypeIds: [],
    };

    if (this.showFilter) {
      filterObject.programId = this.settings.filter.program?.id;
      filterObject.projectId = this.settings.filter.project?.id;
      filterObject.projectTaskId = this.settings.filter.task?.id;
      filterObject.organizationId = this.settings.filter.client?.id;
      filterObject.projectStateIds =
        this.settings.filter.projectStates?.map((state) => state.id) ?? [];
      filterObject.billingTypeIds = this.getBillingTypeIds();

      // Update and set period.
      if (this.settings.filter.period) {
        if (this.settings.filter.period.periodType === DatePeriodType.Custom) {
          filterObject.from = this.settings.filter.period.from;
          filterObject.to = this.settings.filter.period.to;
        } else {
          const datePair = this.dateService.getDatePair(
            this.settings.filter.period.periodType,
          );
          filterObject.from = datePair.from;
          filterObject.to = datePair.to;
        }
      }
    } else if (this.projectId) {
      filterObject.projectId = this.projectId;
      filterObject.projectVersionId = this.projectVersion?.id;
      filterObject.projectTaskId = this.form.value.task?.id;
    } else if (this.programId) {
      filterObject.programId = this.programId;
      filterObject.projectId = this.form.value.project?.id;
    } else if (this.organizationId) {
      filterObject.organizationId = this.organizationId;
      filterObject.projectId = this.form.value.project?.id;
    }

    return filterObject;
  }

  /**
   * Checks whether PnL filter period is required to load report or not.
   * Not required for non-general PnL.
   *
   * @returns <code>true</code> if required, <code>false</code> otherwise.
   * */
  private checkIfPeriodIsRequired(): void {
    this._isPeriodRequired = this.isGeneral && !this.settings.filter.period;
    if (this._isPeriodRequired) {
      this._thereIsNoData = true;
      this._loading = false;
    }
  }

  private expenseTypeRowHasRules(
    accountId: string | null,
    isExtendedEntries: boolean,
  ): boolean {
    return (
      isExtendedEntries
        ? this.pnlStatement.extendedEntries
        : this.pnlStatement.entries
    ).some(
      (e) =>
        e.accountId === accountId &&
        e.kind === PnlStatementEntryKind.ExpenseRule,
    );
  }

  private getBillingTypeIds(): string[] {
    return Object.entries(this.settings.filter.billingTypes)
      .filter(([_, enabled]) => enabled)
      .map(([code, _]) => ProjectBillingTypes.find((s) => s.code === code).id)
      .filter((id) => !!id);
  }

  // TODO: fix it after `PnlStatementEntryKind` refactoring
  private buildLaborAndTimeOffRows(isMainRegion: boolean): void {
    const laborCostIncluded = this.pnlStatement.accounts.find(
      (account) => account.id === FinancialAccount.laborCostId,
    ).includedInBalance;
    const timeOffCostIncluded = this.pnlStatement.accounts.find(
      (account) => account.id === FinancialAccount.timeOffCostId,
    ).includedInBalance;

    if (
      (laborCostIncluded && isMainRegion) ||
      (!laborCostIncluded && !isMainRegion)
    ) {
      this.buildRow(
        PnlStatementEntryKind.LaborCost,
        null,
        null,
        null,
        this.localizationStrings.cost,
        !isMainRegion,
      );
    }

    if (
      this.isGeneral &&
      ((timeOffCostIncluded && isMainRegion) ||
        (!timeOffCostIncluded && !isMainRegion))
    ) {
      this.buildRow(
        PnlStatementEntryKind.TimeOffCost,
        null,
        null,
        null,
        this.localizationStrings.timeOff,
        !isMainRegion,
      );
    }
  }
}
