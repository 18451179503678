<div class="p-3">
  <div class="p-1">
    <small class="text-body-secondary">{{ 'shared.props.name' | translate }}</small>
    <div class="word-wrap" data-test="name">
      {{ (lifecycleService.lifecycleInfo$ | async)?.workflowIndicatorData.name }}
    </div>
  </div>
  <div class="p-1">
    <small class="text-body-secondary">{{
      'shared.workflow.workflowIndicator.initiator' | translate
    }}</small>
    <div class="word-wrap" data-test="initiator">
      {{ (lifecycleService.lifecycleInfo$ | async)?.workflowIndicatorData.initiator }}
    </div>
  </div>
  <div class="p-1">
    <small class="text-body-secondary">{{
      'shared.workflow.workflowIndicator.startDate' | translate
    }}</small>
    <div class="word-wrap" data-test="startDate">
      {{
        (lifecycleService.lifecycleInfo$ | async)?.workflowIndicatorData.startDate
          | date: 'short' : this.appService.session.timeZoneOffset
      }}
    </div>
  </div>
  <div
    class="p-1"
    *ngIf="(lifecycleService.lifecycleInfo$ | async)?.workflowIndicatorData.description"
  >
    <small class="text-body-secondary">{{ 'shared.props.description' | translate }}</small>
    <div
      class="word-wrap"
      [innerHTML]="
        (lifecycleService.lifecycleInfo$ | async)?.workflowIndicatorData.description | nl2br
      "
      data-test="description"
    ></div>
  </div>
</div>
