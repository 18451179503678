import { StateDeclaration, StateObject } from '@uirouter/core';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Настройка ресурсного плана. */
export class ProjectResourceSettings implements BaseSettings {
  public readonly name = 'ProjectResourceSettings';
  version: number;
  toggler: string | StateObject | StateDeclaration;
  isShowTaskDuration: boolean;
  isForecastMode: boolean;

  getDefault(): ProjectResourceSettings {
    return {
      version: 4,
      toggler: 'project.resources.forecastCalendar',
      isShowTaskDuration: true,
      isForecastMode: false,
    } as ProjectResourceSettings;
  }
}
