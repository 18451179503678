import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import { StateService } from '@uirouter/core';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordResetModalComponent } from 'src/app/settings-app/users/card/user-profile/password-reset-modal/password-reset-modal.component';
import { MessageService } from 'src/app/core/message.service';
import { AuthProviderType } from 'src/app/shared/models/enums/auth-provider-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class UserCardService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  private userNameSubject = new BehaviorSubject<string>(null);
  public userName$ = this.userNameSubject.asObservable();
  user: User;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') private userId: string,
    private data: DataService,
    private notification: NotificationService,
    private navigation: NavigationService,
    private state: StateService,
    private modalService: NgbModal,
    private message: MessageService,
    private actionPanelService: ActionPanelService,
  ) {
    this.load();
    this.state$.next(CardState.Loading);
  }

  public load() {
    const query = {
      select: ['name', 'editAllowed'],
      expand: { authProvider: { select: ['type'] } },
    };

    this.data
      .collection('Users')
      .entity(this.userId)
      .get<User>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (user: User) => {
          this.state$.next(CardState.Ready);
          this.userNameSubject.next(user.name);
          this.user = user;
          this.navigation.addRouteSegment({
            id: this.userId,
            title: user.name,
          });

          this.actionPanelService.action('management').isShown =
            user.authProvider.type == AuthProviderType.Local;

          this.actionPanelService.action('delete').isShown = user.editAllowed;
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  public updateName(value: any) {
    this.userNameSubject.next(value);
  }

  /** Deletes entity. */
  public delete() {
    this.message.confirmLocal('shared.deleteConfirmation').then(
      () => {
        this.data
          .collection('Users')
          .entity(this.userId)
          .delete()
          .subscribe({
            next: () => {
              this.notification.successLocal('shared.messages.deleted');
              this.navigation.goToSelectedNavItem(true);
            },
            error: (error: Exception) => {
              this.message.error(error.message).then(
                () => this.load(),
                () => this.load(),
              );
            },
          });
      },
      () => null,
    );
  }

  /**
   * Navigates to user's login log.
   * */
  public goToLoginLog(): void {
    this.state.go(`settings.loginLog`, {
      routeMode: RouteMode.continue,
      filter: JSON.stringify(<EntityFilter>{
        name: this.user.name,
        filter: [{ userId: { type: 'guid', value: this.userId } }],
      }),
    });
  }

  /** Диалог установки нового пароля. */
  public resetPassword() {
    const ref = this.modalService.open(PasswordResetModalComponent);
    (ref.componentInstance as PasswordResetModalComponent).userId = this.userId;
  }

  /** Отправка сообщения на восстановление пароля. */
  public restorePassword() {
    this.message
      .confirmLocal(
        'settings.users.card.profile.messages.sendResetMessageConfirmation',
      )
      .then(
        () => {
          this.data
            .collection('users')
            .entity(this.userId)
            .action('WP.RestorePassword')
            .execute()
            .subscribe({
              next: () => {
                this.notification.successLocal(
                  'settings.users.card.profile.messages.messageSent',
                );
              },
              error: (error: Exception) => {
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  /** Removes OTP generator app configuration. */
  public removeOtpConfiguration() {
    this.message
      .confirmLocal(
        'settings.users.card.profile.messages.removeOtpConfigurationConfirmation',
      )
      .then(
        () => {
          this.data
            .collection('Users')
            .entity(this.userId)
            .action('ResetOtpCodeGenerator')
            .execute()
            .subscribe({
              next: () => {
                this.notification.successLocal(
                  'settings.users.card.profile.messages.otpGeneratorConfigurationRemoved',
                );
              },
              error: (error: Exception) => {
                if (error.code == Exception.BtEntityNotFoundException.code) {
                  this.notification.errorLocal(
                    'settings.users.card.profile.messages.otpConfigurationNotFound',
                  );
                } else {
                  this.notification.error(error.message);
                }
              },
            });
        },
        () => null,
      );
  }
}
