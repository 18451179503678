import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';

/** Форматирование значений в часах. */
@Pipe({
  name: 'wpWork',
  standalone: true,
})
export class WorkPipe implements PipeTransform {
  private hourSymbol = '';

  constructor(
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
  ) {
    this.hourSymbol = this.translate.instant('shared.unitHour');
  }

  transform(value?: number, decimal?: boolean, zeroIfNull?: boolean): any {
    const template = decimal === false ? '1.0-0' : '1.0-2';
    let displayValue;

    if (value === null && zeroIfNull) {
      value = 0;
    }

    if (value || value === 0) {
      displayValue = this.decimalPipe.transform(value, template);
      displayValue += ' ' + this.hourSymbol;
    } else {
      displayValue = null;
    }
    return displayValue;
  }
}
