import {
  Component,
  ElementRef,
  inject,
  Injector,
  input,
  model,
  OnInit,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, UntypedFormGroup } from '@angular/forms';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { NavigationService } from 'src/app/core/navigation.service';

import { SharedModule } from 'src/app/shared/shared.module';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { KeysWithName } from 'src/app/shared/models/entities/named-entity.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';

import { WorkLogService } from 'src/app/work-log/work-log.service';

@Component({
  selector: 'tmt-work-log-item',
  standalone: true,
  imports: [
    DatePipe,
    NgbTooltipModule,
    TranslateModule,
    SharedModule,
    UIRouterModule,
  ],
  templateUrl: './work-log-item.component.html',
  styleUrl: './work-log-item.component.scss',
})
export class WorkLogItemComponent implements OnInit {
  public timeEntry = model<TimeAllocation>();
  public readonly = input<boolean>(false);

  public availableFields: KeysWithName<TimeAllocation>[] = [];
  public availableCustomFields: MetaEntityBaseProperty[] = [];
  public customFieldsForm: UntypedFormGroup = new FormGroup({});

  protected readonly workLogService = inject(WorkLogService);
  protected readonly navigationService = inject(NavigationService);

  private readonly customFieldsService = inject(CustomFieldService);
  private readonly infoPopupService = inject(InfoPopupService);
  private readonly elRef = inject(ElementRef<HTMLElement>);
  private readonly injector = inject(Injector);

  public ngOnInit(): void {
    [this.availableFields, this.availableCustomFields] =
      this.workLogService.getAvailableFields(this.timeEntry().template);

    this.customFieldsService.enrichFormGroup(
      this.customFieldsForm,
      'TimeAllocation',
    );

    this.customFieldsForm.patchValue(this.timeEntry());
    this.customFieldsForm.disable();
  }

  /** Opens work-log card in view mode. */
  public async openTimeEntry(): Promise<void> {
    this.workLogService.openTimeEntry(this.timeEntry());
  }

  /** Opens work-log card in edit mode. */
  public async editTimeEntry(): Promise<void> {
    const result = await this.workLogService.editTimeEntry(this.timeEntry());

    if (result) {
      this.timeEntry.set(result);
      this.customFieldsForm.patchValue(result);
    }
  }

  /**
   * Opens user info popup.
   *
   * @param userId
   */
  public openUserInfo(userId: string): void {
    this.infoPopupService.open({
      target: this.elRef.nativeElement.querySelector(`#user-${userId}`),
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }
}
