import {
  trigger,
  style,
  animate,
  transition,
  query,
  stagger,
} from '@angular/animations';

export const init = trigger('init', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms', style({ opacity: 1 })),
  ]),
]);

export const destroy = trigger('destroy', [
  transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
]);

export const initAndDestroy = trigger('initAndDestroy', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
]);

export const listFade = trigger('listFade', [
  transition(':enter, * => 0, * => -1', []),
  transition(':increment', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger(55, [animate('600ms ease-out', style({ opacity: 1 }))]),
      ],
      { optional: true },
    ),
  ]),
  transition(':decrement', [
    query(':leave', [
      stagger(55, [animate('300ms ease-out', style({ opacity: 0 }))]),
    ]),
  ]),
]);
