import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import {
  ResourceViewGroup,
  ResourceViewGroupLine,
} from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { ProjectResourcesGridDataService } from 'src/app/projects/card/project-resources/core/project-resources-grid-data.service';
import {
  ResourceGeneralCellAssignment,
  ResourceGeneralCellTask,
} from 'src/app/projects/card/project-resources/models/project-resources-grid-view.model';
import { Command } from 'src/app/shared-features/planner/models/command.model';
import { ProjectResourcesCalendarCommandService } from 'src/app/projects/card/project-resources/core/project-resources-calendar-command.service';

@Component({
  selector: 'tmt-resource-general-cell',
  styleUrls: ['./resource-general-cell.component.scss'],
  templateUrl: './resource-general-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceGeneralCellComponent implements GridComponentCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public readonly resourceType = ResourceType;

  public readonly: boolean;

  public get controlValue(): ResourceGeneralCellAssignment &
    ResourceGeneralCellTask {
    return this.formGroup.controls[this.column.name].value;
  }

  private _rowState: {
    commands: Command[];
    isHover: boolean;
    isMenuOpened: boolean;
  };
  public get rowState(): {
    commands: Command[];
    isHover: boolean;
    isMenuOpened: boolean;
  } {
    return this._rowState;
  }

  private groupId: string;
  private correspondingGroup: ResourceViewGroup;
  private correspondingLine: ResourceViewGroupLine | null;

  constructor(
    public renderer: Renderer2,
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
    public elRef: ElementRef,
    public projectResourceService: ProjectResourceService,
    private projectResourceDataService: ProjectResourceDataService,
    private projectResourcesGridDataService: ProjectResourcesGridDataService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private commandService: ProjectResourcesCalendarCommandService,
  ) {
    this.gridService.detectChanges$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  public ngOnInit(): void {
    this.initProps();
  }

  /**
   * Toggles the expansion state of the group.
   *
   * @param currentValue The current expansion state of the group.
   */
  public expandGroup(currentValue: boolean): void {
    this.correspondingGroup.isExpanded = !currentValue;
    this.formGroup.controls[this.column.name].setValue({
      ...this.controlValue,
      isExpanded: !currentValue,
    });
    this.projectResourcesGridDataService.toggleGroupLineView(
      this.controlValue.id,
    );
    this.cdr.detectChanges();
    //Change rightSide component
    if (this.correspondingGroup.isExpanded) {
      this.projectResourceService.toggleGroup(this.controlValue.id);
    } else {
      this.gridService.detectChanges();
    }
  }

  /**
   * Updates the row state to reflect whether it is currently visible or not.
   *
   * @param isVisible A boolean indicating whether the row is visible or not.
   */
  public changeRowStateView(isVisible: boolean): void {
    this.rowState.isHover = isVisible;
    this.cdr.detectChanges();
  }

  /**
   * Opens the user information popup.
   *
   * @param userId The ID of the user for whom to open the information popup.
   */
  public openUserInfo(userId: string): void {
    const userNameDiv = document.getElementById(userId);
    const userNameDivWidth = userNameDiv.offsetWidth;

    const userNameSpan = document.getElementById(`${userId}-name`);
    const userNameSpanWidth = userNameSpan.offsetWidth;

    const target =
      userNameDivWidth < userNameSpanWidth ? userNameDiv : userNameSpan;

    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Toggles the visibility of other actual resources. */
  public toggleShowOtherActual(): void {
    this.projectResourceService.toggleShowOtherActual();
    this.gridService.detectChanges();
  }

  /** Initializes the component's properties based on the control value. */
  private initProps(): void {
    this.groupId =
      this.controlValue.type === 'assignment'
        ? this.controlValue.id
        : this.controlValue.groupId;

    this.correspondingGroup =
      this.controlValue.type === 'otherActual'
        ? this.projectResourceDataService.otherActualGroup
        : this.projectResourceDataService.groups.find(
            (g) => g.id === this.groupId,
          );

    this.correspondingLine =
      this.controlValue.type === 'assignment'
        ? null
        : this.correspondingGroup.lines.find(
            (l) => l.taskId === this.controlValue.taskId,
          );

    this._rowState = {
      commands:
        this.controlValue.type === 'assignment'
          ? this.commandService.getGroupCommands(this.correspondingGroup)
          : this.commandService.getLineCommands(
              this.correspondingGroup,
              this.correspondingLine,
            ),
      isHover: false,
      isMenuOpened: false,
    };
  }
}
