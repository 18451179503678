import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';

import {
  Observable,
  catchError,
  firstValueFrom,
  forkJoin,
  map,
  of,
  tap,
} from 'rxjs';
import _ from 'lodash';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';
import { AppService } from 'src/app/core/app.service';
import { NavigationService } from 'src/app/core/navigation.service';

import { LifecycleInfo } from 'src/app/shared/models/entities/lifecycle/lifecycle-info.model';
import { State } from 'src/app/shared/models/entities/state.model';
import { TransitionFormModalComponent } from 'src/app/shared/components/features/transition-form-modal/transition-form-modal.component';
import {
  MetaEntity,
  MetaEntityPropertyKind,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { Exception } from 'src/app/shared/models/exception';

import { Board } from 'src/app/settings-app/boards/model/board.model';

import {
  BOARD_CONFIG,
  BoardConfig,
  BoardColumnView,
  Paging,
  BoardCardView,
  BoardCardEntity,
  BoardColumn,
  BoardCard,
} from 'src/app/boards/models';

@Injectable()
export class BoardDataService {
  public states: State[] = [];
  public metaEntity: MetaEntity;
  public entityLifecycleInfo = new Map<string, LifecycleInfo>();

  private destroyRef = inject(DestroyRef);

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private appService: AppService,
    private stateService: StateService,
    private navigationService: NavigationService,
    private modal: NgbModal,
    private blockUI: BlockUIService,
    @Inject(BOARD_CONFIG) private config: BoardConfig | null,
  ) {
    this.metaEntity = this.appService.getMetaEntity(this.config.entityType);
  }

  /**
   * Gets board configs. Also loads states and saves them.
   *
   * @param filter entity filter for getCardsCount method.
   * @returns board column views.
   */
  public getBoardConfig(filter: any): Observable<BoardColumnView[]> {
    return forkJoin([
      this.dataService
        .collection('Boards')
        .entity(this.config.id)
        .get<Board>({
          select: ['id', 'cardProperties', 'cardViewProperties'],
          expand: {
            columns: {
              select: ['*'],
            },
          },
        }),
      this.dataService
        .collection(this.config.collection)
        .function('GetStates')
        .query<State[]>(null, {
          select: ['id', 'code', 'name', 'index', 'style'],
          orderBy: 'index',
        }),
      this.getCardsCount(filter),
    ]).pipe(
      map(([board, states, counts]) => {
        this.config.cardStructure = board.cardViewProperties;
        this.initCardStructure();
        this.states = states;
        let columnViews: BoardColumnView[];

        if (board.columns.length) {
          columnViews = board.columns.map((column) => ({
            ...column,
            state: states.find((state) => state.id === column.stateId),
            actions: [],
            count:
              counts.find((v) => v.card.columnId === column.id)?.count ?? 0,
          }));
        }

        return _.sortBy(columnViews, 'index');
      }),
    );
  }

  /**
   * Gets cards list.
   *
   * @param filter entity filter, not for cards.
   * @returns board cards.
   */
  public getCards(
    filter: { and: Array<any> } | any,
    orderBy?: string,
    columnId?: string,
    pageSettings?: Partial<Paging> & { pageSize: number },
  ): Observable<BoardCardView[] | null> {
    const query: any = {
      expand: {
        entity: this.getEntityQuery(),
        card: { select: ['*'] },
      },
      filter: {
        card: {
          boardId: { type: 'guid', value: this.config.id },
        },
      },
      orderBy: orderBy ? `entity/${orderBy}` : 'card/index',
    };

    if (columnId) {
      _.set(query, 'filter.card.columnId', { type: 'guid', value: columnId });
    }

    if (pageSettings) {
      query.top = pageSettings.pageSize;
      query.skip = pageSettings.pageSize * pageSettings.currentPage;
    }

    const transformedFilter = this.transformFilter(filter);

    if (transformedFilter) {
      query.filter = {
        ...query.filter,
        entity: transformedFilter,
      };
    }

    return this.dataService
      .collection(this.config.cardCollection)
      .query<BoardCardEntity<any>[]>(query)
      .pipe(
        map((values) =>
          values.map(({ card, entity }) => ({
            id: card.id,
            columnId: card.columnId,
            entity,
          })),
        ),
        catchError((error) => {
          this.notificationService.error(error.message);
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  /**
   * Gets lifecycle information for entity.
   *
   * @param card Board card.
   * @returns lifecycle information.
   */
  public getLifecycleInfo(card: BoardCardView): Observable<LifecycleInfo> {
    return this.dataService
      .collection(this.config.collection)
      .entity(card.entity.id)
      .function('GetLifecycleInfo')
      .get<LifecycleInfo>()
      .pipe(
        tap((lifecycleInfo) =>
          this.entityLifecycleInfo.set(card.entity.id, lifecycleInfo),
        ),
      );
  }

  /**
   * Changes entity state.
   *
   * @param card Board card.
   * @param nextStateId New entity state id.
   * @returns `true` if state is changed, otherwise `false`.
   */
  public async setState(
    card: BoardCardView,
    nextStateId: string,
  ): Promise<boolean> {
    try {
      let lifecycleInfo = this.entityLifecycleInfo.get(card.entity.id);

      if (!lifecycleInfo) {
        this.blockUI.start();
        lifecycleInfo = await firstValueFrom(this.getLifecycleInfo(card));
        this.blockUI.stop();
      }

      if (lifecycleInfo.workflowIndicatorData) {
        await this.messageService.confirmLocal(
          this.translateService.instant(
            'shared.workflow.workflowCancelConfirmation',
          ),
        );
      }

      const transition = lifecycleInfo.transitions.find(
        (t) => t.nextStateId === nextStateId,
      );

      if (!transition) {
        this.notificationService.errorLocal('shared2.messages.transitionError');
        return false;
      }

      let data: any = {
        stateId: nextStateId,
        transitionFormValue: {
          propertyValues: [],
          comment: '',
        },
      };

      if (transition.hasTransitionForm) {
        const ref = this.modal.open(TransitionFormModalComponent);
        const instance = ref.componentInstance as TransitionFormModalComponent;
        instance.collection = this.config.collection;
        instance.entityId = card.entity.id;
        instance.stateId = nextStateId;
        instance.transitionId = transition.id;
        instance.metaEntity = this.metaEntity;

        data = await ref.result;
      }

      if (!data) {
        return false;
      }

      this.blockUI.start();
      await firstValueFrom(
        this.dataService
          .collection(this.config.collection)
          .entity(card.entity.id)
          .action('SetState')
          .execute(data),
      );
      this.blockUI.stop();

      this.entityLifecycleInfo.delete(card.entity.id);

      return true;
    } catch (error) {
      this.blockUI.stop();

      if (error?.message) {
        this.notificationService.error(error.message);
      }
    }
  }

  /**
   * Saves columns collection to board.
   *
   * @param columnViews Board column view.
   * @returns `true` if save succeeded, otherwise `false`
   */
  public saveUpdatedColumns(
    columnViews: BoardColumnView[],
  ): Observable<boolean> {
    const columns: BoardColumn[] = columnViews.map((columnView) => ({
      id: columnView.id,
      stateId: columnView.stateId,
      style: columnView.style,
      index: columnView.index,
      header: columnView.header,
      isInitial: columnView.isInitial,
    }));

    return this.updateBoard({ columns });
  }

  /**
   * Updates board card.
   *
   * @param id board card id.
   * @param data board card properties.
   * @returns `true` if update succeeded, otherwise `false`.
   */
  public updateCard(id: string, data: Partial<BoardCard>): Observable<boolean> {
    return this.dataService
      .collection('BoardCards')
      .entity(id)
      .patch(data)
      .pipe(
        map(() => true),
        catchError((error: Exception) => {
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notificationService.error(error.message);
          }

          return of(false);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  /**
   * Updates board configuration.
   *
   * @param data board parameters.
   * @returns `true` if update succeeded, otherwise `false`.
   */
  public updateBoard(data: Partial<Board>): Observable<boolean> {
    return this.dataService
      .collection('Boards')
      .entity(this.config.id)
      .patch(data)
      .pipe(
        map(() => true),
        catchError((error) => {
          this.notificationService.error(error.message);
          return of(false);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  /** Deletes board. */
  public removeBoard(): void {
    this.messageService.confirmLocal('shared.deleteConfirmation').then(
      () => {
        this.dataService
          .collection('Boards')
          .entity(this.config.id)
          .delete()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.notificationService.successLocal('shared.deleteCompleted');
              this.stateService.go('currentTimesheet', {
                navigation: 'my.currentTimesheet',
              });
              this.navigationService.reloadCustomNavigationItems();
            },
            error: (error: Exception) => {
              this.notificationService.error(error.message);
            },
          });
      },
      () => null,
    );
  }

  private getCardsCount(
    filter: any,
  ): Observable<Array<{ card: Partial<BoardCard>; count: number }>> {
    const query: any = {
      transform: {
        filter: {
          card: {
            boardId: { type: 'guid', value: this.config.id },
          },
        },
        groupBy: {
          properties: [`card/columnId`],
          transform: {
            aggregate: {
              [`card/id`]: {
                with: 'countdistinct',
                as: 'count',
              },
            },
          },
        },
      },
    };

    const transformedFilter = this.transformFilter(filter);

    if (transformedFilter) {
      _.set(query, 'transform.filter.entity', transformedFilter);
    }

    return this.dataService.collection(this.config.cardCollection).query(query);
  }

  private getEntityQuery(): Record<string, any> {
    const clrTypesWithIcon: Record<string, string> =
      this.config.clrTypesWithIcon;
    const select: string[] = ['id', 'stateId', 'isActive'];
    const expand: Record<string, any> = {
      state: {
        select: ['id', 'code'],
      },
    };

    this.config.cardStructure.forEach((property) => {
      switch (property.kind) {
        case MetaEntityPropertyKind.primitive:
          select.push(property.name);
          break;
        case MetaEntityPropertyKind.navigation:
        case MetaEntityPropertyKind.directory:
          expand[property.name] = {
            select: ['id', 'name'],
          };

          if (
            clrTypesWithIcon &&
            clrTypesWithIcon[_.camelCase(property.clrType)]
          ) {
            expand[property.name]['select'].push(
              clrTypesWithIcon[_.camelCase(property.clrType)],
            );
          }

          break;
        // case MetaEntityPropertyKind.complex:
        //   select.push(property.name);
        //   break;
      }
    });

    return {
      select,
      expand,
    };
  }

  private initCardStructure(): void {
    this.config.cardStructure.forEach((property) => {
      property.name = _.camelCase(property.name);
      property.clrType = _.camelCase(property.clrType);
    });
  }

  /** Transforms an entity filter to a proper object. */
  private transformFilter(filter: any): Record<string, any> | null {
    if (_.isEmpty(filter)) {
      return null;
    }

    const entityFilter = Array.isArray(filter)
      ? filter.reduce((result, item) => ({ ...result, ...item }), {})
      : filter;

    // TODO: remove it after filterService refactoring
    for (const key of Object.keys(entityFilter)) {
      if (Array.isArray(entityFilter[key]) && !entityFilter[key].length) {
        delete entityFilter[key];
      }

      if (key === 'and' && !Array.isArray(entityFilter[key])) {
        Object.entries(entityFilter[key]).forEach(([property, value]) => {
          entityFilter[property] = value;
        });

        delete entityFilter[key];
      }
    }

    if (_.isEmpty(entityFilter)) {
      return null;
    }

    return Array.isArray(filter) ? { and: entityFilter } : entityFilter;
  }
}
