import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';

import { filter } from 'rxjs';

import { AppService } from 'src/app/core/app.service';

import { SharedModule } from 'src/app/shared/shared.module';
import { init, initAndDestroy, listFade } from 'src/app/shared/animations';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { LocalStringHelper } from 'src/app/shared/models/enums/language.enum';
import {
  MetaEntity,
  MetaEntityPrimitiveProperty,
  MetaEntityPropertyKind,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

import {
  Filter,
  FilterCondition,
  FilterProperty,
  MetaEntityData,
} from './filter-conditions-builder.model';
import { FilterConditionComponent } from './filter-condition/filter-condition.component';

@Component({
  selector: 'tmt-filter-conditions-builder',
  standalone: true,
  imports: [SharedModule, FilterConditionComponent],
  templateUrl: './filter-conditions-builder.component.html',
  styleUrl: './filter-conditions-builder.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [init, initAndDestroy, listFade],
})
export class FilterConditionsBuilderComponent {
  public source = input.required<FilterProperty[] | string>();
  public isNavigationExpanded = input<boolean>(false); // TODO: change it?

  public onTitleChanged = output<string>();

  public filters = model<Filter[]>([]);

  public indicatorTitle: string;
  public loading = signal<boolean>(false);
  public sourceBoxShown = signal<boolean>(false);
  public sourceFields = computed<FilterProperty[] | null>(() =>
    this.buildSource(this.source()),
  );
  public sourceControl = new FormControl<{ id: string; name: string } | null>(
    null,
  );
  public availableSourceFields: FilterProperty[] = [];
  public paramsBySourceField: Record<
    string,
    { title: string; metaEntityPropertyData: MetaEntityData }
  > = {};
  public metaEntity: MetaEntity;
  public allowInactiveControl = new FormControl<boolean>(false);

  private readonly appService = inject(AppService);
  private readonly translateService = inject(TranslateService);

  constructor() {
    this.sourceControl.valueChanges
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(),
      )
      .subscribe((value) => {
        const item = this.sourceFields().find((v) => v.id === value.id);

        this.sourceBoxShown.set(false);
        this.sourceControl.setValue(null, { emitEvent: false });

        this.filters.update((values) => {
          this.updateIndicator(values.length + 1);
          return values.concat([
            {
              property: item.key,
              conditions: [
                {
                  operator: 'Equal',
                  value: null,
                },
              ],
            },
          ]);
        });
      });
  }

  public ngOnInit(): void {
    this.updateIndicator(this.filters().length);
  }

  /**
   * Adds condition to criterion.
   *
   * @param index criterion index.
   */
  public addCondition(index: number): void {
    this.filters.update((values) => {
      values.at(index).conditions.push({
        operator: 'Equal',
        value: null,
      });

      return values.slice(0);
    });
  }

  /**
   * Updates filters.
   *
   * @param filterIndex
   * @param criterionIndex
   * @param data updated filter conditions.
   */
  public updateCondition(
    filterIndex: number,
    criterionIndex: number,
    data: FilterCondition,
  ): void {
    this.filters.update((values) => {
      Object.assign(values.at(filterIndex).conditions.at(criterionIndex), data);
      return values.slice(0);
    });
  }

  /**
   * Removes condition from criterion.
   *
   * @param filterIndex
   * @param criterionIndex
   */
  public removeCondition(filterIndex: number, criterionIndex: number): void {
    this.filters.update((values) => {
      values.at(filterIndex).conditions.splice(criterionIndex, 1);
      return values.slice(0);
    });
  }

  /**
   * Removes criterion from filters.
   *
   * @param index condition index.
   */
  public removeCriterion(index: number): void {
    this.filters.update((values) => {
      values.splice(index, 1);
      this.updateIndicator(values.length);
      return values.slice(0);
    });
  }

  /** Removes all criterions. */
  public clearCriterions(): void {
    this.updateIndicator(0);
    this.filters.set([]);
  }

  private updateIndicator(length: number): void {
    if (!length) {
      this.indicatorTitle = this.translateService.instant(
        'analytics.reportFilters.noFiltersDefined',
      );
    } else {
      const count = { count: length };

      this.indicatorTitle = StringHelper.declOfNum(length, [
        this.translateService.instant(
          'analytics.reportFilters.criteriaCountForms.v1',
          count,
        ),
        this.translateService.instant(
          'analytics.reportFilters.criteriaCountForms.v2',
          count,
        ),
        this.translateService.instant(
          'analytics.reportFilters.criteriaCountForms.v3',
          count,
        ),
      ]);
    }

    this.onTitleChanged.emit(this.indicatorTitle);
  }

  private buildSource(
    source: FilterProperty[] | string,
  ): FilterProperty[] | null {
    if (Array.isArray(source)) {
      return source;
    }

    this.metaEntity = this.appService.getMetaEntity(source);

    if (typeof source !== 'string' || !this.metaEntity) {
      return null;
    }

    const sourceFields: FilterProperty[] = [];
    // TODO: collection, complex?
    this.metaEntity.primitiveProperties
      .concat(this.metaEntity.directoryProperties)
      .concat(this.metaEntity.navigationProperties)
      .filter(
        // TODO: remove it after API fix
        (property) =>
          ![
            MetaEntityPropertyType.dateOnly,
            MetaEntityPropertyType.dateTime,
          ].includes(property.type),
      )
      .forEach((property) => {
        const name = this.getPropertyName(property);
        const sourceField: FilterProperty = {
          id: name,
          name: LocalStringHelper.getTranslate(
            property.displayNames,
            this.appService.session.language,
          ),
          type: property.type,
          key: [name],
          group: null,
        };

        this.paramsBySourceField[sourceField.id] = {
          title: sourceField.name,
          metaEntityPropertyData: property,
        };

        sourceFields.push(sourceField);

        if (
          [
            MetaEntityPropertyKind.navigation,
            MetaEntityPropertyKind.directory,
          ].includes(property.kind) &&
          this.isNavigationExpanded()
        ) {
          const navigationMetaEntity = this.appService.getMetaEntity(
            property.clrType,
          );

          navigationMetaEntity?.primitiveProperties
            .concat(navigationMetaEntity.directoryProperties)
            .concat(navigationMetaEntity.navigationProperties)
            .forEach((navigationProperty) => {
              const navigationName = this.getPropertyName(navigationProperty);
              const navigationSourceField: FilterProperty = {
                id: `${property.name}/${navigationName}`,
                name: `${sourceField.name} (${LocalStringHelper.getTranslate(
                  navigationProperty.displayNames,
                  this.appService.session.language,
                )})`,
                type: navigationProperty.type,
                key: [property.name, navigationName],
                group: name,
              };

              this.paramsBySourceField[navigationSourceField.id] = {
                title: navigationSourceField.name,
                metaEntityPropertyData: navigationProperty,
              };

              sourceFields.push(navigationSourceField);
            });
        }
      });

    return sourceFields.sort(naturalSort('name'));
  }

  private getPropertyName(property: MetaEntityPrimitiveProperty): string {
    return [
      MetaEntityPropertyKind.directory,
      MetaEntityPropertyKind.navigation,
    ].includes(property.kind)
      ? `${property.name}Id`
      : property.name;
  }
}
