@if (sourceFields()?.length) {
  <div class="header">
    <label class="group"> {{ indicatorTitle }} </label>
  </div>

  <div class="panel">
    <div class="form-check">
      <label class="form-check-label">
        <input [formControl]="allowInactiveControl" type="checkbox" class="form-check-input" />
        {{ 'shared2.filters.allowNonActiveEntityToSelected' | translate }}
      </label>
    </div>
    <button
      type="button"
      class="btn btn-default"
      [disabled]="!filters().length"
      (click)="clearCriterions()"
    >
      {{ 'components.filterConditionsBuilderComponent.actions.removeCriterions' | translate }}
    </button>
  </div>

  <div [@listFade]="filters().length" class="card__wrapper">
    @for (filter of filters(); track filter.property; let filterIndex = $index) {
      @if (paramsBySourceField[filter.property.join('/')]) {
        <div
          class="card card--{{
            paramsBySourceField[filter.property.join('/')]?.metaEntityPropertyData.type
          }}"
        >
          <div class="card-header">
            <div>
              {{ paramsBySourceField[filter.property.join('/')].title }}
            </div>

            <button
              class="btn btn-icon card__header-btn card__header-btn--right"
              (click)="removeCriterion(filterIndex)"
              [ngbTooltip]="
                'components.filterConditionsBuilderComponent.actions.removeCriterion' | translate
              "
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>

          <div class="card-body">
            @for (condition of filter.conditions; track condition) {
              <tmt-filter-condition
                @initAndDestroy
                [filterCondition]="condition"
                [metaEntityPropertyData]="
                  paramsBySourceField[filter.property.join('/')].metaEntityPropertyData
                "
                [entityName]="metaEntity.name"
                [allowInactive]="allowInactiveControl.value"
                (onRemoveClick)="removeCondition(filterIndex, $index)"
                (onConditionChange)="updateCondition(filterIndex, $index, $event)"
              />

              @if (filter.conditions[$index + 1]) {
                <div @initAndDestroy class="text-between">
                  {{ 'analytics.reportFilters.or' | translate }}
                </div>
              }
            }

            <div class="btn-container">
              <button
                @init
                class="btn btn-link btn-link-content"
                (click)="addCondition(filterIndex)"
              >
                {{ 'components.filterConditionsBuilderComponent.actions.addCondition' | translate }}
              </button>
            </div>
          </div>
        </div>
      } @else {
        <div class="card card--new">
          <div class="card-header">
            <div>
              {{ 'components.filterConditionsBuilderComponent.props.notFound' | translate }}
            </div>

            <button
              class="btn btn-icon card__header-btn card__header-btn--right"
              (click)="removeCriterion(filterIndex)"
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
          <div class="card-body">
            {{ filter.property }}
          </div>
        </div>
      }

      @if (filters()[filterIndex + 1]) {
        <div @initAndDestroy class="badge bg-secondary rounded-pill text-uppercase m-auto">
          {{ 'analytics.reportFilters.and' | translate }}
        </div>
      }
    }

    @if (sourceBoxShown()) {
      <tmt-hierarchical-box
        @init
        class="mt-2 mb-3"
        [items]="sourceFields()"
        [parentIdKey]="'group'"
        [autofocus]="true"
        [placeholder]="'components.filterConditionsBuilderComponent.props.newCriterion' | translate"
        [formControl]="sourceControl"
      />
    } @else {
      <button @init class="btn btn-default mt-2 mb-3" (click)="sourceBoxShown.set(true)">
        <i class="bi bi-plus-lg"></i>
        {{ 'components.filterConditionsBuilderComponent.props.newCriterion' | translate }}
      </button>
    }
  </div>
}
