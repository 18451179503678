import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { Dictionary } from '../models/dictionary';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

/**
 *  The service is designed to receive and cache ProjectTaskInfo entities.
 *  ProjectTaskInfo - basic information about projects.
 *  This information is available to users who have rights to view the project
 *  or are included in its team.
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectTaskInfoService {
  /** Максимальный загружаемый уровень. */
  constructor(private data: DataService) {}

  private observables: Dictionary<Observable<ProjectTaskInfo[]>> = {};

  /**
   * Gets and cache ProjectTaskInfo entities.
   *
   * @param projectId Project Id.
   * @param userId User Id.
   * */
  public getProjectTasksInfo(
    projectId: string,
    userId: string,
  ): Observable<ProjectTaskInfo[]> {
    const complexKey = projectId + userId;

    if (this.observables[complexKey]) {
      return this.observables[complexKey];
    }

    const observable = this.data
      .collection('Projects')
      .entity(projectId)
      .function('GetProjectTasksInfo')
      .query<ProjectTaskInfo[]>({ userId })
      .pipe(
        shareReplay(),
        map((it) => cloneDeep(it)),
      );

    return (this.observables[complexKey] = observable);
  }

  public resetProjectTasks(projectId: string, userId: string) {
    const complexKey = projectId + userId;
    delete this.observables[complexKey];
  }
}

export interface ProjectTaskInfo extends NamedEntity {
  crossId: string;
  projectId: string | null;
  versionId: string | null;
  number: number;
  structNumber: string;
  indent: number;
  leadTaskId: string | null;
  allowEntry: boolean;
}
