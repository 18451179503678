<wp-loading-indicator
  [loading]="overviewKpiService.loading$ | async"
  [isSpinnerMode]="true"
  [spinnerParams]="spinnerParams"
  [isOverlay]="true"
>
  <div class="card" [ngClass]="{ attention: model.attention }">
    <div class="card-body">
      <div class="header d-flex header justify-content-between">
        <span>
          {{ model.header | translate }}
        </span>
        <i
          class="bi bi-exclamation-triangle-fill text-danger"
          title="{{ 'projects.overview.kpi.deviationHint' | translate }}"
          data-test="deviationIndicator"
        ></i>
      </div>
      <div class="primary">
        <span class="value">{{ model.value }}</span>
        <span class="unit">{{ model.unit }}</span>
      </div>
      <div class="secondary">
        <div>
          <span class="label">{{ model.leftDetail.label | translate }}:</span>
          <span class="value">{{ model.leftDetail.value }}</span>
        </div>

        <div>
          <span class="label">{{ model.rightDetail.label | translate }}:</span>
          <span class="value">{{ model.rightDetail.value }}</span>
        </div>
      </div>
    </div>
  </div>
</wp-loading-indicator>
