import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { firstValueFrom, map } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { AbstractHierarchicalBoxComponent } from 'src/app/shared/components/controls/hierarchical-box/abstract-hierarchical-box.component';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import {
  ProjectTaskInfo,
  ProjectTaskInfoService,
} from 'src/app/shared/services/project-tasks-info.service';
import _ from 'lodash';

interface ProjectTaskRow extends ProjectTaskInfo {
  nameOriginal: string;
}

/** Контрол ввода подразделения. */
@Component({
  selector: 'tmt-task-box',
  templateUrl: './task-box.component.html',
  styleUrls: ['./task-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskBoxComponent),
      multi: true,
    },
  ],
})
export class TaskBoxComponent
  extends AbstractHierarchicalBoxComponent
  implements OnChanges, OnInit
{
  @Input({ required: false }) public override collection = 'ProjectTasks';
  @Input({ required: false }) public override parentIdKey = 'leadTaskId';
  @Input({ required: true }) public projectId: string;
  @Input() public projectVersion: ProjectVersion;
  @Input() public userId: string;
  @Input() public showMainTask = true;
  @Input() public indent = 1;
  @Input() public filterFn: (tasks: ProjectTaskInfo[]) => ProjectTaskRow[];

  constructor(
    protected override notificationService: NotificationService,
    protected override translateService: TranslateService,
    protected override cdr: ChangeDetectorRef,
    protected override renderer: Renderer2,
    protected override el: ElementRef<HTMLElement>,
    private projectTasksService: ProjectTasksService,
    private projectTaskInfoService: ProjectTaskInfoService,
  ) {
    super(notificationService, null, translateService, cdr, renderer, el);
  }

  public ngOnInit(): void {
    if (this.items?.length) {
      throw new Error('TaskBoxComponent does not supported "items" property');
    }
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes['projectId'] && !changes['projectId'].firstChange) {
      this.reloadData();
      return;
    }

    if (changes['projectVersion'] && !changes['projectVersion'].firstChange) {
      this.reloadData();
    }
  }

  public override writeValue(value: any): void {
    value = _.clone(value);
    this.enrichTask(value);
    this.setTextBoxValue(value);
    this.selectedRow = value;
  }

  /**
   *  Propagates selected value.
   *
   * @param value any entity.
   */
  public override onSelected(value: ProjectTaskRow): void {
    if (
      this.selectedRow !== value ||
      (this.selectedRow && value && this.selectedRow.id !== value.id)
    ) {
      this.selectedRow = value;

      this.propagateChange(
        value
          ? {
              ...value,
              name: value.nameOriginal,
              [this.parentIdKey]: value[this.parentIdKey],
            }
          : null,
      );

      this.setTextBoxValue(value);
      this.cancel();
    }
  }

  protected override async loadData(): Promise<void> {
    try {
      this.isLoading = true;
      this.cdr.markForCheck();

      let result = await firstValueFrom(
        this.userId
          ? this.projectTaskInfoService.getProjectTasksInfo(
              this.projectId,
              this.userId,
            )
          : this.projectTasksService
              .getProjectTasks(this.projectId, this.projectVersion, this.indent)
              .pipe(
                map((tasks) =>
                  tasks.map((task) => {
                    const taskInfo = {
                      id: task.id,
                      structNumber: task.structNumber,
                      allowEntry: task.allowTimeEntry,
                      indent: task.indent,
                      isActive: task.isActive,
                      leadTaskId: task.leadTaskId,
                      number: task.number,
                      name: task.name,
                      crossId: task.crossId,
                    } as ProjectTaskInfo;
                    return taskInfo;
                  }),
                ),
              ),
      );

      if (!this.showMainTask) {
        result = result.filter((r) => r[this.parentIdKey]);
      }

      if (this.filterFn) {
        result = this.filterFn(result);
      }

      result.forEach((task: ProjectTaskRow) => this.enrichTask(task));
      result.sort(naturalSort('name'));

      this.rows = result;
      this.isLoading = false;
      this.cdr.detectChanges();
    } catch (error) {
      this.errorHandler(error);
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }

  private async reloadData(): Promise<void> {
    this.onSelected(null);
    await this.loadData();
    this.onSelected(this.rows[0]);
  }

  private enrichTask(task: ProjectTaskRow): void {
    if (task) {
      task.nameOriginal = task.name;
      task.name = task.structNumber
        ? `${task.structNumber ?? ''} ${task.name ?? ''}`
        : task.name ?? '';
    }
  }
}
