import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Analytics } from '../card/structure-change-modal/rate-matrix-structure.model';
import { State } from 'src/app/shared/models/entities/state.model';

export class RateMatrix extends NamedEntity {
  public static readonly billingRateTypeId =
    '806aa1f6-d8d1-43a5-99b2-eada003e03cf';
  public static readonly costRateTypeId =
    '79329a3b-4a50-4595-83b8-87ae99460112';
  public static readonly draftStateId = '2f25a363-11a3-4df9-94d6-edc4580eba0a';
  public static readonly activeStateId = 'cc477184-b32c-4f14-ac2b-70bbf14cbc12';
  /** Rate matrix Code. */
  code: string;
  /** Description. */
  description: string;
  /** Start date of rate matrix. */
  effectiveDate: string;
  /** End date of rate matrix. */
  expiryDate: string;
  /** Grouping of rate matrix analytics. */
  rateMatrixStructure: Analytics[];
  /** Rate matrix state. */
  state: State;
  /** Rate matrix state id. */
  stateId: string;
  /** Count of lines. */
  linescount: number;
}
