import { Injectable } from '@angular/core';
import { NgbCalendarGregorian, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { AppService } from 'src/app/core/app.service';

@Injectable()
/**The service overrides getToday to show the current day based on the user's time zone. */
export class CustomNgbCalendarService extends NgbCalendarGregorian {
  constructor(private appService: AppService) {
    super();
  }

  public override getToday(): NgbDate {
    const { day, month, year } = DateTime.now()
      .toUTC()
      .setZone(this.appService.session.timeZone);

    return new NgbDate(year, month, day);
  }
}
