import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailValidator(): ValidatorFn {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
  const maxEmailLength = 65;

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null; // if the field is empty - not valid
    }

    if (value.length > maxEmailLength) {
      return { emailTooLong: true };
    }

    // Checking the basic format using a regular expression
    if (!emailRegex.test(value)) {
      return { invalidEmail: true };
    }

    // Checking for spaces
    if (/\s/.test(value)) {
      return { emailContainsSpaces: true };
    }

    // Checking for multiple "@" characters
    const atSymbolCount = (value.match(/@/g) || []).length;
    if (atSymbolCount !== 1) {
      return { multipleAtSymbols: true };
    }

    // Checking for invalid characters (!, #, $, %, &, *)
    const invalidChars = /[!#$%&'*/=?^`{|}~]/;
    if (invalidChars.test(value)) {
      return { invalidChars: true };
    }

    // Checking for points at the beginning and at the end
    const [localPart, domain] = value.split('@');
    if (
      localPart.startsWith('.') ||
      localPart.endsWith('.') ||
      domain.startsWith('.') ||
      domain.endsWith('.')
    ) {
      return { invalidDotPosition: true };
    }

    return null; // If all checks are passed, then the email is valid
  };
}
