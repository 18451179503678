<tmt-not-found [state]="boardCardService.state()" />
<wp-loading-indicator [state]="boardCardService.state()" />

@if (boardCardService.state() === 'Ready') {
  <tmt-form-header [name]="boardCardService.name()" />

  <ul class="nav nav-tabs mb-3 mt-3">
    @for (tab of tabs; track tab.state) {
      <li class="nav-item">
        <a
          class="nav-link"
          [ngClass]="{ active: stateService?.current?.name === tab.state }"
          [uiSref]="tab.state"
          [uiParams]="{ routeMode: 'keep' }"
        >
          {{ tab.header | translate }}
        </a>
      </li>
    }
  </ul>

  <wp-data-not-saved-indicator [form]="boardCardService.form" />

  <ui-view />

  @if (!boardCardService.readonly()) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="boardCardService.isSaving()"
        (click)="boardCardService.save()"
      >
        {{ 'shared2.actions.save' | translate }}
      </button>
    </div>
  }
}
