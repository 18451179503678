import { Entity } from './entity.model';

export class NamedEntity extends Entity {
  /** Entity name. */
  name: string;
  /** Entity version. */
  rowVersion?: string;
  /** User who created entity. */
  createdBy?: NamedEntity;
  /** Last user who modified entity. */
  modifiedBy?: NamedEntity;
}

export type KeysWithName<T> = {
  [K in keyof T]: T[K] extends NamedEntity ? K : never;
}[keyof T];
