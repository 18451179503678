import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';
import { Exception } from 'src/app/shared/models/exception';
import { LogService } from '../log.service';

/**
 * Перехват и трансформация ответов API.
 */
@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
  constructor(
    private log: LogService,
    private data: DataService,
    private auth: AuthService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        // Проблема с токеном.
        if (errorResponse.status === 401) {
          this.log.error(
            '401 Unauthorized Error Response. Client will be signed out.',
          );
          this.auth.signOut();
          return EMPTY;
        }

        this.data.setResponseStatus({
          url: req.url,
          code: errorResponse.status,
        });

        if (
          errorResponse.error instanceof Blob &&
          errorResponse.status === 500
        ) {
          return throwError(errorResponse.error.text());
        }

        if (errorResponse.status === 0) {
          return throwError(new Exception('Network', 'Connection error.'));
        }

        // В режиме замещения проблема с замещаемым.
        if (
          errorResponse.status === 403 &&
          errorResponse.error?.error?.code ===
            Exception.BtSubstitutionException.code
        ) {
          this.auth.stopSubstituting();
          return EMPTY;
        }

        if (errorResponse.status === 403 && !errorResponse.error?.error) {
          return throwError(
            new Exception(errorResponse.status.toString(), 'Forbidden.'),
          );
        }

        if (errorResponse.status === 304) {
          return throwError(new Exception('304', 'Not Modified'));
        }

        // Если ответ OData.
        if (errorResponse.error?.error) {
          return throwError(errorResponse.error.error);
        }

        return throwError(Exception.UnexpectedError);
      }),

      map((event) => {
        if (event instanceof HttpResponse) {
          if (event.url.indexOf('/odata') !== -1 && event.body != null) {
            this.data.setResponseStatus({
              url: req.url,
              code: event.status,
            });

            const eventBody = event.body;
            const exceptionKeys = ['@odata.count'];
            let keys = Object.keys(event.body);
            let responseBody = {};

            // Preserve exception odata alias keys.
            if (keys?.length) {
              keys = keys.filter(
                (k) =>
                  k.indexOf('@odata') !== 0 ||
                  exceptionKeys.some((key) => key === k),
              );
            } else {
              responseBody = eventBody;
            }

            if (keys.length === 1 && keys[0] === 'value') {
              // Replace event body by value key if it's the only one.
              responseBody = eventBody.value;
            } else {
              // Remove odata prefix from exceptionKeys.
              keys.forEach((k) => {
                responseBody[k.replace('@odata.', '')] = eventBody[k];
              });
            }

            return event.clone({ body: responseBody });
          }
        }
        return event;
      }),
    );
  }
}
