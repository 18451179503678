/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class WorkLogFilterService extends FilterService {
  public override hasDetails = false;
  public override hasViewSelector = false;
  public override placeholder =
    'components.workLogFilterService.filter.textInput';

  public override getODataFilter(): any {
    const result: any[] = super.getODataFilter();
    const text = this.values.text;

    if (text && text.length >= this.minStringLengthForFilter) {
      result.push(
        this.getTextFilter(
          [
            'description',
            'user/name',
            'activity/name',
            'role/name',
            'project/name',
            'projectTask/name',
            'projectTariff/name',
            'projectCostCenter/name',
            'project/organization/name',
          ],
          this.getClearText(text),
        ),
      );
    }

    return result;
  }

  protected override getDefaultValues(): any {
    return {
      text: '',
    };
  }
}
