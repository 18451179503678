@if (isModifiable) {
  <tmt-interaction-form />
  <hr />
}

<div class="interactions__filters">
  <wp-filter />
  <div class="form-check">
    <input
      [formControl]="checkboxControl"
      type="checkbox"
      class="form-check-input"
      id="showOnlyPlanned"
    />
    <label class="form-check-label" for="showOnlyPlanned">
      {{ 'components.interactionComponent.props.showOnlyPlanned' | translate }}
    </label>
  </div>
  <wp-sort />
</div>

<wp-loading-indicator
  [loading]="interactionsService.isLoading()"
  [isSpinnerMode]="true"
  [isOverlay]="true"
  [spinnerParams]="spinnerParams"
>
  <div class="interactions__feed">
    @for (interaction of interactionsService.interactions(); track interaction.id) {
      <tmt-interaction-item
        [interaction]="interaction"
        [index]="$index"
        [isModifiable]="isModifiable"
      />
    } @empty {
      <div class="text-center text-body-secondary pt-2">
        {{ 'shared2.messages.noDisplayData' | translate }}
      </div>
    }
  </div>
</wp-loading-indicator>
