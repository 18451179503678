<form class="modal-form" novalidate [formGroup]="creationForm" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'components.createDealModalComponent.props.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving()"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label class="control-label">{{ 'shared2.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared2.props.name' | translate }}" />
    </div>
    <div class="form-group">
      <label class="control-label">{{ 'shared2.props.client' | translate }}</label>
      <wp-select-box
        formControlName="organizationId"
        collection="Organizations"
        [isIdMode]="true"
        placeholder="{{ 'shared2.props.client' | translate }}"
        [allowNull]="false"
      />
    </div>
    <div class="form-group">
      <label class="control-label">{{ 'shared2.props.manager' | translate }}</label>
      <wp-user-box
        formControlName="manager"
        placeholder="{{ 'shared2.props.manager' | translate }}"
        [allowNull]="false"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving()"
    >
      {{ 'shared2.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving()">
      {{ 'shared2.actions.cancel' | translate }}
    </button>
  </div>
</form>
