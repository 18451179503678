<div
  class="interaction-item"
  [ngClass]="{
    'interaction-item--edit-mode': interactionsService.activeInteraction() === interaction.id
  }"
>
  @if (interactionsService.activeInteraction() !== interaction.id) {
    @if (isModifiable) {
      <div class="interaction-item__icons-modify">
        <i
          class="bi bi-pencil-fill"
          ngbTooltip="{{ 'shared2.actions.edit' | translate }}"
          (click)="enableEditMode()"
        ></i>
        <i
          class="bi bi-trash3"
          ngbTooltip="{{ 'shared2.actions.delete' | translate }}"
          (click)="deleteInteraction()"
        ></i>
      </div>
    }
    <div class="interaction-item__icon-type">
      @if (index !== interactionsService.interactions().length - 1) {
        <div class="interaction-item__vertical-line"></div>
      }
      <div class="interaction-item__icon">
        @switch (interaction.type.code) {
          @case ('Meeting') {
            <i class="bi bi-people"></i>
          }
          @case ('Call') {
            <i class="bi bi-telephone"></i>
          }
          @case ('Email') {
            <i class="bi bi-envelope"></i>
          }
          @case ('Other') {
            <i class="bi bi-three-dots"></i>
          }
        }
      </div>
    </div>
    <div class="interaction-item__content">
      <div class="interaction-item__header">
        <div
          class="interaction-item__badge interaction-item__badge--type badge badge-trim text-dark"
        >
          {{ interaction.type.name }}
        </div>
        @if (interaction.isPlanned) {
          <div
            class="interaction-item__badge interaction-item__badge--planned badge badge-trim text-bg-danger"
          >
            {{ 'components.interactionItemComponent.props.plannedInteraction' | translate }}
          </div>
        }
        <div class="interaction-item__performer" id="{{ 'performer' + index }}">
          <img
            [src]="interaction.performer.id | avatar: 60"
            alt="user avatar"
            class="interaction-item__performer-avatar"
          />
          <div
            class="interaction-item__performer-name trim"
            ngbTooltip="{{ interaction.performer.name }}"
          >
            <button (click)="openUserInfo()" class="btn btn-link btn-link-content" type="button">
              {{ interaction.performer.name }}
            </button>
          </div>
        </div>
        <div class="interaction-item__date">
          <div>
            <i class="bi bi-calendar"></i>
            {{ interaction.date | date: 'short' : this.appService.session.timeZoneOffset }}
          </div>
        </div>
      </div>
      @if (hasDetailedInfo()) {
        <div class="interaction-item__details">
          @if (interaction.organization?.name) {
            <div
              class="interaction-item__organization trim"
              [ngStyle]="{ 'max-width': maxWidth }"
              ngbTooltip="{{ interaction.organization.name }}"
              container="body"
            >
              <button class="btn btn-link btn-link-content" type="button" (click)="openClient()">
                {{ interaction.organization.name }}
              </button>
            </div>
          }

          @if (interaction.deal?.name) {
            <div
              class="interaction-item__deal trim"
              [ngStyle]="{ 'max-width': maxWidth }"
              ngbTooltip="{{ interaction.deal.name }}"
              container="body"
            >
              <button class="btn btn-link btn-link-content" (click)="openDeal()" type="button">
                {{ interaction.deal.name }}
              </button>
            </div>
          }

          @if (interaction.interactionContacts?.length) {
            <div class="interaction-item__contacts" [ngStyle]="{ 'max-width': maxWidth }">
              @for (
                interactionContact of interaction.interactionContacts;
                track interactionContact.id
              ) {
                <div
                  class="interaction-item__contact"
                  [ngStyle]="{ 'max-width': maxWidthContact }"
                  ngbTooltip="{{ interactionContact.contact.name }}"
                  container="body"
                >
                  <div class="interaction-item__contact-name trim">
                    <button
                      class="btn btn-link btn-link-content"
                      type="button"
                      (click)="openContact(interactionContact.contact.id)"
                    >
                      {{ interactionContact.contact.name }}
                    </button>
                  </div>
                  @if (!$last) {
                    ,
                  }
                </div>
              }
            </div>
          }
        </div>
      }

      <div class="interaction-item__description">
        {{ interaction.description }}
      </div>
    </div>
  } @else {
    <tmt-interaction-form [interaction]="interaction" (onCancel)="onCancel()" />
  }
</div>
