import {
  DestroyRef,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';

import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  concatMap,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  map,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import _ from 'lodash';

import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';
import { MenuService, MenuItem, MenuSubItem } from 'src/app/core/menu.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { BlockUIService } from 'src/app/core/block-ui.service';

import { OffCanvasEntry } from 'src/app/shared/models/off-canvas/off-canvas-entry.interface';
import { LocalStringHelper } from 'src/app/shared/models/enums/language.enum';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { LocalDragDropService } from 'src/app/shared/services/drag-drop';
import { DragAndDropData } from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { MetaEntityPropertyKind } from 'src/app/shared/models/entities/settings/metamodel.model';
import { Guid } from 'src/app/shared/helpers/guid';

import { BoardCardViewProperties } from 'src/app/settings-app/boards/model/board.model';

import {
  BoardColumnMode,
  BoardEvent,
  BoardColumnView,
  BoardCardView,
  Paging,
  BOARD_CONFIG,
  BoardConfig,
  BoardLocalSettings,
  BoardCardContext,
  BoardColumn,
} from 'src/app/boards/models';
import { BoardDataService } from 'src/app/boards/services/board-data.service';
import { BoardColumnHeaderFormComponent } from 'src/app/boards/components/board/column-header-form/board-column-header-form.component';
import { BoardMiniCardBuilderModalComponent } from 'src/app/boards/components/board/mini-card-builder-modal/board-mini-card-builder-modal.component';
import { SortItem } from 'src/app/boards/components/board/sort-button/board-sort-button.interface';

@Injectable()
export class BoardService implements OnDestroy {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private _columnMode = signal<BoardColumnMode>('states');
  public columnMode = computed(this._columnMode);

  public event$ = new Subject<BoardEvent>();
  public columnsDependencies: Record<string, string[]> = {};
  public columns: BoardColumnView[] = [];
  public cards: BoardCardView[] = [];
  public cardsByColumns: Record<string, BoardCardView[]>;
  public pagingByColumns = new Map<string, Paging>();
  public sortItems: SortItem[] = [];
  public sortChange$ = new BehaviorSubject<string>(null);

  private readonly pageSize = 50;
  private readonly pageSizeOnInit = this.pageSize * 1;
  private readonly destroyRef = inject(DestroyRef);

  public get activeColumns(): BoardColumnView[] {
    return this.columns.filter((column) =>
      this._columnMode() === 'states' ? !!column.stateId : !column.state,
    );
  }

  constructor(
    @Inject(BOARD_CONFIG) public readonly config: BoardConfig | null,
    private boardDataService: BoardDataService,
    private offCanvasService: OffCanvasService,
    private notificationService: NotificationService,
    private actionPanelService: ActionPanelService,
    private filterService: FilterService,
    private dragDropService: LocalDragDropService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private menuService: MenuService,
    private modal: NgbModal,
    private stateService: StateService,
    private navigationService: NavigationService,
    private blockUI: BlockUIService,
    private appService: AppService,
    private translateService: TranslateService,
    private title: Title,
    @Inject(DOCUMENT) private document: Document,
    localStorageService: LocalStorageService,
  ) {
    const localSettings: BoardLocalSettings | null =
      localStorageService.retrieve(`board_settings_` + config.id) ?? {};

    this._columnMode.set(localSettings?.columnMode ?? 'states');

    effect(() => {
      const columnMode = this._columnMode();

      this.columns.length = 0;
      this.event$.next({
        action: 'updated',
        target: 'board',
      });

      localStorageService.store(
        `board_settings_` + config.id,
        Object.assign(localSettings, { columnMode, groupBy: null }),
      );
    });

    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.infoPopupService.close();
  }

  /**
   * Opens card in `offCanvas` (aside).
   *
   * @param entityId  card entity id.
   */
  public openOffCanvas(entityId: string): void {
    const options: OffCanvasEntry = {
      content: {
        component: this.config.offCanvasComponent,
        componentParams: {
          inputs: {
            entityId,
          },
          injector: Injector.create({
            providers: [
              {
                provide: 'entityId',
                useValue: entityId,
              },
            ],
            parent: this.injector,
          }),
        },
      },
      exclude: '.board__task',
    };

    if (this.offCanvasService.offCanvasEntry) {
      this.offCanvasService.reRenderContent(options.content);
      return;
    }

    this.offCanvasService.openOffCanvas(options);
  }

  /** Opens card builder modal. If modal result if successful, reloads board with new card structure.  */
  public openCardBuilder(): void {
    const modalRef = this.modal.open(BoardMiniCardBuilderModalComponent, {
      injector: this.injector,
    });

    modalRef.result.then(
      (result: BoardCardViewProperties[] | undefined) => {
        if (result) {
          this.loadBoard().subscribe();
        }
      },
      () => null,
    );
  }

  /** Changes state to board settings. */
  public openSettings(): void {
    this.stateService.go('settings.board', {
      entityId: this.config.id,
      routeMode: RouteMode.continue,
      navigation: this.navigationService.selectedNavigationItem?.name,
    });
  }

  /**
   * Loads board's states and tasks.
   *
   * @returns board card views.
   */
  public loadBoard(): Observable<BoardCardView<any>[]> {
    if (!this.config) {
      return of(null);
    }

    this.blockUI.start();

    return this.boardDataService.getBoardConfig(this.getFilter()).pipe(
      tap((columns) => {
        this.columns = columns;
        this.initColumnsDependencies();
        this.initColumnsSettings();
        this.initSortItems();
      }),
      switchMap(() => {
        const activeColumns = this.activeColumns;
        return activeColumns.length
          ? forkJoin(
              this.activeColumns.map((column) =>
                this.boardDataService.getCards(
                  this.getFilter(),
                  this.sortChange$.getValue(),
                  column.id,
                  {
                    pageSize: this.pageSizeOnInit,
                    currentPage: 0,
                  },
                ),
              ),
            )
          : of([]);
      }),
      map((cards) => cards.flat()),
      tap((cards) => {
        this.cards = cards;
        this.cardsByColumns = _.groupBy(
          this.cards,
          (item) => `${item['columnId']}`,
        );

        for (const column of this.columns) {
          if (!this.cardsByColumns[column.id]) {
            this.cardsByColumns[column.id] = [];
          }

          this.pagingByColumns.set(column.id, {
            currentPage: this.pageSizeOnInit / this.pageSize,
            loadedAll: this.cardsByColumns[column.id].length < this.pageSize,
          });
        }

        this.initCardActions(this.cards);
        this.event$.next({
          target: 'board',
          action: 'updated',
        });
        this.event$.next({
          target: 'track',
          action: 'updated',
        });

        this.blockUI.stop();
      }),
      catchError((error) => {
        this.notificationService.error(error.message);
        this.blockUI.stop();
        return of(null);
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /**
   * Loads cards page-by-page for the column.
   *
   * @param column
   * @returns `false`, if loading has been rejected.
   */
  public loadCardsByColumn(column: BoardColumn): void | false {
    const paging = this.pagingByColumns.get(column.id);

    if (
      !paging ||
      paging.loadedAll ||
      paging.pending ||
      this.loadingSubject.getValue()
    ) {
      return false;
    }

    paging.pending = true;
    this.pagingByColumns.set(column.id, paging);

    this.boardDataService
      .getCards(this.getFilter(), this.sortChange$.getValue(), column.id, {
        pageSize: this.pageSize,
        currentPage: paging.currentPage,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        paging.pending = false;

        if (value) {
          paging.currentPage++;
          paging.loadedAll = value.length < this.pageSize;

          this.initCardActions(value);
          this.cards = this.cards.concat(value);
          this.cardsByColumns[column.id] =
            this.cardsByColumns[column.id].concat(value);

          this.event$.next({
            target: 'track',
            action: 'updated',
            id: column.id,
          });
        }

        this.pagingByColumns.set(column.id, paging);
      });
  }

  /**
   * Updates card after drop.
   *
   * @param cardId Card id.
   * @param newColumnCode New column after drop.
   */
  public async updateCard(
    cardId: string,
    data: DragAndDropData<BoardCardView>,
  ): Promise<void> {
    const card = this.cards.find((c) => c.id === cardId);
    const newColumn = this.columns.find((c) => c.id === data.toGroupName);
    const oldColumn = this.columns.find((c) => c.id === data.fromGroupName);

    // NOTE: same column case
    if (data.fromGroupName === data.toGroupName) {
      if (data.oldIndex === data.newIndex) {
        return;
      }

      // NOTE: reject changes if sorting is not manual
      if (this.sortChange$.getValue()) {
        this.dragDropService.data = data;
        this.notificationService.warningLocal(
          'components.boardService.messages.sortingAlert',
        );

        this.event$.next({
          target: 'track',
          id: card.columnId,
          action: 'rollBackFrom',
          data,
        });

        this.event$.next({
          target: 'track',
          id: card.columnId,
          action: 'rollBackTo',
          data,
        });

        return;
      }

      this.boardDataService
        .updateCard(card.id, {
          columnId: newColumn.id,
          index: data.newIndex,
        })
        .subscribe();

      return;
    }

    if (
      data.fromGroupName !== data.toGroupName &&
      this.columnMode() === 'custom'
    ) {
      this.boardDataService
        .updateCard(card.id, {
          columnId: newColumn.id,
          index: data.newIndex,
        })
        .pipe(switchMap(() => this.refreshCard(card, newColumn.id)))
        .subscribe(() => {
          this.updateColumnsCount(newColumn, oldColumn);
        });

      return;
    }

    this.setDisableDrag(true);

    const stateChangeResult = await this.boardDataService.setState(
      card,
      newColumn.stateId,
    );

    if (stateChangeResult) {
      card.entity.stateId = newColumn.stateId;
      card.entity.state = newColumn.state;
      card.columnId = newColumn.id;

      this.boardDataService
        .updateCard(card.id, {
          columnId: card.columnId,
          index: data.newIndex,
        })
        .subscribe((result) => {
          if (result) {
            this.updateColumnsCount(newColumn, oldColumn);
          } else {
            this.removeCard(card);
            this.updateColumnsCount(null, oldColumn);
          }
        });
    } else {
      this.dragDropService.data = data;
      const oldColumn = this.columns.find((c) => c.id === data.fromGroupName);

      this.event$.next({
        target: 'track',
        id: newColumn.id,
        action: 'rollBackFrom',
        data,
      });

      this.event$.next({
        target: 'track',
        id: oldColumn.id,
        action: 'rollBackTo',
        data,
      });
    }

    this.setDisableDrag(false);
    this.refreshCard(card, newColumn.id).subscribe();
  }

  /**
   * Adds new column.
   *
   * @param data column properties.
   * @returns updated column and cards if save succeeded, otherwise `false`.
   */
  public addColumn(
    data: Partial<BoardColumnView>,
  ): Promise<BoardCardView[] | boolean> {
    const updatedColumns = this.columns.slice(0);
    const state = this.boardDataService.states.find(
      (state) => state.id === data.state?.id,
    );

    updatedColumns.push({
      actions: [],
      id: Guid.generate(),
      stateId: state?.id ?? null,
      header: data.header,
      style: state?.style ?? null,
      index: this.columns.length,
      isInitial: false,
      count: 0,
    });

    return firstValueFrom(
      this.boardDataService.saveUpdatedColumns(updatedColumns).pipe(
        switchMap((v) => (v ? this.loadBoard() : of(v))),
        tap(() => {
          this.event$.next({ target: 'board', action: 'updated' });
        }),
      ),
    );
  }

  /**
   * Removes column.
   *
   * @param column Board column view.
   */
  public removeColumn(column: BoardColumnView): void {
    this.columns.splice(
      this.columns.findIndex((c) => c.id === column.id),
      1,
    );
    this.setDisableDrag(true);

    firstValueFrom(
      this.boardDataService.saveUpdatedColumns(this.columns).pipe(
        tap(() => {
          if (this._columnMode() === 'custom') {
            this.loadBoard().subscribe(() => this.setDisableDrag(false));
          } else {
            this.event$.next({ target: 'board', action: 'updated' });
            this.setDisableDrag(false);
          }
        }),
      ),
    );
  }

  /**
   * Updates column.
   *
   * @param id column id.
   * @param data column properties.
   * @returns `true` if save succeeded, otherwise `false`.
   */
  public updateColumn(
    id: string,
    data: Partial<BoardColumnView>,
  ): Promise<boolean> {
    Object.assign(
      this.columns.find((column) => column.id === id),
      data,
    );

    this.event$.next({
      target: 'column',
      action: 'updated',
      id,
    });

    return firstValueFrom(
      this.boardDataService.saveUpdatedColumns(this.columns),
    );
  }

  /**
   * Updates columns after drag and drop.
   *
   * @param sortedColumns sorted columns.
   */
  public updateColumnsOrder(sortedColumns: BoardColumnView[]): void {
    sortedColumns.forEach((sortedColumn, index) => {
      const column = this.columns.find((c) => c.id === sortedColumn.id);
      column.index = index;
    });

    this.columns = _.sortBy(this.columns, ['index']);

    firstValueFrom(this.boardDataService.saveUpdatedColumns(this.columns));
  }

  /**
   * Opens popup with column form.
   *
   * @param target popup target.
   * @param column board column view.
   * @param mode form mode.
   */
  public opensColumnForm(
    target: HTMLElement,
    column: BoardColumnView,
    mode: 'edit' | 'create',
  ): void {
    this.infoPopupService.open<BoardColumnHeaderFormComponent>({
      target,
      data: {
        component: BoardColumnHeaderFormComponent,
        componentParams: {
          inputs: {
            mode,
            column,
          },
          injector: this.injector,
        },
      },
      observeIntersectionDisabled: true,
    });
  }

  /**
   * Changes column mode.
   *
   * @param mode `states` or `custom`.
   */
  public changeColumnMode(mode: BoardColumnMode): void {
    this._columnMode.set(mode);
  }

  /**
   * Opens menu.
   *
   * @param event mouse event.
   * @param actions actions array.
   * @param context menu item context.
   */
  public openMenu(
    event: MouseEvent,
    actions: MenuItem[],
    context: BoardCardContext | HTMLElement,
  ): void {
    this.infoPopupService.close();
    this.menuService.open(event, actions, context);
    event.preventDefault();
    event.stopPropagation();
  }

  /** Sets board name to document title. */
  public setTitle(): void {
    this.title.setTitle(this.config?.title || '');
  }

  // TODO: refactor to effect
  private updateColumnsCount(
    incrementColumn: BoardColumnView | null,
    decrementColumn: BoardColumnView | null,
  ): void {
    const columns: BoardColumnView[] = [];

    if (incrementColumn) {
      incrementColumn.count++;
      columns.push(incrementColumn);
    }

    if (decrementColumn) {
      decrementColumn.count--;
      columns.push(decrementColumn);
    }

    columns.forEach((column) => {
      this.event$.next({
        target: 'column',
        action: 'updated',
        id: column.id,
      });
    });
  }

  /**
   * Gets data from API and merges properties with current card.
   *
   * @param card board card view.
   * @param columnId
   * @returns board card view.
   */
  private refreshCard(
    card: BoardCardView,
    columnId: string,
  ): Observable<BoardCardView> {
    return this.boardDataService
      .getCards(
        {
          id: { type: 'guid', value: card.entity.id },
        },
        '',
        columnId,
      )
      .pipe(
        map((cards) => cards.pop()),
        tap((data) => {
          Object.assign(card, data);
          this.event$.next({
            target: 'card',
            id: card.id,
            action: 'updated',
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  /**
   * Sets menu actions to cards.
   *
   * @param cards board cards.
   */
  private initCardActions(cards: BoardCardView[]): void {
    for (const cardItem of cards) {
      cardItem.actions = [
        {
          name: 'changeState',
          label: `components.boardMiniCardComponent.actions.${this._columnMode() === 'states' ? 'changeState' : 'changeColumn'}`,
          handlerFn: () => null,
          subActionsLazyResolver: (context) =>
            this._columnMode() === 'states'
              ? this.getChangeStateSubActions(context)
              : Promise.resolve(this.getChangeColumnActions(context)),
        },
        {
          name: 'moveToTop',
          label: 'components.boardMiniCardComponent.actions.moveToTop',
          iconClass: 'bi-arrow-bar-up',
          handlerFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: 0,
                item: card.item,
              },
            }),
          allowedFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.cardsByColumns[card.item.columnId].length > 1 &&
            !!card.oldIndex &&
            !this.sortChange$.getValue(),
        },
        {
          name: 'moveToBottom',
          label: 'components.boardMiniCardComponent.actions.moveToBottom',
          iconClass: 'bi-arrow-bar-down',
          handlerFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: this.cardsByColumns[card.item.columnId].length - 1,
                item: card.item,
              },
            }),
          allowedFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.cardsByColumns[card.item.columnId].length > 1 &&
            card.oldIndex <
              this.cardsByColumns[card.item.columnId].length - 1 &&
            !this.sortChange$.getValue(),
        },
      ];
    }
  }

  private getChangeColumnActions(context: BoardCardContext): MenuSubItem[] {
    const columns = this.activeColumns.filter(
      (c) => c.id !== context.item.columnId,
    );

    return columns.map((column) => ({
      name: column.id,
      label: column.header,
      handlerFn: (card: BoardCardContext) => {
        this.event$.next({
          target: 'track',
          id: card.item.columnId,
          action: 'moveThroughCardMenu',
          data: {
            oldIndex: card.oldIndex,
            newIndex: this.cardsByColumns[column.id].length,
            item: card.item,
            toGroupName: column.id,
          },
        });
      },
    }));
  }

  /** Inits card menu change state sub actions. */
  private async getChangeStateSubActions(
    context: BoardCardContext,
  ): Promise<MenuSubItem[]> {
    const actions: MenuSubItem[] = [];
    const lifecycleInfo = await firstValueFrom(
      this.boardDataService.getLifecycleInfo(context.item),
    );

    for (const transition of lifecycleInfo.transitions) {
      actions.push({
        name: transition.id,
        label: transition.label,
        handlerFn: (card: BoardCardContext) => {
          const column = this.columns.find(
            (column) => column.stateId === transition.nextStateId,
          );

          if (!column) {
            this.boardDataService
              .setState(card.item, transition.nextStateId)
              .then((value) => {
                if (value) {
                  this.cardsByColumns[card.item.columnId].splice(
                    card.oldIndex,
                    1,
                  );
                  this.dragDropService.setOnEnd();
                }
              });
          } else {
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: this.cardsByColumns[column.id].length,
                item: card.item,
                toGroupName: column.id,
              },
            });
          }
        },
      });
    }

    return actions;
  }

  private removeCard(result: BoardCardView): void {
    const cardIndex = this.cards.findIndex((card) => result.id === card.id);
    const cardInColumnIndex = this.cardsByColumns[result.columnId].findIndex(
      (card) => result.id === card.id,
    );

    this.cards.splice(cardIndex, 1);
    this.cardsByColumns[result.columnId].splice(cardInColumnIndex, 1);

    this.event$.next({
      target: 'track',
      action: 'updated',
    });
  }

  // TODO: not correct, it's like mock data
  private initColumnsDependencies(): void {
    const codes = this.columns.map((column) => column.id);

    codes.forEach((code) => {
      this.columnsDependencies[code] = codes;
    });
  }

  private initColumnsSettings(): void {
    this.pagingByColumns.clear();
    this.columns.forEach((column) => {
      column.actions = [
        {
          name: 'edit',
          label: 'shared2.actions.edit',
          iconClass: 'bi bi-pencil',
          handlerFn: (target) => this.opensColumnForm(target, column, 'edit'),
        },
        {
          name: 'remove',
          label: 'shared2.actions.delete',
          iconClass: 'bi bi-trash',
          handlerFn: () => this.removeColumn(column),
        },
      ];

      this.pagingByColumns.set(column.id, {
        currentPage: 0,
        loadedAll: false,
      });
    });
  }

  private initSortItems(): void {
    this.sortItems.length = 0;

    this.sortItems.push({
      key: 'sort-default',
      name: this.translateService.instant(
        'components.boardService.props.manual',
      ),
      isNavigation: false,
      isDefault: true,
    });

    this.boardDataService.metaEntity.primitiveProperties
      .concat(this.boardDataService.metaEntity.navigationProperties)
      .filter((property) => property.type.toLowerCase() !== 'text')
      .forEach((property) => {
        this.sortItems.push({
          key: _.camelCase(property.name),
          name: LocalStringHelper.getTranslate(
            property.displayNames,
            this.appService.getLanguage(),
          ),
          isNavigation: property.kind === MetaEntityPropertyKind.navigation,
        });
      });

    this.sortItems = _.orderBy(
      this.sortItems,
      ['isDefault', 'name'],
      ['asc', 'asc'],
    );
  }

  private initSubscriptions(): void {
    this.loadingSubject.next(true);

    merge(
      this.filterService?.values$,
      this.actionPanelService.reload$,
      this.sortChange$.pipe(filter((v) => !!v || v === '')),
      toObservable(this._columnMode, { injector: this.injector }),
    )
      .pipe(
        switchMap(() => this.loadBoard()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.loadingSubject.getValue()) {
          this.loadingSubject.next(false);
        }

        this.document.querySelector('#main-area').scroll({
          top: 0,
          behavior: 'smooth',
        });
      });

    this.offCanvasService.entityUpdated$
      .pipe(
        filter((v) => !!v),
        switchMap((entity: { [key: string]: any; changedStateId?: string }) => {
          const card = this.cards.find((card) => card.entity.id === entity.id);

          if (entity.changedStateId || !card) {
            this.actionPanelService.reload();
            return of(null);
          }

          return this.refreshCard(card, card.columnId).pipe(
            map((result) => (!result ? card : null)),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result: BoardCardView | null) => {
        if (result) {
          this.removeCard(result);
          this.updateColumnsCount(
            null,
            this.columns.find((column) => column.id === result.columnId),
          );
        }

        if (!this.offCanvasService.offCanvasEntry) {
          this.setTitle();
        }
      });

    this.dragDropService.onStart$
      .pipe(
        tap(() => {
          this.infoPopupService.close();
        }),
        filter(
          (data) =>
            data.fromGroupKey === 'cards' && this.columnMode() === 'states',
        ),
        concatMap((data) => {
          const lifecycleInfo = this.boardDataService.entityLifecycleInfo.get(
            data.item.entity.id,
          );

          return lifecycleInfo
            ? of(lifecycleInfo)
            : this.boardDataService
                .getLifecycleInfo(data.item)
                .pipe(
                  takeUntil(
                    merge(
                      this.dragDropService.onDrop$,
                      this.dragDropService.onEnd$,
                    ).pipe(first()),
                  ),
                );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((lifecycleInfo) => {
        this.columns
          .filter(
            (column) =>
              !lifecycleInfo.transitions.some(
                (t) => t.nextStateId === column.stateId,
              ) && column.stateId !== lifecycleInfo.currentState.id,
          )
          .forEach((column) => {
            this.event$.next({
              target: 'track',
              id: column.id,
              action: 'restrictTransition',
              data: true,
            });
          });
      });

    this.dragDropService.completed$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.event$.next({
          target: 'track',
          id: null,
          action: 'restrictTransition',
          data: false,
        });
      });
  }

  private getFilter(): { and: Array<any> } | any | null {
    const originalFilter = this.filterService?.getODataFilter();

    if (!originalFilter) {
      return null;
    }

    if (!this.config?.filterService || !this.config?.isNestedFilterQuery) {
      return originalFilter;
    }

    const newFilter = {
      and: [],
    };

    for (const value of originalFilter) {
      const item = Object.values(value).pop();

      if (!Array.isArray(item)) {
        newFilter.and.push(item);
      } else if (Array.isArray(item) && item.length) {
        newFilter.and.push({
          or: item.map((i) => Object.values(i).pop()),
        });
      }
    }

    return newFilter.and.length ? newFilter : null;
  }

  private setDisableDrag(value: boolean): void {
    this.event$.next({
      target: 'track',
      action: 'disableDrag',
      data: value,
    });
  }
}
