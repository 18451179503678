<div name="scroll-table-header">
  <table
    class="table wp-nested-table"
    [ngStyle]="{ width: projectResourceService.rightTableWidth + 'px' }"
  >
    <colgroup>
      @for (slot of projectResourceService.slots; track slot.id) {
        <col />
      }
    </colgroup>
    <thead>
      <tr>
        @for (group of projectResourceService.slotGroups; track group.id) {
          <th
            [ngStyle]="{ width: projectResourceService.slotWidth * group.slotsCount + 'px' }"
            [attr.colspan]="group.slotsCount"
            title="{{ group.hint }}"
          >
            {{ group.header }}
          </th>
        }
      </tr>
      <tr>
        @for (slot of projectResourceService.slots; track slot.id) {
          <th
            class="slot"
            [ngClass]="{
              'slot-active': slot.today,
              'slot-active--forecast': projectResourceDataService.isForecastMode && slot.today
            }"
            title="{{ slot.hint }}"
            [attr.data-test-date]="slot.date.toISODate()"
          >
            {{ slot.header }}
            @if (projectResourceDataService.isForecastMode) {
              @if (slot.isActual) {
                <span class="d-block m-0 p-0 slot-hint">
                  {{ 'projects.projects.card.resources.info.Actual' | translate | lowercase }}
                </span>
              } @else {
                <span class="d-block m-0 p-0 slot-hint">
                  {{ 'projects.projects.card.resources.info.Estimate' | translate | lowercase }}
                </span>
              }
            }
          </th>
        }
      </tr>
    </thead>
  </table>
</div>

<div name="scroll-table-body">
  <table
    class="table wp-nested-table"
    [ngStyle]="{ width: projectResourceService.getTableWidth() + 'px' }"
  >
    @if (!(projectResourceService.loading$ | async)) {
      <colgroup>
        @for (slot of projectResourceService.slots; track slot.id) {
          <col />
        }
      </colgroup>
      @if (
        !projectResourceDataService.groups.length &&
        !projectResourceDataService.otherActualGroup &&
        !(projectResourceService.loading$ | async)
      ) {
        <tbody>
          <tr>
            <td
              class="text-body-secondary text-center"
              [attr.colspan]="projectResourceService.slots.length"
            >
              {{ 'shared.noDisplayData' | translate }}
            </td>
          </tr>
        </tbody>
      }
      @for (group of projectResourceDataService.groups; track group.id) {
        <tbody
          tmtProjectResourcesCalendarRightGroup
          [group]="group"
          [valueMode]="projectResourceService.valueMode"
          [scale]="projectResourceService.planningScale"
          [isShowTaskDuration]="isShowTaskDuration"
          [currentPeriodSlot]="projectResourceService.currentPeriodSlot"
        ></tbody>
      }
      <!-- Other Actual -->
      @if (projectResourceDataService.otherActualGroup?.totalHours) {
        <tbody
          tmtProjectResourcesCalendarRightGroup
          [group]="projectResourceDataService.otherActualGroup"
          [static]="true"
          [empty]="!projectResourceService.showOtherActual"
          [valueMode]="projectResourceService.valueMode"
          [scale]="projectResourceService.planningScale"
          [currentPeriodSlot]="projectResourceService.currentPeriodSlot"
        ></tbody>
      }
    }
  </table>
</div>

<div name="scroll-table-footer">
  <table
    class="table wp-nested-table"
    [ngStyle]="{ width: projectResourceService.rightTableWidth + 'px' }"
  >
    @if (!(projectResourceService.loading$ | async)) {
      <colgroup>
        @for (slot of projectResourceService.slots; track slot.id) {
          <col />
        }
      </colgroup>
      <!-- Часы -->
      <tr>
        @for (slot of projectResourceService.slotTotals; track slot.id) {
          <td
            class="total-cell text-truncate"
            [ngClass]="{
              'non-working': slot.nonWorking,
              'slot-active--forecast': projectResourceDataService.isForecastMode && slot.today
            }"
            [ngStyle]="{ width: projectResourceService.slotWidth + 'px' }"
            title="{{ slot.hours | plannerFormat }}"
          >
            {{ slot.hours | plannerFormat }}
          </td>
        }
      </tr>
      <!-- Себестоимость -->
      @if ((projectCardService.project$ | async).financeViewAllowed) {
        <tr>
          @for (slot of projectResourceService.slotTotals; track slot.id) {
            <td
              class="total-cell text-truncate"
              [ngClass]="{
                'non-working': slot.nonWorking,
                'slot-active--forecast': projectResourceDataService.isForecastMode && slot.today
              }"
              title="{{ slot.cost | zeroToNull | number: '1.0-0' }}"
            >
              {{ slot.cost | zeroToNull | number: '1.0-0' }}
            </td>
          }
        </tr>
      }
    }
  </table>
</div>
