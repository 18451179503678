import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { SettingsCardService } from '../settings-card.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Subscription } from 'rxjs';
import {
  TenantSetting,
  TenantSettingPayload,
} from 'src/app/shared/models/entities/settings/multitenant/tenant-settings.model';
import { Exception } from 'src/app/shared/models/exception';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-settings-security',
  templateUrl: './settings-security.component.html',
  styleUrls: ['./settings-security.component.scss'],
  providers: [GridService],
})
export class SettingsSecurityComponent implements OnInit, OnDestroy {
  public isSaving = false;
  public state: CardState;

  public readonly formValidatorValues = {
    passwordSetting: {
      passwordUpdatePeriodDays: {
        min: 0,
        max: 999999,
      },
      maxInvalidLoginAttempts: {
        min: 1,
        max: 999999,
      },
      lockoutMinutes: {
        min: 1,
        max: 999999,
      },
      minPasswordLength: {
        min: 6,
        max: 15,
      },
    },
  };

  public form: UntypedFormGroup = this.fb.group({
    id: null,
    passwordSetting: this.fb.group({
      passwordUpdatePeriodDays: [
        0,
        [
          Validators.required,
          Validators.min(
            this.formValidatorValues.passwordSetting.passwordUpdatePeriodDays
              .min,
          ),
          Validators.max(
            this.formValidatorValues.passwordSetting.passwordUpdatePeriodDays
              .max,
          ),
        ],
      ],
      maxInvalidLoginAttempts: [
        1,
        [
          Validators.required,
          Validators.min(
            this.formValidatorValues.passwordSetting.maxInvalidLoginAttempts
              .min,
          ),
          Validators.max(
            this.formValidatorValues.passwordSetting.maxInvalidLoginAttempts
              .max,
          ),
        ],
      ],
      lockoutMinutes: [
        1,
        [
          Validators.required,
          Validators.min(
            this.formValidatorValues.passwordSetting.lockoutMinutes.min,
          ),
          Validators.max(
            this.formValidatorValues.passwordSetting.lockoutMinutes.max,
          ),
        ],
      ],
      minPasswordLength: [
        0,
        [
          Validators.required,
          Validators.min(
            this.formValidatorValues.passwordSetting.minPasswordLength.min,
          ),
          Validators.max(
            this.formValidatorValues.passwordSetting.minPasswordLength.max,
          ),
        ],
      ],
      requireDigits: [false],
      requireSpecSymbols: [false],
      requireCapitalLetters: [false],
      useOtp: [false],
    }),
  });

  tenantSettings: TenantSetting;

  private reloadSubscription: Subscription;

  constructor(
    private service: SettingsCardService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
  ) {}

  ngOnInit(): void {
    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: true,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.reloadSubscription = this.service.reloadTab$.subscribe(() =>
      this.reload(),
    );
  }

  ngOnDestroy(): void {
    this.reloadSubscription.unsubscribe();
  }

  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  public save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const tenantSetting = this.form.value as TenantSetting;

    const setting: TenantSettingPayload = {
      id: tenantSetting.id,
      languageCode: this.tenantSettings.languageCode,
      cultureCode: this.tenantSettings.cultureCode,
      timeZoneId: this.tenantSettings.timeZone.id,
      currencyId: this.tenantSettings.currency.id,
      useVat: this.tenantSettings.useVat,
      passwordSetting: tenantSetting.passwordSetting,
      forecastPeriod: this.tenantSettings.forecastPeriod,
    };

    this.data
      .collection('TenantSettings')
      .action('WP.UpdateSingle')
      .execute({ setting })
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.settings.messages.settingSaved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  private load() {
    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.data
      .collection('TenantSettings')
      .function('WP.GetSingle')
      .get<TenantSetting>(null, {
        select: '*',
        expand: {
          timeZone: { select: ['id', 'name'] },
          currency: { select: ['id', 'name'] },
        },
      })
      .subscribe({
        next: (data) => {
          this.tenantSettings = data;
          this.form.patchValue(data);

          this.state = CardState.Ready;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state = CardState.Error;
        },
      });
  }
}
