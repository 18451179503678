<div *ngIf="entries.length == 0">
  {{ 'timesheets.details.commentsIsEmpty' | translate }}
</div>

<table
  style="width: 100%; table-layout: fixed"
  class="table table-striped"
  *ngIf="entries.length > 0"
>
  <thead>
    <tr>
      <th
        style="width: 80px"
        title="{{ 'timesheets.card.columns.date.hint' | translate }}"
        class="trim"
      >
        {{ 'timesheets.card.columns.date.header' | translate }}
      </th>
      <th
        *ngIf="template.showClient"
        style="width: 335px"
        title="{{ 'timesheets.card.columns.task.hint' | translate }}"
        class="trim"
      >
        {{ 'timesheets.card.columns.task.header' | translate }}
      </th>
      <th
        *ngIf="!template.showClient"
        style="width: 335px"
        title="{{ 'timesheets.card.columns.taskWithoutClient.hint' | translate }}"
        class="trim"
      >
        {{ 'timesheets.card.columns.taskWithoutClient.header' | translate }}
      </th>
      <th
        *ngIf="template.showActivity"
        style="width: 100px"
        title="{{ 'timesheets.card.columns.activity.hint' | translate }}"
        class="trim"
      >
        {{ 'timesheets.card.columns.activity.header' | translate }}
      </th>
      <th
        *ngIf="template.showRole"
        style="width: 100px"
        title="{{ 'timesheets.card.columns.role.hint' | translate }}"
        class="trim"
      >
        {{ 'timesheets.card.columns.role.header' | translate }}
      </th>
      @if (template.showProjectCostCenter) {
        <th
          style="width: 150px"
          title="{{ 'timesheets.card.columns.projectCostCenter.hint' | translate }}"
          class="trim"
        >
          {{ 'timesheets.card.columns.projectCostCenter.header' | translate }}
        </th>
      }
      @if (template.showTariff) {
        <th
          style="width: 150px"
          title="{{ 'timesheets.card.columns.projectTariff.hint' | translate }}"
          class="trim"
        >
          {{ 'timesheets.card.columns.projectTariff.header' | translate }}
        </th>
      }
      <th
        style="width: 75px"
        title="{{ 'timesheets.card.columns.hours.hint' | translate }}"
        class="trim"
      >
        {{ 'timesheets.card.columns.hours.header' | translate }}
      </th>
      @for (field of timesheetCardService.allocationCustomFields; track field) {
        <th style="width: 110px" title="{{ field.viewConfiguration.label }}" class="trim">
          {{ field.viewConfiguration.label }}
        </th>
      }

      <th
        style="width: 100%"
        title="{{ 'timesheets.card.columns.comment.hint' | translate }}"
        class="trim"
      >
        {{ 'timesheets.card.columns.comment.header' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let entry of entries; trackBy: trackById">
      <td title="{{ entry.allocation.date | date: 'shortDate' }}">
        {{ entry.allocation.date | date: 'shortDate' }}
      </td>
      <td title="{{ entry.firstLine + '&#013;' + entry.secondLine }}">
        <div class="trim first-line">
          {{ entry.firstLine }}
        </div>
        <div class="trim">{{ entry.secondLine }}</div>
      </td>
      <td *ngIf="template.showActivity" title="{{ entry.activityName }}" class="trim-multiline">
        {{ entry.activityName }}
      </td>
      <td *ngIf="template.showRole" title="{{ entry.roleName }}" class="trim-multiline">
        {{ entry.roleName }}
      </td>
      @if (template.showProjectCostCenter) {
        <td title="{{ entry.projectCostCenterName }}" class="trim-multiline">
          {{ entry.projectCostCenterName }}
        </td>
      }
      @if (template.showTariff) {
        <td title="{{ entry.projectTariffName }}" class="trim-multiline">
          {{ entry.projectTariffName }}
        </td>
      }
      <td class="trim" title="{{ entry.allocation.hours | wpTimeDuration: 0 : false }}">
        {{ entry.allocation.hours | wpTimeDuration: entry.schedule : false }}
      </td>
      @for (field of timesheetCardService.allocationCustomFields; track field) {
        <td
          class="trim"
          title="{{ getCustomFieldValue(entry, field) | async | wpCustomFieldValue: field }}"
        >
          {{ getCustomFieldValue(entry, field) | async | wpCustomFieldValue: field }}
        </td>
      }

      <td
        class="trim-multiline"
        title="{{ entry.allocation.description }}"
        [innerHTML]="entry.allocation.description | linky | nl2br"
      ></td>
    </tr>
  </tbody>
</table>
