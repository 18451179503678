import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { AppService } from './core/app.service';
import { AuthService } from './core/auth.service';
import { NavigationService } from './core/navigation.service';
import { Navigation } from './shared/models/navigation/navigation';
import { TranslateService } from '@ngx-translate/core';
import {
  NgbModalConfig,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { startWith } from 'rxjs';
import { ChromeService } from './core/chrome.service';
import { ResizedEvent } from 'angular-resize-event';
import { ClientSession } from './shared/models/session/client-session.model';
import { MessageService } from './core/message.service';
import { detectAnyAdblocker } from 'just-detect-adblock';
import { LogService } from './core/log.service';
import { UpdateService } from './core/update.service';
import { TransitionService } from '@uirouter/core';
import { ModalForm } from 'src/app/shared/models/inner/modal-form.interface';
import { CreateMenuService } from 'src/app/core/create-menu.service';
import _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import { Position } from 'src/app/shared/models/inner/position.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppConfigService } from 'src/app/core/app-config.service';
import { UserSettingsModalComponent } from './shared/modules/user-settings-modal/user-settings-modal.component';
import { SelectingOfSubstituteModalComponent } from './selecting-of-substitute-modal/selecting-of-substitute-modal.component';
import { FireWork } from 'src/app/shared/globals/firework';

@Component({
  selector: 'wp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UpdateService, CreateMenuService],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('appContent') appContent: ElementRef;

  session: ClientSession;
  compactMode = true;
  moduleDisplayName: string;
  indicators = [];
  areaInCompactMode = false;
  selectedNavigationItem = null;
  isAppMenuVisible = false;
  modalInstances: NgbModalRef[] = [];
  public newYearForm: UntypedFormGroup = this.fb.group({
    background: false,
    snow: false,
    garland: false,
    firework: false,
  });
  private configKeyStorage = 'tmt_config_new_year';
  private mouseListener: () => void | null = null;
  private clickListener: () => void | null = null;

  public appConfigService = AppConfigService;

  constructor(
    public app: AppService,
    public log: LogService,
    public authService: AuthService,
    public createMenuService: CreateMenuService,
    private wpNavigationService: NavigationService,
    private translate: TranslateService,
    private chromeService: ChromeService,
    private messages: MessageService,
    private transitionService: TransitionService,
    ngbModalConfig: NgbModalConfig,
    private modal: NgbModal,
    private updateService: UpdateService,
    private renderer: Renderer2,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private fb: UntypedFormBuilder,
  ) {
    ngbModalConfig.backdrop = false;
    this.session = app.session;

    chromeService.compactMode$.subscribe((mode) => {
      this.compactMode = mode;
    });

    // Comment it on New Year:
    // localStorage.setItem(
    //   this.configKeyStorage,
    //   JSON.stringify(this.newYearForm.value),
    // );

    // Uncomment it on New Year:
    if (localStorage.getItem(this.configKeyStorage)) {
      this.newYearForm.patchValue(
        JSON.parse(localStorage.getItem(this.configKeyStorage)),
      );
    }
  }

  public showAppMenu(event: MouseEvent): void {
    event.stopPropagation();
    this.isAppMenuVisible = true;
  }

  onAppMenuClosed() {
    this.isAppMenuVisible = false;
  }

  openHelp() {
    const beacon = (window as any).Beacon;
    if (beacon) {
      beacon('open');
    } else {
      this.messages.errorLocal('scout.scoutWasBlocked');
    }
  }

  signOut() {
    this.authService.signOutWithSessionDestroy();
  }

  // TODO: why the event?
  onMainResized(event: ResizedEvent) {
    this.chromeService.setMainAreaDimension();
  }

  public loginAsSubstitute() {
    this.modal.open(SelectingOfSubstituteModalComponent, { size: 'md' });
  }

  public stopSubstituting() {
    this.authService.stopSubstituting();
  }

  public userSettings() {
    this.modal.open(UserSettingsModalComponent, { size: 'md' });
  }

  ngOnInit(): void {
    this.wpNavigationService.navigation$.subscribe((navigation: Navigation) => {
      if (navigation === null) {
        return;
      }
      this.moduleDisplayName = this.translate.instant(
        navigation.applicationTitle,
      );
    });

    if (this.app.session.configuration.tenantIsReadOnly) {
      this.messages.message(
        this.translate.instant(
          'shared.suspendedAttention.suspendedModeWarning',
        ),
        this.translate.instant('shared.suspendedAttention.attention'),
      );
    }

    // Some routes are redirected in start, and it leads to close any modals.

    setTimeout(() => {
      detectAnyAdblocker().then((detected) => {
        if (detected) {
          this.messages.message(
            this.translate.instant('shared.messages.disablePopupBlocker'),
          );
        }
      });
    }, 100);

    this.modal.activeInstances.subscribe((value) => {
      this.modalInstances = value;
    });

    this.transitionService.onSuccess(null, () => {
      if (
        !this.modalInstances.length ||
        this.modalInstances.some(
          (instance) => (instance.componentInstance as ModalForm).isSharedModal,
        )
      ) {
        return;
      }
      this.modal.dismissAll('Dismiss on state changing.');
    });

    this.updateService.run();
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.renderer.listen(this.appContent.nativeElement, 'scroll', () => {
        this.chromeService.scrollMainArea();
      });
    });

    const loader = this.document.querySelector('#tmt-loader');
    const loaderContainer = this.document.querySelector(
      '#tmt-loader-container',
    );

    this.renderer.addClass(loader, 'water-animation');
    this.renderer.addClass(loaderContainer, 'fade-animation');

    const loaderContainerListener = this.renderer.listen(
      loaderContainer,
      'animationend',
      () => {
        this.renderer.removeChild(this.document, loaderContainer);
        loaderContainerListener();
      },
    );

    this.newYearForm.valueChanges
      .pipe(startWith(this.newYearForm.value))
      .subscribe((values) => {
        localStorage.setItem(this.configKeyStorage, JSON.stringify(values));

        if (values['snow']) {
          this.zone.runOutsideAngular(() => {
            this.makeMagic();
          });
        } else {
          this.dispelMagic();
        }

        if (values['firework']) {
          this.zone.runOutsideAngular(() => {
            this.makeFirework();
          });
        } else {
          this.dispelFirework();
        }
      });
  }

  public toggleTheme(): void {
    const html = this.document.querySelector('html');

    this.renderer.setAttribute(
      html,
      'data-bs-theme',
      html.dataset['bsTheme'] === 'dark' ? 'light' : 'dark',
    );
  }

  private dispelFirework(): void {
    if (this.clickListener) {
      this.clickListener();
      this.clickListener = null;
    }
  }

  private makeFirework(): void {
    const fireWork = new FireWork();
    this.clickListener = this.renderer.listen(
      this.document.body,
      'click',
      (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const targetRect = target.getBoundingClientRect();

        if (target.tagName === 'BUTTON' && targetRect.width > 50) {
          fireWork.run(targetRect);
        }
      },
    );
  }

  private dispelMagic(): void {
    if (this.mouseListener) {
      this.mouseListener();
      this.mouseListener = null;
    }
  }

  private makeMagic(): void {
    this.dispelMagic();

    const iconClassName = 'bi-asterisk';
    const diffLimit = 75;
    const colors = ['#428bca', '#6da5d6', '#6699cc'];
    const lastPosition: Position = {
      x: null,
      y: null,
    };

    this.mouseListener = this.renderer.listen(
      this.document.body,
      'mousemove',
      (event: MouseEvent) => {
        if (this.document.querySelectorAll(`.${iconClassName}`).length > 50) {
          return;
        }

        if (
          Math.abs(event.clientX - lastPosition.x) < diffLimit &&
          Math.abs(event.clientY - lastPosition.y) < diffLimit &&
          lastPosition.y !== null
        ) {
          return;
        }

        const star: HTMLElement = this.renderer.createElement('i');
        const starColor = colors[_.random(0, colors.length - 1)];
        const starFallHeight =
          event.clientX - lastPosition.x < 0
            ? _.random(40, 80)
            : _.random(-80, -40);

        this.renderer.addClass(star, 'bi');
        this.renderer.addClass(star, iconClassName);
        this.renderer.addClass(star, 'magic-star');
        this.renderer.setProperty(
          star,
          'style',
          `--height: ${starFallHeight}px`,
        );
        this.renderer.setStyle(star, 'top', `${event.clientY}px`);
        this.renderer.setStyle(star, 'left', `${event.clientX}px`);
        this.renderer.setStyle(star, 'font-size', `${_.random(7.5, 10)}px`);
        this.renderer.setStyle(star, 'color', starColor);
        this.renderer.setStyle(star, 'text-shadow', `0 0 4px ${starColor}cc`);
        this.renderer.setStyle(star, 'animation-name', 'magic');
        this.renderer.setStyle(star, 'animation-iteration', '1');
        this.renderer.setStyle(star, 'animation-fill-mode', 'forwards');
        this.renderer.setStyle(
          star,
          'animation-duration',
          `${_.random(1.5, 2.5)}s`,
        );

        this.renderer.appendChild(this.document.body, star);

        lastPosition.x = event.clientX;
        lastPosition.y = event.clientY;

        const starListener = this.renderer.listen(star, 'animationend', () => {
          star.remove();
          starListener();
        });
      },
    );
  }
}
