<div name="toolbar">
  <div class="toolbar-container">
    <wp-project-resources-calendar-toggler />
    <wp-schedule-navigation
      [visibleParts]="scheduleNavigationParts"
      [disabled]="projectResourceService.frameLoading$ | async"
    />
    <!-- TODO: move resource plan temporary turned off -->
    <!--       <div class="group" *ngIf="!service.readonly">
      <button
        type="button"
        [disabled]="service.loading$ | async"
        title="{{ 'projects.projects.card.resources.actions.move.hint' | translate }}"
        class="btn btn-default"
        (click)="service.movePlan()"
        >
        {{ 'projects.projects.card.resources.actions.move.label' | translate }}
      </button>
    </div> -->
    @if (!projectResourceDataService.readonly) {
      <div class="group">
        <div class="group">
          <button
            type="button"
            [disabled]="projectResourceService.loading$ | async"
            title="{{ 'shared.actions.clear' | translate }}"
            class="btn btn-default"
            (click)="projectResourceService.clearPlan()"
          >
            {{ 'shared.actions.clear' | translate }}
          </button>
        </div>
      </div>
    }
    <tmt-undo-redo-buttons />
    <div class="group ms-auto">
      <wp-resources-view-form />
      <button
        type="button"
        class="btn btn-default"
        (click)="gridService.execute('setUserView')"
        title="{{ 'shared.actions.setView' | translate }}"
        data-test="setView"
      >
        <i class="bi bi-gear" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
