<tmt-grid
  [ngClass]="{
    'double-header-height': true,
    'double-footer-height': (projectCardService.project$ | async).financeViewAllowed
  }"
  [formArray]="gridDataService.formArray"
  [options]="gridOptions"
  [totals]="!(projectResourceService.loading$ | async) ? totals : null"
  [loading]="projectResourceService.loading$ | async"
/>
