import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  public phonePattern = '+_ (___) ___-__-__';

  /** Formats a numeric string into the phone pattern. */
  public formatPhone(value: string): string {
    if (!value) return;
    this.checkPattern(value.length);
    let formatted = this.phonePattern;

    for (const char of value.replace(/\D/g, '')) {
      formatted = formatted.replace('_', char);
    }

    const firstUnderscore = formatted.indexOf('_');
    if (firstUnderscore !== -1) {
      formatted = formatted.substring(0, firstUnderscore);
    }

    return formatted;
  }

  /** Defines the type of template depending on the number of entered characters. */
  public checkPattern(numberCount: number) {
    switch (numberCount) {
      case 12:
        this.phonePattern = '+__ (___) ___-__-__';
        break;
      case 13:
        this.phonePattern = '+___ (___) ___-__-__';
        break;
      default:
        this.phonePattern = '+_ (___) ___-__-__';
    }
  }
}
