import {
  GridColumnType,
  GridStringControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { List } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const DIRECTORY_ENTRIES_LIST: List = {
  name: 'directoryEntries',
  version: 2,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 250,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'code',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 250,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridStringControlColumn>{
      name: 'name',
      type: GridColumnType.StringControl,
      header: 'shared2.props.name',
      hint: 'shared2.props.name',
      availableTotals: [TotalType.Count],
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridStringControlColumn>{
      name: 'code',
      type: GridColumnType.StringControl,
      header: 'shared2.props.code',
      hint: 'shared2.props.code',
      fixedWidth: true,
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridStringControlColumn>{
      name: 'description',
      type: GridColumnType.StringControl,
      header: 'shared2.props.description',
      hint: 'shared2.props.description',
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    {
      name: 'isActive',
      header: 'shared2.props.isActive.value',
      hint: 'shared2.props.isActive.hint',
      type: GridColumnType.BooleanControl,
      forceCellUpdating: true,
    },
  ],
};
