import { Component, Input, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

import { FinancialTaskCellService } from './financial-task-cell.service';

@Component({
  selector: 'tmt-financial-task-cell',
  templateUrl: './financial-task-cell.component.html',
  styleUrls: ['./financial-task-cell.component.scss'],
})
export class FinancialTaskCellComponent implements GridComponentCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  public get groupValue(): any {
    return this.formGroup.getRawValue();
  }

  constructor(
    public gridService: GridService,
    public financialTaskCellService: FinancialTaskCellService,
    private projectTasksService: ProjectTasksService,
    private cdr: ChangeDetectorRef,
    @Inject('entityId') public entityId,
  ) {
    this.gridService.detectChanges$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  public getTaskFullName(): string {
    return this.projectTasksService.getTaskFullName(
      this.groupValue.projectTask,
    );
  }
}
