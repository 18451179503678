import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractTotalCell } from 'src/app/shared-features/grid/grid-cell/abstract-total-cell.directive';

@Component({
  selector: 'tmt-resource-total-cell',
  styleUrls: ['./resource-total-cell.component.scss'],
  templateUrl: './resource-total-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceTotalCellComponent extends AbstractTotalCell {
  constructor(
    public projectCardService: ProjectCardService,
    public projectResourceService: ProjectResourceService,
    gridService: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
    projectResourceService.changes$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.cdr.detectChanges();
    });
  }
}
