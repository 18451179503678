<wp-loading-indicator [loading]="isLoading" [isSpinnerMode]="true" />

@if (!isLoading && !notFound) {
  <div class="user-info-container">
    <div class="user-info-block d-flex pb-1">
      <div class="avatar-block me-3 d-flex flex-column justify-content-between">
        <img [src]="user?.id | avatar: 100" />
        <button class="btn btn-link m-0 p-0" (click)="switch()">
          <i
            class="bi"
            [ngClass]="{
              'bi-chevron-double-down': settings?.collapsed,
              'bi-chevron-double-up': !settings?.collapsed
            }"
            aria-hidden="true"
          ></i>
        </button>
      </div>

      <div class="user-details-block trim-multiline">
        <a
          class="user-name"
          title="{{ user?.name }}"
          (click)="close()"
          uiSref="employee"
          [uiParams]="{
            entityId: user.id,
            routeMode: stateParams.routeMode,
            navigation: stateParams.navigation,
            route: stateParams.route
          }"
          >{{ user?.name }}</a
        >

        @if (user.position) {
          <p class="trim">
            {{ user.position }}
          </p>
        }

        <p class="trim">
          <a title="{{ user?.email }}" href="mailto://{{ user?.email }}">{{ user?.email }}</a>
        </p>

        @if (user?.phone) {
          <p class="trim ps-1" title="{{ user?.phone }}">
            {{ user?.phone }}
          </p>
        }

        @if (user?.level) {
          <p class="trim" title="{{ user?.level?.name }}">
            {{ user?.level?.name }}
          </p>
        }
      </div>
    </div>

    <div class="row" [hidden]="settings?.collapsed">
      <div class="col-6">
        @if (user?.department) {
          <div>
            <small class="text-body-secondary">{{ 'shared.props.department' | translate }}</small>
            <p class="trim" title="{{ user?.department?.name }}">
              {{ user?.department?.name }}
            </p>
          </div>
        }

        @if (user?.supervisor) {
          <div>
            <small class="text-body-secondary">{{ 'shared.props.supervisor' | translate }}</small>
            <p class="trim" title="{{ user?.supervisor?.name }}">
              {{ user?.supervisor?.name }}
            </p>
          </div>
        }
        @if (user?.location) {
          <div>
            <small class="text-body-secondary">{{ 'shared.props.location' | translate }}</small>
            <p class="trim" title="{{ user?.location?.name }}">
              {{ user?.location?.name }}
            </p>
          </div>
        }
      </div>
      <div class="col-6">
        @if (user?.resourcePool) {
          <div>
            <small class="text-body-secondary">{{ 'shared.props.resourcePool' | translate }}</small>
            <p class="trim" title="{{ user?.resourcePool?.name }}">
              {{ user?.resourcePool?.name }}
            </p>
          </div>
        }
        @if (user?.role) {
          <div>
            <small class="text-body-secondary">{{ 'shared.props.role' | translate }}</small>
            <p class="trim" title="{{ user?.role?.name }}">
              {{ user?.role?.name }}
            </p>
          </div>
        }
        @if (user?.competence) {
          <div>
            <small class="text-body-secondary">{{ 'shared.props.competence' | translate }}</small>
            <p class="trim" title="{{ user?.competence?.name }}">
              {{ user?.competence?.name }}
            </p>
          </div>
        }
      </div>

      <div class="col-12">
        @if (skills) {
          <div>
            <small class="text-body-secondary">{{ 'shared.userInfo.skills' | translate }}</small>
            <p class="trim" [title]="skills">
              {{ skills }}
            </p>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (notFound) {
  <div class="text-center text-body-secondary text-uppercase">
    {{ 'shared.entityNotFound.title' | translate }}
  </div>
}
