@if (!loading()) {
  <div ngbDropdown class="condition__operator" placement="bottom-left">
    <button
      type="button"
      class="btn btn-default"
      ngbDropdownToggle
      title="{{ 'analytics.reportFilters.condition.operator' | translate }}"
    >
      {{ 'enums.filterOperator.' + operator() | translate }}
    </button>

    <div ngbDropdownMenu class="dropdown-scroll">
      @for (item of operators; track $index) {
        <button ngbDropdownItem (click)="operator.set(item)">
          {{ 'enums.filterOperator.' + item | translate }}
        </button>
      }
    </div>
  </div>

  <div class="condition__control">
    @if (
      ['Navigation', 'Directory'].includes(metaEntityPropertyData().type) &&
      ['Equal', 'NotEqual'].includes(operator())
    ) {
      @switch (metaEntityPropertyData().clrType) {
        @case ('User') {
          <wp-user-box
            [formControl]="propertyControl"
            [allowInactive]="allowInactive()"
            placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
          />
        }

        @case ('State') {
          <wp-select-box
            [formControl]="propertyControl"
            [values]="states$"
            placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
          />
        }

        @default {
          @if (parentIdKey) {
            <tmt-hierarchical-box
              [formControl]="propertyControl"
              [parentIdKey]="parentIdKey"
              [collection]="collectionName"
              [allowInactive]="allowInactive()"
              placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
            />
          } @else {
            <wp-select-box
              [formControl]="propertyControl"
              [collection]="collectionName"
              [directoryId]="directoryId"
              [allowInactive]="allowInactive()"
              placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
            />
          }
        }
      }
    }

    @if (['String', 'Text'].includes(metaEntityPropertyData().type)) {
      <wp-text-box
        [formControl]="propertyControl"
        placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
      />
    }

    @if (['Integer', 'Decimal'].includes(metaEntityPropertyData().type)) {
      @switch (operator()) {
        @case ('Between') {
          <div class="range-group" [formGroup]="propertyRangeForm">
            <wp-number-box
              formControlName="left"
              [type]="metaEntityPropertyData().type === 'Integer' ? 'integer' : 'decimal'"
              [allowNull]="true"
              placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
            />
            <span>—</span>
            <wp-number-box
              formControlName="right"
              [type]="metaEntityPropertyData().type === 'Integer' ? 'integer' : 'decimal'"
              [allowNull]="true"
              placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
            />
          </div>
        }

        @default {
          <wp-number-box
            [formControl]="propertyControl"
            [type]="metaEntityPropertyData().type === 'Integer' ? 'integer' : 'decimal'"
            [allowNull]="true"
            placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
          />
        }
      }
    }

    @if (metaEntityPropertyData().type === 'Boolean') {
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [formControl]="propertyControl" />
      </div>
    }

    @if (['DateTime', 'DateOnly'].includes(metaEntityPropertyData().type)) {
      @switch (operator()) {
        @case ('InPeriod') {
          <wp-date-period-box
            [formControl]="propertyControl"
            placeholder="{{ 'components.filterConditionComponent.props.value' | translate }}"
            [advanced]="true"
          />
        }

        @default {
          <wp-date-box [formControl]="propertyControl" />
        }
      }
    }
  </div>

  <button
    class="btn btn-icon btn--remove"
    (click)="onRemoveClick.emit()"
    [ngbTooltip]="'components.filterConditionComponent.actions.removeCondition' | translate"
  >
    <i class="bi bi-trash"></i>
  </button>
} @else {
  <wp-loading-indicator class="w-100 mt-2" [loading]="true" />
}
