import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

/** Abstract class for total cell component type. */
@Directive()
export class AbstractTotalCell implements OnInit {
  @Input() column: GridColumn;

  protected destroyRef = inject(DestroyRef);
  constructor(
    protected gridService: GridService,
    protected cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.gridService.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());
  }
}
