import _ from 'lodash';
import { interval, startWith, take } from 'rxjs';

//** Not works without styles! Check .fire__line class. */
class FireLine {
  public elRect: DOMRect;
  public params: Partial<FireLine>;
  public x: number;
  public y: number;
  public height: number;
  public angle: number;
  public bgColor: string;
  public filterColor: string;

  constructor(elRect: DOMRect, params?: Partial<FireLine>) {
    this.elRect = elRect;
    this.height = this.getRandom(70, 270);
    Object.entries(this.getPositionAndAngle()).forEach(([key, value]) => {
      this[key] = value;
    });
    const [bgColor, filterColor] =
      FireLine.colors[this.getRandom(0, FireLine.colors.length - 1)];
    this.bgColor = bgColor;
    this.filterColor = filterColor;

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        this[key] = value;
      });
    }
  }

  /**
   * Creates line and start animation.
   *
   * @returns Resolves on animation end.
   */
  public shoot(): Promise<void> {
    return new Promise((resolve) => {
      const fireLine = document.createElement('div');

      fireLine.classList.add('fire__line');
      fireLine.setAttribute(
        'style',
        `--height: ${this.height}px; --rotate: ${this.angle}deg; --bg-color: ${this.bgColor}; --filter-color: ${this.filterColor}`,
      );
      fireLine.style.top = this.y + 'px';
      fireLine.style.left = this.x + 'px';

      fireLine.addEventListener('animationend', () => {
        fireLine.remove();
        resolve();
      });

      document.body.appendChild(fireLine);
    });
  }

  protected getPositionAndAngle(): {
    x: number;
    y: number;
    angle: number;
  } {
    const neededSpace = this.height * FireLine.trackMP;
    const { top, right, bottom, left } = this.elRect;
    const allowedSide: number[] = [];
    let side: number;

    if (top > neededSpace) {
      allowedSide.push(1);
    }

    if (window.innerHeight - bottom > neededSpace) {
      allowedSide.push(2);
    }

    if (left > neededSpace) {
      allowedSide.push(3);
    }

    if (window.innerWidth - right > neededSpace) {
      allowedSide.push(4);
    }

    while (!allowedSide.includes(side)) {
      side = this.getRandom(1, 4);

      if (!allowedSide.length) {
        return;
      }
    }

    switch (side) {
      case 1: // TOP
        return {
          x: this.getRandom(left, right),
          y: top,
          angle: this.getRandom(100, 260),
        };
      case 2: // DOWN
        return {
          x: this.getRandom(left, right),
          y: bottom,
          angle: this.getRandom(-60, 60),
        };
      case 3: // LEFT
        return {
          x: left,
          y: this.getRandom(top, bottom),
          angle: this.getRandom(60, 180),
        };
      case 4: // RIGHT
        return {
          x: right,
          y: this.getRandom(top, bottom),
          angle: this.getRandom(-60, -180),
        };
    }
  }

  protected getRandom(from: number, to: number): number {
    return _.random(from, to);
  }

  public static colors: Array<[string, string]> = [
    ['rgba(95, 145, 255, 1)', 'rgba(105, 155, 255, 1)'], // Blue
    ['rgba(255, 85, 85, 1)', 'rgba(255, 100, 100, 1)'], // Red
    ['rgba(142, 239, 142, 1)', 'rgba(100, 255, 100, 1)'], // Green
    ['rgba(255, 85, 255, 1)', 'rgba(255, 100, 255, 1)'], // Pink
    ['rgba(125, 225, 225, 1)', 'rgba(100, 255, 255, 1)'], // Cyan
    ['rgba(255, 170, 85, 1)', 'rgba(255, 180, 100, 1)'], // Orange
    ['rgba(170, 85, 255, 1)', 'rgba(180, 100, 255, 1)'], // Purple
    ['rgba(85, 85, 255, 1)', 'rgba(100, 100, 255, 1)'], // Dark blue
    ['rgba(85, 255, 170, 1)', 'rgba(100, 255, 180, 1)'], // Lime
  ];

  /**
   * Calculates coordinates of line destination.
   *
   * @param x1 Start X.
   * @param y1 Start Y.
   * @param angleDegrees angle.
   * @param length line height.
   * @param angleShift shift relative to document.
   * @returns Endpoint coordinates.
   */
  public static calculateEndPoint(
    x1: number,
    y1: number,
    angleDegrees: number,
    length: number,
    angleShift = 90,
  ): { x: number; y: number } {
    const angleRadians = ((angleDegrees + angleShift) * Math.PI) / 180;

    const x2 = x1 + length * Math.cos(angleRadians);
    const y2 = y1 + length * Math.sin(angleRadians);

    return { x: x2, y: y2 };
  }

  /** CSS value from animation. */
  public static trackMP = 1.5;
}

export class FireWork {
  public readonly intervalBetweenShoot = 100;

  /**
   * Runs firework!
   *
   * @param rect element coordinates & sizes.
   */
  public run(rect: DOMRect): void {
    if (document.querySelector('.fire__line')) {
      return;
    }

    interval(this.intervalBetweenShoot)
      .pipe(startWith(-1), take(4))
      .subscribe((value) => {
        if (!value) {
          this.simpleShoot(rect);
          this.doubleExplosion(rect);
        }

        if (value === 1) {
          this.simpleShoot(rect);
          this.singleExplosion(rect);
        }

        this.simpleShoot(rect);
      });
  }

  private simpleShoot(rect: DOMRect): void {
    for (let i = 1; i <= 5; i++) {
      new FireLine(rect).shoot();
    }
  }

  private singleExplosion(rect: DOMRect): void {
    for (let i = 1; i <= 3; i++) {
      const fireLine = new FireLine(rect);
      fireLine.shoot().then(() => {
        const { x, y } = FireLine.calculateEndPoint(
          fireLine.x,
          fireLine.y,
          fireLine.angle,
          fireLine.height * FireLine.trackMP,
        );

        let angle = 0;

        while (angle < 360) {
          new FireLine(rect, {
            x,
            y,
            angle,
            height: 50,
            bgColor: fireLine.bgColor,
            filterColor: fireLine.filterColor,
          }).shoot();

          angle += 20;
        }
      });
    }
  }

  private doubleExplosion(rect: DOMRect): void {
    for (let i = 1; i <= 2; i++) {
      const fireLine = new FireLine(rect);
      fireLine.shoot().then(() => {
        const { x, y } = FireLine.calculateEndPoint(
          fireLine.x,
          fireLine.y,
          fireLine.angle,
          fireLine.height * FireLine.trackMP,
        );

        let angle = 0;

        while (angle < 360) {
          const params = {
            x,
            y,
            angle,
            height: 50,
            bgColor: fireLine.bgColor,
            filterColor: fireLine.filterColor,
          };

          new FireLine(rect, params).shoot();
          setTimeout(() => new FireLine(rect, params).shoot(), 500);

          angle += 45;
        }
      });
    }
  }
}
