import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-financial-readonly-amount-cell',
  templateUrl: './financial-amount-readonly-cell.component.html',
  styleUrls: ['./financial-amount-readonly-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialAmountReadonlyCellComponent implements GridComponentCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  constructor(
    public service: GridService,
    public readonly projectCardService: ProjectCardService,
    private cdr: ChangeDetectorRef,
  ) {
    service.detectChanges$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.cdr.detectChanges());
  }

  /**
   * Returns an array of CSS classes based on the form group's value.
   *
   * @returns {string[]} An array of CSS classes.
   */
  public getCss(): string[] {
    const css = [];

    if (this.formGroup.value.type) {
      css.push(this.formGroup.value.type.code.toLowerCase());
    }

    if (this.formGroup.value.isTaskGroup) {
      css.push('task');
    }
    return css;
  }
}
