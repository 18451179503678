export enum CommentedEntityCollectionType {
  ActsOfAcceptance = 'ActsOfAcceptance',
  ResourceRequests = 'ResourceRequests',
  Issues = 'Issues',
  ExpenseRequests = 'ExpenseRequests',
  ProjectVersions = 'ProjectVersions',
  TimeOffRequests = 'TimeOffRequests',
  TimeSheets = 'TimeSheets',
  Certificates = 'Certificates',
  Projects = 'Projects',
  Invoices = 'Invoices',
  RateMatrices = 'RateMatrices',
  Deals = 'Deals',
}
