import { Component, effect, input, Input } from '@angular/core';
import { map, timer } from 'rxjs';
import { SpinnerParams } from 'src/app/shared/components/features/loading-indicator/loading-indicator.model';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

/** Loading indicator. */
@Component({
  selector: 'wp-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
  @Input() public isSpinnerMode = false;
  @Input() public isOverlay = false;
  @Input() public spinnerParams: SpinnerParams;
  @Input() public loading = false;
  @Input() public set state(state: CardState) {
    this.loading = state === CardState.Loading;
  }
  /** Delay in milliseconds */
  @Input() public delay = 300;

  public delayTimer$ = timer(this.delay).pipe(map(() => true));
  public progress = input<number>(0);
  public style: Dictionary<string>;

  /** Returns Record object for spinner styles. */
  public get spinnerStyles(): Record<string, string> {
    if (!this.spinnerParams || !Object.keys(this.spinnerParams).length) {
      return;
    }

    for (const key in this.spinnerParams) {
      if (this.spinnerParams[key]) {
        this.spinnerParams[key] = this.formatCssVar(this.spinnerParams[key]);
      }
    }

    return {
      '--spinner-size': this.spinnerParams?.spinnerSize,
      '--spinner-speed': this.spinnerParams?.spinnerSpeed,
      '--spinner-circle-color': this.spinnerParams?.spinnerCircleColor,
      '--spinner-spin-color': this.spinnerParams?.spinnerSpinColor,
      '--spinner-background-color': this.spinnerParams?.spinnerBackgroundColor,
      '--spinner-border-radius': this.spinnerParams?.spinnerBorderRadius,
      '--spinner-top': this.spinnerParams?.spinnerTopPosition,
    };
  }

  constructor() {
    effect(() => {
      if (this.progress()) {
        this.style = { width: `${this.progress()}%` };
      }
    });
  }

  /**
   * Formats spinner parameters.
   *
   * @param value spinner parameter value. Can be set as a concrete value or a variable.
   * Variable shall starts with '--', otherwise use var() function.
   * @returns formatted string.
   *
   * @example
   * const formattedValue1 = this.formatCssVar('--bs-primary');
   * const formattedValue2 = this.formatCssVar('var(--bs-border-radius)');
   * const formattedValue3 = this.formatCssVar('30px');
   */
  private formatCssVar(value: string): string {
    if (!value) return;
    const trimmed = value.trim();
    return trimmed.startsWith('--') ? `var(${trimmed})` : trimmed;
  }
}
