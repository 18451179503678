import { LocalString } from 'src/app/shared/models/enums/language.enum';

export interface MetaEntity {
  name: string;
  displayNames: LocalString[];
  primitiveProperties: MetaEntityPrimitiveProperty[];
  complexProperties: MetaEntityComplexProperty[];
  navigationProperties: MetaEntityNavigationProperty[];
  directoryProperties: MetaEntityDirectoryProperty[];

  //   logAttributes: MetaEntityLogAttribute[];
  usedInLogging: boolean;
  usedInBoards: boolean;
  hasLifecycle: boolean;

  /** Determines whether entity can be enriched by custom fields or not. */
  customizable: boolean;

  /** Defines the hierarchy key for hierarchical entities. */
  hierarchyProperty: string | null;

  lifecycleKindProperty: string | null;
  lifecycleKindType: string | null;
}

export enum MetaEntityPropertyKind {
  primitive = 'Primitive',
  navigation = 'Navigation',
  collection = 'Collection',
  complex = 'Complex',
  directory = 'Directory',
}

export enum MetaEntityPropertyType {
  string = 'String',
  decimal = 'Decimal',
  dateTime = 'DateTime',
  integer = 'Integer',
  dateOnly = 'DateOnly',
  text = 'Text',
  directory = 'Directory',
  navigation = 'Navigation',
  boolean = 'Boolean',
}

export interface MetaEntityBaseProperty {
  name: string;
  kind: MetaEntityPropertyKind;
  displayNames: LocalString[];
  viewConfiguration: ClientMetaEntityPropertyViewConfiguration;
  customFieldId: string | null;
  clrType: string;
  type: MetaEntityPropertyType;
  annotation: string | null;
  isRequired: boolean;
  isShownInReports: boolean;
  isOnlyForApi: boolean;
  metaEntityName: string;
}

export interface MetaEntityPropertyViewConfiguration {
  labels: LocalString[];
  placeholders: LocalString[];
  formColumn: FormColumn;
  orderNumber: number;
  formDefaultValue?: any;
  isShownInEntityLists: boolean;
  isShownInEntityListFilters: boolean;
  isShownInEntityForms: boolean;
}

/** Client extended MetaEntityViewConfiguration considered current language. */
export interface ClientMetaEntityPropertyViewConfiguration
  extends MetaEntityPropertyViewConfiguration {
  /** Client calculated field label. */
  label?: string;
  /** Client calculated field placeholder. */
  placeholder?: string;
}

export interface MetaEntityPrimitiveProperty extends MetaEntityBaseProperty {
  usedInHistoryLog: boolean;
}

export interface MetaEntityComplexProperty extends MetaEntityBaseProperty {
  usedInHistoryLog: boolean;
  properties: string[];
}

export interface MetaEntityNavigationProperty extends MetaEntityBaseProperty {
  usedInHistoryLog: boolean;
  keyProperty: string;
}

export interface MetaEntityDirectoryProperty extends MetaEntityBaseProperty {
  usedInHistoryLog: boolean;
  keyProperty: string;
  directoryId: string | null;
  directoryCode: string | null;
}

export type FormColumn = 'Left' | 'Right';
