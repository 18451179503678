import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import {
  META_ENTITY_TYPE,
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { IssueListComponent } from 'src/app/issues/list/issue-list.component';
import { IssueCardComponent } from 'src/app/issues/card/issue-card.component';
import { IssueFilterComponent } from 'src/app/issues/list/filter/issue-filter.component';
import { ISSUE_LIST } from 'src/app/issues/models/issue.list';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { IssueCreationComponent } from 'src/app/issues/creation/issue-creation.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { RichEditorBoxComponent } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box.component';
import { FileBoxComponent } from 'src/app/shared/components/controls/file-box/file-box.component';
import { ChangingIssueTypeModalComponent } from 'src/app/issues/card/changing-issue-type-modal/changing-issue-type-modal.component';
import { WorkLogComponent } from 'src/app/work-log/work-log.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';

@NgModule({
  declarations: [
    IssueListComponent,
    IssueCardComponent,
    IssueCreationComponent,
    IssueFilterComponent,
    ChangingIssueTypeModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CommentsModule,
    BaseSharedModule,
    EntityListComponent,
    RichEditorBoxComponent,
    FileBoxComponent,
    WorkLogComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'issues',
          url: '/issues/{view:viewName}?{navigation}&{route}',
          params: {},
          component: IssueListComponent,
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: ISSUE_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            { provide: META_ENTITY_TYPE, useValue: 'Issue' },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'issue',
              } as MassOperationParameters,
            },
          ],
        },
        {
          name: 'issue',
          params: {
            routeMode: null,
            navigation: 'my.allIssues',
            route: ROUTE_DEFAULT_PARAMS,
          },
          url: '/issues/{entityId:guid}?{navigation}&{route}',
          component: IssueCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class IssuesModule {}
