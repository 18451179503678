<div
  class="board__column"
  [class.board__column--alert]="isShowAlert"
  [tmtDragAndDrop]="cards()"
  [tmtDragAndDropOptions]="ddOptions"
  [dragDisabled]="dragDisabled"
  [dropDisabled]="dropDisabled"
>
  @if (isShowAlert) {
    <div class="alert alert-danger">
      {{ 'components.boardTrackComponent.messages.transitionAlert' | translate }}
    </div>
  }

  @for (card of cards(); track card.id) {
    <tmt-board-mini-card
      [card]="card"
      class="{{ ddOptions.draggableClass }}"
      [index]="$index"
      [attr.data-test-entity-id]="card.entity.id"
    />
  }

  <div class="end-of-track"></div>

  <wp-loading-indicator [loading]="pending()" [isSpinnerMode]="true" />
</div>
