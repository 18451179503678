import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { Dictionary } from '../shared/models/dictionary';
import buildQuery from 'odata-query';
import { HeaderParams } from 'src/app/shared/models/header-params.model';

/**
 * Service for work with API OData V4.
 * Uses library for request building https://github.com/techniq/odata-query.
 */
@Injectable({
  providedIn: 'root',
})
export class DataService {
  private requestStatusSubject = new Subject<RequestStatus>();
  public requestStatus$ = this.requestStatusSubject.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Sets request code & url for the current request.
   *
   * @param statusNumber The status number to set for the request.
   */
  public setResponseStatus(statusNumber: RequestStatus): void {
    this.requestStatusSubject.next(statusNumber);
  }

  /**
   * Returns an singleton entity.
   *
   * @param name The name of the singleton endpoint.
   * @returns An Entity instance representing the singleton.
   */
  public singleton = (name: string): Entity => {
    const url = `${this.getBaseUrl()}/${name}`;
    return new Entity(this.http, url, null);
  };

  /**
   * Gets the model (root level of the API).
   *
   * @returns {Model} A new Model instance initialized with the HTTP client and base URL.
   */
  public get model(): Model {
    return new Model(this.http, this.getBaseUrl());
  }

  /**
   * Returns a collection.
   *
   * @param collectionName The name of the collection to retrieve.
   * @returns A Collection instance representing the specified collection.
   */
  public collection = (collectionName: string): Collection =>
    new Collection(this.http, this.getBaseUrl(), collectionName);

  /**
   * Returns the base URL for OData API requests.
   *
   * @returns {string} The base URL for OData API requests, constructed by appending '/odata' to the API URL from the app configuration.
   * @private
   */
  private getBaseUrl(): string {
    return AppConfigService.config.api.url + '/odata';
  }
}

class Helper {
  /**
   * Adds URL parameters to a query string.
   *
   * @param query - The existing query string, can be empty or null.
   * @param urlParameters - A dictionary of URL parameters to add.
   * @returns The updated query string with added URL parameters.
   */
  public static addUrlParameters(
    query: string,
    urlParameters: Dictionary<string>,
  ): string {
    let url = '';

    if (urlParameters) {
      for (const key of Object.keys(urlParameters)) {
        url += key + '=' + urlParameters[key] + '&';
      }
      url = url.substring(0, url.length - 1);
    }

    if (!query) {
      query = '';
    }
    if (url) {
      if (query.length > 1 && query[0] === '?') {
        query += '&' + url;
      } else {
        query = '?' + url;
      }
    }
    return query;
  }

  /**
   * Constructs a function URL with optional parameters and query string.
   *
   * @param url - The base URL.
   * @param name - The name of the function.
   * @param oDataParams - Optional OData parameters to be included in the query string.
   * @param params - Optional dictionary of function parameters.
   * @param urlParams - Optional dictionary of URL parameters to be appended to the query string.
   * @returns The constructed function URL with all parameters and query string.
   */
  public static getFuncUrl(
    url: string,
    name: string,
    oDataParams?: object,
    params?: Dictionary<string>,
    urlParams?: Dictionary<string>,
  ): string {
    let funcUrl = url + '/' + name;

    let funcParams = '';

    if (params) {
      for (const key of Object.keys(params)) {
        funcParams += key + '=' + params[key] + ',';
      }
      funcParams = funcParams.substring(0, funcParams.length - 1);
    }
    if (funcParams) {
      funcParams = `(${funcParams})`;
    }

    funcUrl += funcParams;

    let query = '';
    if (oDataParams) {
      query = buildQuery(oDataParams);
    }
    query = this.addUrlParameters(query, urlParams);

    return funcUrl + query;
  }

  /**
   * Generates options object with headers based on provided parameters.
   *
   * @param headerParams - An object containing header parameters.
   * @param existOptions - An optional existing options object to extend.
   * @returns A Record<string, any> containing the options with headers.
   */
  public static getOptionsWithHeaders(
    headerParams: HeaderParams,
    existOptions?: Record<string, any>,
  ): Record<string, any> {
    const options = (existOptions ??= {
      headers: new HttpHeaders(),
    });
    if (headerParams?.withResponse) {
      options.headers = options.headers.set('prefer', 'return=representation');
    }
    if (headerParams?.undoRedoSessionId) {
      options.headers = options.headers.set(
        'Undo-Redo-Session-Id',
        headerParams.undoRedoSessionId,
      );
    }
    return options;
  }
}

class Model {
  constructor(
    private http: HttpClient,
    private url: string,
  ) {}

  /**
   * Creates and returns a new Function instance.
   *
   * @param name - The name of the function to be created.
   * @returns A new Function instance with the specified name.
   */
  public function(name: string) {
    return new Function(this.http, this.url, name);
  }

  /**
   * Creates and returns a new Action instance.
   *
   * @param name - The name of the action to be created.
   * @returns A new Action instance with the specified name.
   */
  public action(name: string) {
    return new Action(this.http, this.url, name);
  }
}

export class Action {
  url: string;

  constructor(
    private http: HttpClient,
    url: string,
    name: string,
  ) {
    this.url = url + '/' + name;
  }

  /**
   * Executes an HTTP POST request to the action's URL.
   *
   * @param data - The data to be sent in the request body.
   * @param oDataParams - Optional OData parameters to be appended to the URL.
   * @param headerParams - Optional parameters for the request headers.
   * @returns An Observable of type T representing the response from the server.
   */
  public execute<T>(
    data?: any,
    oDataParams?: object,
    headerParams?: HeaderParams,
  ): Observable<T> {
    let url = this.url;
    if (oDataParams) {
      url += buildQuery(oDataParams);
    }

    return this.http.post<T>(
      url,
      data,
      Helper.getOptionsWithHeaders(headerParams),
    );
  }
}

class Function {
  constructor(
    private http: HttpClient,
    private url: string,
    private name: string,
  ) {}

  /** Выполняет запрос коллекции значений. */
  /**
   * Executes a query to retrieve a collection of values.
   *
   * @param params - Optional dictionary of string parameters to be included in the request.
   * @param oDataParams - Optional OData parameters to be appended to the URL.
   * @param urlParams - Optional dictionary of string parameters to be included in the URL.
   * @returns An Observable of type T representing the response from the server.
   */
  public query<T>(
    params?: Dictionary<string>,
    oDataParams?: object,
    urlParams?: Dictionary<string>,
  ): Observable<T> {
    const url = Helper.getFuncUrl(
      this.url,
      this.name,
      oDataParams,
      params,
      urlParams,
    );
    return this.http.get<T>(url);
  }

  /**
   * Executes a request to retrieve a single value (entity).
   *
   * @param params - Optional dictionary of string parameters to be included in the request.
   * @param oDataParams - Optional OData parameters to be appended to the URL.
   * @param urlParams - Optional dictionary of string parameters to be included in the URL.
   * @returns An Observable of type T representing the response from the server.
   */
  public get<T>(
    params?: Dictionary<string>,
    oDataParams?: object,
    urlParams?: Dictionary<string>,
  ): Observable<T> {
    const url = Helper.getFuncUrl(
      this.url,
      this.name,
      oDataParams,
      params,
      urlParams,
    );
    return this.http.get<T>(url);
  }

  /**
   * Executes a request to retrieve a single value (entity).
   *
   * @param params - Optional dictionary of string parameters to be included in the request.
   * @param oDataParams - Optional OData parameters to be appended to the URL.
   * @param urlParams - Optional dictionary of string parameters to be included in the URL.
   * @param headers - Optional HttpHeaders parameter to be included in the request.
   * @returns An Observable of type HttpResponse<object> representing the response from the server.
   */
  public getWithHeaders<T>(
    params?: Dictionary<string>,
    oDataParams?: object,
    urlParams?: Dictionary<string>,
    headers?: HttpHeaders,
  ): Observable<HttpResponse<object>> {
    const url = Helper.getFuncUrl(
      this.url,
      this.name,
      oDataParams,
      params,
      urlParams,
    );
    return this.http.get(url, { headers, observe: 'response' });
  }

  /**
   * Executes a request to retrieve a value as a Blob object.
   *
   * @param params - Optional dictionary of string parameters to be included in the request.
   * @param oDataParams - Optional OData parameters to be appended to the URL.
   * @param urlParams - Optional dictionary of string parameters to be included in the URL.
   * @returns An Observable that emits the Blob response from the server.
   */
  public getBlob(
    params?: Dictionary<string>,
    oDataParams?: object,
    urlParams?: Dictionary<string>,
  ): Observable<Blob> {
    const url = Helper.getFuncUrl(
      this.url,
      this.name,
      oDataParams,
      params,
      urlParams,
    );
    return this.http.get(url, { responseType: 'blob' });
  }
}

export class Entity {
  url: string;

  constructor(
    private http: HttpClient,
    private collectionUrl: string,
    private entityId?: string,
  ) {
    // entityId === null - singleton.
    this.url = entityId ? `${this.collectionUrl}(${entityId})` : collectionUrl;
  }

  /**
   * Deletes the entity.
   *
   * @param headerParams - Optional parameters for the request headers.
   * @returns An Observable that emits the response from the server.
   */
  public delete(headerParams?: HeaderParams): Observable<any> {
    return this.http.delete(
      this.url,
      Helper.getOptionsWithHeaders(headerParams),
    );
  }

  /**
   * Patches the entity with the provided data.
   *
   * @param data - The data to patch the entity with.
   * @param headerParams - Optional parameters for the request headers.
   * @returns An Observable that emits the response from the server.
   */
  public patch(data: any, headerParams?: HeaderParams): Observable<any> {
    return this.http.patch(
      this.url,
      data,
      Helper.getOptionsWithHeaders(headerParams),
    );
  }

  /**
   * Updates the entity with the provided data.
   *
   * @param data - The data to update the entity with.
   * @param headerParams - Optional parameters for the request headers.
   * @returns An Observable that emits the response from the server.
   */
  public update(data: any, headerParams?: HeaderParams): Observable<any> {
    return this.http.put(
      this.url,
      data,
      Helper.getOptionsWithHeaders(headerParams),
    );
  }

  /**
   * Retrieves data from the server using a GET request.
   *
   * @template T - The type of the response data.
   * @param {object} [queryObject] - An optional object containing query parameters.
   * @param {Dictionary<string>} [urlParams] - An optional dictionary of URL parameters.
   * @returns {Observable<T>} An Observable that emits the response data of type T.
   */
  public get<T>(
    queryObject?: object,
    urlParams?: Dictionary<string>,
  ): Observable<T> {
    let query = '';
    if (queryObject) {
      query = buildQuery(queryObject);
    }
    query = Helper.addUrlParameters(query, urlParams);

    return this.http.get<T>(this.url + query);
  }

  /**
   * Returns a Function object for the specified entity function.
   *
   * @param name The name of the entity function.
   * @returns A new Function object representing the entity function.
   */
  public function(name: string) {
    return new Function(this.http, this.url, name);
  }

  /**
   * Returns an Action object for the specified entity action.
   *
   * @param name The name of the entity action.
   * @returns A new Action object representing the entity action.
   */
  public action(name: string) {
    return new Action(this.http, this.url, name);
  }

  /**
   * Returns a Collection object for the specified entity collection.
   *
   * @param name The name of the entity collection.
   * @returns A new Collection object representing the entity collection.
   */
  public collection(name: string) {
    return new Collection(this.http, this.url, name);
  }
}

export class Collection {
  private collectionUrl: string;

  constructor(
    private http: HttpClient,
    private rootUrl: string,
    private collectionName: string,
  ) {
    this.collectionUrl = rootUrl + '/' + collectionName;
  }

  /**
   * Returns an Entity object for the specified entity ID within the collection.
   *
   * @param entityId The unique identifier of the entity.
   * @returns A new Entity object representing the specified entity.
   */
  public entity(entityId: string): Entity {
    return new Entity(this.http, this.collectionUrl, entityId);
  }

  /**
   * Queries the collection and returns an Observable of type T.
   *
   * @template T The type of the response data.
   * @param {object} [queryObject] - Optional object to build OData query parameters.
   * @param {Dictionary<string>} [urlParams] - Optional dictionary of URL parameters to be appended to the query string.
   * @returns {Observable<T>} An Observable that emits the response data of type T.
   */
  public query<T>(
    queryObject?: object,
    urlParams?: Dictionary<string>,
  ): Observable<T> {
    let query = '';
    if (queryObject) {
      query = buildQuery(queryObject);
    }
    query = Helper.addUrlParameters(query, urlParams);
    const url = this.collectionUrl + query;
    return this.http.get<T>(url);
  }

  /**
   * Inserts a new entity into the collection.
   *
   * @param data The data to be inserted.
   * @param queryObject Optional query object to build the query string.
   * @param headerParams - Optional parameters for the request headers.
   * @returns An Observable that emits the response from the server.
   */
  public insert(
    data: any,
    queryObject?: any,
    headerParams?: HeaderParams,
  ): Observable<any> {
    let query = '';
    const options = {
      headers: new HttpHeaders(),
    };

    if (queryObject) {
      query = buildQuery(queryObject);
      options.headers = options.headers.set('prefer', 'return=representation');
    }

    return this.http.post(
      this.collectionUrl + query,
      data,
      Helper.getOptionsWithHeaders(headerParams, options),
    );
  }

  /**
   * Returns an Action instance for the specified collection action.
   *
   * @param name - The name of the action to be created.
   * @returns A new Action instance associated with the collection URL and the specified action name.
   */
  public action(name: string) {
    return new Action(this.http, this.collectionUrl, name);
  }

  /**
   * Returns a Function instance for the specified collection function.
   *
   * @param name - The name of the function to be created.
   * @returns A new Function instance associated with the collection URL and the specified function name.
   */
  public function(name: string) {
    return new Function(this.http, this.collectionUrl, name);
  }
}

interface RequestStatus {
  url: string;
  code: number;
}
