import { ElementRef, Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';

/** Отображение аватара пользователя. */
@Pipe({
  name: 'avatar',
  pure: true,
  standalone: true,
})
export class AvatarPipe implements PipeTransform {
  private defaultAvatarUrl = '/assets/images/avatar/avatar.svg';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.renderer.setStyle(
      this.el.nativeElement,
      'background-image',
      `url(${this.defaultAvatarUrl})`,
    );
    this.renderer.setStyle(this.el.nativeElement, 'background-size', '100%');

    const listener = this.renderer.listen(
      this.el.nativeElement,
      'load',
      (event: Event) => {
        if ((event.target as HTMLElement).tagName === 'IMG') {
          this.renderer.removeStyle(this.el.nativeElement, 'background-image');
          this.renderer.removeStyle(this.el.nativeElement, 'background-size');
        }

        listener();
      },
    );
  }

  public transform(id: string, size?: number, version?: string): string {
    if (!id) {
      return this.defaultAvatarUrl;
    }

    let url = `${AppConfigService.config.api.url}/avatar/${id}?ngsw-bypass`;

    if (size) {
      url += `&size=${size}`;
    }

    if (version) {
      url += `&v=${version}`;
    }

    return url;
  }
}
