import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { ProjectTariff } from 'src/app/projects/card/project-tariffs/models/project-tariff.model';
import { TimeSheetCacheService } from 'src/app/timesheets/card/core/timesheet.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtTimesheetLineTariff]',
  templateUrl: './timesheet-line-tariff.component.html',
  styleUrl: './timesheet-line-tariff.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimesheetLineTariffComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetLineTariffComponent implements OnChanges {
  @Input() projectId: string;
  public areaExpanded = false;
  public projectTariffs: Observable<ProjectTariff[]>;
  public readonly: boolean;
  public loading = false;

  private _value: ProjectTariff = null;
  public get value(): ProjectTariff {
    return this._value;
  }
  @Input()
  public set value(newValue: ProjectTariff) {
    this._value = newValue;
    this.propagateChange(this._value);
    this.cdr.markForCheck();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private timeSheetCacheService: TimeSheetCacheService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.projectId &&
      changes.projectId.currentValue !== changes.projectId.previousValue
    ) {
      this.loading = true;
      this.projectTariffs = this.timeSheetCacheService
        .getProjectTariffs(this.projectId)
        .pipe(tap(() => (this.loading = false)));
    }
  }

  public propagateChange = (_: any) => null;
  public propagateTouch = () => null;

  public writeValue(value: ProjectTariff): void {
    this.value = value;
  }

  public registerOnChange(fn: (value: ProjectTariff) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.cdr.markForCheck();
  }

  /* Opens select area. */
  public openSelect(): void {
    if (this.readonly) {
      return;
    }
    this.areaExpanded = !this.areaExpanded;
  }

  /**
   * Selects new tariff on click.
   *
   * @param projectTariff selected tariff.
   */
  public selectNewValue(projectTariff: ProjectTariff): void {
    this.value = projectTariff;
    this.closeSelect();
  }

  /* Closes select area. */
  public closeSelect(): void {
    this.areaExpanded = false;
  }
}
