import { computed, Injectable, Injector, signal } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  debounceTime,
  firstValueFrom,
  isObservable,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import _ from 'lodash';

import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Constants } from 'src/app/shared/globals/constants';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { TimesheetTemplate } from 'src/app/shared/models/entities/settings/timesheet-template.model';
import { Exception } from 'src/app/shared/models/exception';
import {
  KeysWithName,
  NamedEntity,
} from 'src/app/shared/models/entities/named-entity.model';
import { UserRole } from 'src/app/shared/models/entities/settings/user-role.model';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';

import { IssueCardService } from 'src/app/issues/card/issue-card.service';

import { WorkLogCardComponent } from './card/work-log-card.component';

@Injectable()
export class WorkLogService {
  private _timeEntries = signal<TimeAllocation[]>([]);
  public timeEntries = computed(this._timeEntries);
  private _isLoading = signal<boolean>(true);
  public isLoading = computed(this._isLoading);

  public readonly = false;
  public readonly optionalFields: KeysWithName<TimeAllocation>[] = [
    'activity',
    'role',
    'projectCostCenter',
    'projectTariff',
  ];
  public readonly dependOnUserFields: Array<keyof TimeAllocation> = [
    'projectTariff',
    'activity',
    'role',
  ];
  public readonly dependOnProjectFields: Array<keyof TimeAllocation> = [
    'projectTask',
    'projectCostCenter',
    'projectTariff',
  ];
  public readonly timeEntryCollection =
    this.dataService.collection('TimeAllocations');

  private query: Record<string, any>;
  private readonly cachedValues = new Map<string, NamedEntity[]>();

  public get timeEntryQuery(): Record<string, any> {
    const query = _.cloneDeep(this.query);
    delete query.filter;
    delete query.orderBy;
    return query;
  }

  public get issueProject(): Project {
    return this.issueCardService.projectControl.getRawValue();
  }

  public get issueProjectTask(): ProjectTask {
    return this.issueCardService.projectTaskControl.getRawValue();
  }

  constructor(
    private issueCardService: IssueCardService,
    private dataService: DataService,
    private customFieldService: CustomFieldService,
    private savingQueueService: SavingQueueService,
    private notificationService: NotificationService,
    private sortService: SortService,
    private filterService: FilterService,
    private modal: NgbModal,
    private injector: Injector,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    this.init();

    sortService.sortDirection$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.load();
    });

    filterService.values$
      .pipe(
        tap(() => this._isLoading.set(true)),
        debounceTime(Constants.textInputClientDebounce),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.load();
      });
  }

  /** Loads work-logs. */
  public async load(): Promise<void> {
    await this.savingQueueService.save();

    this.query.orderBy = [
      `date ${this.sortService.sortDirection === SortDirection.oldest ? 'asc' : 'desc'}`,
    ];
    this.query.filter = this.filterService.getODataFilter();
    this.query.filter.push({
      issueId: {
        type: 'guid',
        value: this.issueCardService.issueId,
      },
    });

    this.timeEntryCollection.query<TimeAllocation[]>(this.query).subscribe({
      next: (items) => {
        this._timeEntries.set(items);
        this._isLoading.set(false);
      },
      error: (error: Exception) => {
        this.notificationService.error(error.message);
        this._isLoading.set(false);
      },
    });
  }

  /**
   * Prepares time entry to save.
   *
   * @param formValue time entry values.
   * @returns Time allocation.
   */
  public prepareData(formValue: any): Partial<TimeAllocation> {
    const data: Partial<TimeAllocation> = {
      userId: formValue.user.id,
      date: formValue.date,
      hours: formValue.hours || 0,
      description: formValue.description,
      projectTariffId: formValue.projectTariff?.id ?? null,
      activityId: formValue.activity?.id ?? null,
      projectCostCenterId: formValue.projectCostCenter?.id ?? null,
      roleId: formValue.role?.id ?? null,
      issueId: this.issueCardService.issue.id,
      projectId: formValue.project?.id ?? null,
      projectTaskId: formValue.projectTask?.id ?? null,
    };

    this.customFieldService.assignValues(data, formValue, 'TimeAllocation');

    return data;
  }

  /**
   * Gets values by field.
   *
   * @param fieldName TimeAllocation property.
   * @param userId
   * @returns If data is cached returns plain array, otherwise `Observable`.
   */
  public getValues(
    fieldName: string,
    userId: string,
    projectId: string,
  ): NamedEntity[] | Observable<NamedEntity[]> {
    const key = `${fieldName}-${userId}-${projectId}`;

    if (this.cachedValues.has(key)) {
      return this.cachedValues.get(key);
    }

    const query = {
      select: ['id', 'name'],
      filter: {
        isActive: true,
      },
      orderBy: 'name',
    };
    let data$: Observable<NamedEntity[]>;

    switch (fieldName) {
      case 'activity':
        data$ = this.dataService
          .collection('Users')
          .entity(userId)
          .get<User>({
            select: 'restrictActivities',
            expand: {
              activities: {
                orderBy: 'activity/name',
                expand: {
                  activity: {
                    select: ['name', 'id'],
                    filter: { isActive: true },
                  },
                },
              },
            },
          })
          .pipe(
            switchMap((values) =>
              values.activities.length
                ? of(values.activities.map((el) => el.activity))
                : this.dataService
                    .collection('Activities')
                    .query<NamedEntity[]>(query),
            ),
          );
        break;
      case 'role':
        delete query.orderBy;
        query.select = ['id'];
        _.set(query, 'filter', {
          userId: { type: 'guid', value: userId },
        });
        _.set(query, 'expand', {
          role: {
            select: ['id', 'name'],
          },
        });

        data$ = this.dataService
          .collection('UserRoles')
          .query<UserRole[]>(query)
          .pipe(map((items) => items.map((userRole) => userRole.role)));
        break;
      case 'projectCostCenter':
        data$ = !projectId
          ? of([])
          : this.dataService
              .collection('Projects')
              .entity(projectId)
              .function('GetUserCostCenters')
              .get({ userId });
        break;
      case 'projectTariff':
        data$ = !projectId
          ? of([])
          : this.dataService
              .collection('Projects')
              .entity(projectId)
              .function('GetUserTariffs')
              .get({ userId });
        break;
    }

    return data$.pipe(
      tap((values) => this.cachedValues.set(key, values)),
      catchError((error) => {
        this.notificationService.error(error.message);
        return of([]);
      }),
    );
  }

  /**
   * Enriches form with default values if it's possible.
   *
   * @param userId
   * @param formGroup TimeAllocation formGroup.
   *
   */
  public async setDefaultValues(
    userId: string,
    projectId: string,
    formGroup: UntypedFormGroup,
    availableFields: KeysWithName<TimeAllocation>[],
  ): Promise<void> {
    for (const key of availableFields) {
      let values = this.getValues(key, userId, projectId);

      if (isObservable(values)) {
        values = await firstValueFrom(values);
      }

      if (values.length === 1) {
        formGroup.get(key).setValue(values[0], { emitEvent: false });
      }

      if (key === 'projectTariff') {
        const primaryValue = (
          values as Array<{ isPrimary: boolean } & NamedEntity>
        ).find((tariff) => tariff.isPrimary);

        if (primaryValue) {
          formGroup.get(key).setValue(primaryValue, { emitEvent: false });
        }
      }
    }
  }

  /**
   * Gets available fields to show by template settings for time entry.
   *
   * @param template timesheet template.
   * @returns Available fields.
   */
  public getAvailableFields(
    template: TimesheetTemplate,
  ): [KeysWithName<TimeAllocation>[], MetaEntityBaseProperty[]] {
    const availableFields = this.optionalFields.slice(0);

    if (!template.showActivity) {
      availableFields.splice(
        availableFields.findIndex((key) => key === 'activity'),
        1,
      );
    }

    if (!template.showProjectCostCenter) {
      availableFields.splice(
        availableFields.findIndex((key) => key === 'projectCostCenter'),
        1,
      );
    }

    if (!template.showRole) {
      availableFields.splice(
        availableFields.findIndex((key) => key === 'role'),
        1,
      );
    }

    if (!template.showTariff) {
      availableFields.splice(
        availableFields.findIndex((key) => key === 'projectTariff'),
        1,
      );
    }

    const availableCustomFields: MetaEntityBaseProperty[] = [];
    const customFields = this.customFieldService.getList('TimeAllocation');

    template.customFields.forEach((templateField) => {
      const field = customFields.find(
        (field) =>
          field.customFieldId === templateField.customFieldId &&
          field.viewConfiguration.isShownInEntityForms,
      );

      if (field) {
        availableCustomFields.push(field);
      }
    });

    return [availableFields, availableCustomFields];
  }

  /**
   *  Determines whether time entry is readonly or not.
   *
   * @param timeEntry Time entry.
   * @returns true|false.
   */
  public isTimeEntryReadonly(
    timeEntry: TimeAllocation | Partial<TimeAllocation>,
  ): boolean {
    return !!timeEntry.timeSheet?.state?.isEntityProtected;
  }

  /** Opens a modal to create a time entry. */
  public createTimeEntry(): void {
    const modalRef = this.modal.open(WorkLogCardComponent, {
      injector: this.injector,
    });

    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /**
   *  Opens a modal to view a time entry.
   *
   * @param timeEntry
   * @returns updated entry.
   */
  public openTimeEntry(timeEntry: Partial<TimeAllocation>): void {
    const modalRef = this.modal.open(WorkLogCardComponent, {
      injector: this.injector,
    });
    const component = modalRef.componentInstance as WorkLogCardComponent;
    component.timeEntry = timeEntry;
    component.readonly = this.isTimeEntryReadonly(timeEntry);

    modalRef.result.finally(() => null);
  }

  /**
   *  Opens a modal to edit a time entry.
   *
   * @param timeEntry
   * @returns updated entry.
   */
  public async editTimeEntry(
    timeEntry: Partial<TimeAllocation>,
  ): Promise<TimeAllocation | null> {
    const modalRef = this.modal.open(WorkLogCardComponent, {
      injector: this.injector,
    });
    (modalRef.componentInstance as WorkLogCardComponent).timeEntry = timeEntry;

    try {
      const result: TimeAllocation = await modalRef.result;
      const updatedEntry = await firstValueFrom(
        this.timeEntryCollection
          .entity(result.id)
          .get<TimeAllocation>(this.timeEntryQuery),
      );

      this._timeEntries.update((values) => {
        const item = values.find((item) => item.id === timeEntry.id);

        if (item) {
          Object.assign(item, updatedEntry);
        }

        return values;
      });

      return updatedEntry;
    } catch {
      return null;
    }
  }

  /**
   * Deletes time entry.
   *
   * @param timeEntry
   */
  public deleteTimeEntry(timeEntry: Partial<TimeAllocation>): void {
    this.messageService
      .confirm(
        this.translateService.instant('shared2.messages.deleteConfirmation'),
      )
      .then(() => {
        this.timeEntryCollection
          .entity(timeEntry.id)
          .delete()
          .subscribe({
            next: () => {
              this._timeEntries.update((values) =>
                values.filter((v) => v.id !== timeEntry.id),
              );
              this.notificationService.successLocal(
                'shared2.messages.deleteCompleted',
              );
            },
            error: (error: Exception) => {
              this.notificationService.error(error.message);
            },
          });
      })
      .catch(() => null);
  }

  private init(): void {
    this.readonly = !this.issueCardService.issue.editAllowed;
    this.query = {
      select: ['id', 'date', 'hours', 'description', 'rowVersion'],
      expand: {
        user: {
          select: ['id', 'name'],
        },
        timeSheet: {
          select: ['id'],
          expand: {
            state: {
              select: ['id', 'name', 'code', 'style', 'isEntityProtected'],
            },
          },
        },
        template: {
          select: [
            'id',
            'showClient',
            'showActivity',
            'showProjectCostCenter',
            'showRole',
            'showTariff',
          ],
          expand: {
            customFields: {
              select: ['*'],
            },
          },
        },
        activity: {
          select: ['id', 'name'],
        },
        role: {
          select: ['id', 'name'],
        },
        project: {
          select: ['id', 'name'],
          expand: {
            organization: {
              select: ['id', 'name'],
            },
          },
        },
        projectTask: {
          select: ['id', 'name'],
        },
        projectTariff: {
          select: ['id', 'name'],
        },
        projectCostCenter: {
          select: ['id', 'name'],
        },
      },
      filter: {},
      orderBy: ['date', 'hours'],
    };

    this.customFieldService.enrichQuery(this.query, 'TimeAllocation');

    this.load();
  }
}
