import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { Dictionary } from '../models/dictionary';
import { ProjectTask } from '../models/entities/projects/project-task.model';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';

@Injectable({
  providedIn: 'root',
})
export class ProjectTasksService {
  /** Максимальный загружаемый уровень. */
  private maxLevel = 1;

  constructor(private data: DataService) {}

  private observables: Dictionary<Observable<ProjectTask[]>> = {};

  /**
   * Возвращает набор задач для проекта (кэширует).
   *
   * @param projectId ID проекта.
   * @param projectVersion Версия проекта.
   * @param maxLevel Максимальный загружаемый уровень.
   * */
  public getProjectTasks(
    projectId: string,
    projectVersion: ProjectVersion = null,
    maxLevel: number = null,
  ): Observable<ProjectTask[]> {
    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      if (this.observables[projectId]) {
        return this.observables[projectId];
      }
    } else {
      if (this.observables[projectVersion.id]) {
        return this.observables[projectVersion.id];
      }
    }

    maxLevel = maxLevel ?? this.maxLevel;

    const query: any = {
      select: [
        'id',
        'name',
        'structNumber',
        'number',
        'leadTaskId',
        'indent',
        'startDate',
        'endDate',
      ],
      filter: [
        {
          indent: { le: maxLevel },
        },
      ],
    };
    ProjectVersionUtil.addProjectEntityIdFilter(
      query,
      projectVersion,
      projectId,
    );

    const observable = this.data
      .collection('ProjectTasks')
      .query<any>(query)
      .pipe(
        shareReplay(),
        map((it) => cloneDeep(it)),
      );

    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      return (this.observables[projectId] = observable);
    } else {
      return (this.observables[projectVersion.id] = observable);
    }
  }
  /**
   * Сбрасывает кэш задач для версии проекта.
   *
   * @param projectId ID проекта.
   * @param projectVersion Версия проекта.
   * */
  public resetProjectTasks(
    projectId: string,
    projectVersion: ProjectVersion = null,
  ) {
    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      delete this.observables[projectId];
    } else {
      delete this.observables[projectVersion.id];
    }
  }

  public getTaskFullName(task: ProjectTask): string {
    return task
      ? task.structNumber
        ? `${task.structNumber} ${task.name}`
        : task.name
      : '';
  }
}
