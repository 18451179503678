import {
  DestroyRef,
  Directive,
  ElementRef,
  Renderer2,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';

/** Abstract class for table right side components. Templates of the descendants must have structure supports wpFreezeTable directive. */
@Directive({
  host: { name: 'right' },
})
export class AbstractRightSide {
  protected destroyRef = inject(DestroyRef);
  constructor(
    protected container: ElementRef,
    protected gridService: GridService,
    protected freezeTableService: FreezeTableService,
    protected renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    this.initSubscribes();
  }

  /** Inits component subscribes. */
  protected initSubscribes(): void {
    this.freezeTableService.leftTableWidth$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((width) => {
        this.renderer.setStyle(
          this.container.nativeElement,
          'margin-left',
          width + 'px',
        );
      });
  }
}
