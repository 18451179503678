<div class="loading-container">
  @if (isOverlay) {
    @if (loading) {
      <div class="loading--overlay-background" [ngStyle]="spinnerStyles"></div>
      <div class="loading-spinner loading-spinner--overlay" [ngStyle]="spinnerStyles"></div>
    }
    <ng-content />
  } @else {
    @if (loading) {
      @if (isSpinnerMode) {
        <div class="loading-spinner" [ngStyle]="spinnerStyles"></div>
      } @else {
        @defer (when delayTimer$ | async) {
          <div class="progress" [ngStyle]="style">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        }
      }
    }
  }
</div>
