import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { ProjectCostCenter } from 'src/app/projects/card/project-cost-centers/models/project-cost-center.model';
import { TimeSheetCacheService } from 'src/app/timesheets/card/core/timesheet.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtTimesheetCostCenter]',
  templateUrl: './timesheet-cost-center.component.html',
  styleUrl: './timesheet-cost-center.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimesheetCostCenterComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetCostCenterComponent implements ControlValueAccessor {
  public areaExpanded = false;
  public projectCostCenters: Observable<ProjectCostCenter[]>;
  public readonly: boolean;
  public loading = false;

  private _projectId = '';

  /* Get private projectId. */
  public get projectId(): string {
    return this._projectId;
  }

  /**
   * Set new projectId and gets project cost centers.
   *
   * @param newProjectId id of new project.
   */
  @Input()
  public set projectId(newProjectId: string) {
    if (newProjectId && newProjectId !== this._projectId) {
      this._projectId = newProjectId;
      this.loading = true;
      this.projectCostCenters = this.timeSheetCacheService
        .getProjectCostCenters(this.projectId)
        .pipe(tap(() => (this.loading = false)));
      return;
    }

    this._projectId = newProjectId;
  }

  private _value: ProjectCostCenter = null;

  /* Get private value. */
  public get value(): ProjectCostCenter {
    return this._value;
  }

  /* Set new value and marks for changes. */
  @Input()
  public set value(newValue: ProjectCostCenter) {
    this._value = newValue;
    this.propagateChange(this._value);
    this.cdr.markForCheck();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private timeSheetCacheService: TimeSheetCacheService,
  ) {}

  public propagateChange = (_: any) => null;
  public propagateTouch = () => null;

  public writeValue(value: ProjectCostCenter): void {
    this.value = value;
  }

  public registerOnChange(fn: (value: ProjectCostCenter) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  /* Open select area. */
  public openSelect(): void {
    if (this.readonly) {
      return;
    }
    this.areaExpanded = !this.areaExpanded;
  }

  /**
   * Select new cost center on click.
   *
   * @param projectCostCenter selected cost center.
   */
  public selectNewValue(projectCostCenter: ProjectCostCenter): void {
    this.value = projectCostCenter;
    this.closeSelect();
  }

  /* Close select area. */
  public closeSelect(): void {
    this.areaExpanded = false;
  }
}
