<div class="feed-item">
  <div class="feed-item__content">
    <div class="feed-item__header">
      <div class="feed-item__badge feed-item__badge--contrast badge badge-trim text-dark">
        {{ timeEntry().hours || 0 | wpWork }}
      </div>

      @if (timeEntry().timeSheet) {
        <div
          class="feed-item__badge badge badge-trim {{ 'bg-' + timeEntry().timeSheet.state.style }}"
        >
          {{ timeEntry().timeSheet.state.name }}
        </div>
      }

      <div class="feed-item__user" id="{{ 'user-' + timeEntry().user.id }}">
        <img
          [src]="timeEntry().user.id | avatar: 60"
          alt="user avatar"
          class="feed-item__user-avatar"
        />

        <div class="feed-item__user-name trim" ngbTooltip="{{ timeEntry().user.name }}">
          <button
            class="btn btn-link btn-link-content"
            type="button"
            (click)="openUserInfo(timeEntry().user.id)"
          >
            {{ timeEntry().user.name }}
          </button>
        </div>
      </div>

      <div class="feed-item__dates">
        <div>
          <i class="bi bi-calendar-check"></i>
          {{ timeEntry().date | date: 'shortDate' }}
        </div>
      </div>

      <div class="feed-item__actions">
        @if (!readonly()) {
          <button
            class="btn btn-icon"
            ngbTooltip="{{ 'shared2.actions.edit' | translate }}"
            (click)="editTimeEntry()"
          >
            <i class="bi bi-pencil-fill"></i>
          </button>
          @if (!workLogService.readonly) {
            <button
              class="btn btn-icon"
              ngbTooltip="{{ 'shared2.actions.delete' | translate }}"
              (click)="workLogService.deleteTimeEntry(timeEntry())"
            >
              <i class="bi bi-trash"></i>
            </button>
          }
        } @else {
          <button
            class="btn btn-icon"
            ngbTooltip="{{ 'shared2.actions.open' | translate }}"
            (click)="openTimeEntry()"
          >
            <i class="bi bi-pencil-fill"></i>
          </button>
        }
      </div>
    </div>

    @if (
      (timeEntry().project?.organization && timeEntry().template.showClient) ||
      timeEntry().project ||
      timeEntry().projectTask
    ) {
      <div class="feed-item__details">
        @if (timeEntry().project?.organization && timeEntry().template.showClient) {
          <div
            class="feed-item__details-item trim"
            ngbTooltip="{{ timeEntry().project.organization.name }}"
            container="body"
          >
            <a
              [uiSref]="'client'"
              [uiParams]="{
                entityId: timeEntry().project.organization.id,
                navigation: navigationService.selectedNavigationItem?.name,
                routeMode: 'continue'
              }"
            >
              {{ timeEntry().project.organization.name }}
            </a>
          </div>
        }

        @if (timeEntry().project) {
          <div
            class="feed-item__details-item trim"
            ngbTooltip="{{ timeEntry().project.name }}"
            container="body"
          >
            <a
              [uiSref]="'project'"
              [uiParams]="{
                entityId: timeEntry().project.id,
                navigation: navigationService.selectedNavigationItem?.name,
                routeMode: 'continue'
              }"
            >
              {{ timeEntry().project.name }}
            </a>
          </div>
        }

        @if (timeEntry().projectTask) {
          <div
            class="feed-item__details-item trim"
            ngbTooltip="{{ timeEntry().projectTask.name }}"
            container="body"
          >
            <a
              [uiSref]="'project.tasks'"
              [uiParams]="{
                entityId: timeEntry().project.id,
                navigation: navigationService.selectedNavigationItem?.name,
                routeMode: 'continue'
              }"
            >
              {{ timeEntry().projectTask.name }}
            </a>
          </div>
        }
      </div>
    }

    @if (timeEntry().description) {
      <div class="feed-item__description">
        {{ timeEntry().description }}
      </div>
    }

    <div class="feed-item__fields">
      @for (key of availableFields; track key) {
        <div class="feed-item__field">
          <small class="text-secondary">
            {{ 'shared2.props.' + key | translate }}
          </small>
          @if (timeEntry()[key]) {
            <p class="trim" [title]="timeEntry()[key]?.name">
              {{ timeEntry()[key]?.name }}
            </p>
          } @else {
            <p class="text-secondary trim">{{ 'shared.valueNotSelected' | translate }}</p>
          }
        </div>
      }

      <wp-custom-fields
        class="feed-item__custom-fields"
        [formGroup]="customFieldsForm"
        [fields]="availableCustomFields"
        [isVisibleHr]="false"
      />
    </div>
  </div>
</div>
