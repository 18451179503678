import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { PercentPipe } from '@angular/common';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';

@Injectable()
export class ProjectTeamService {
  public allTeamName: string;
  public assignmentTitleWithoutUnits: boolean;

  constructor(
    private data: DataService,
    translate: TranslateService,
    @Inject('entityId') private projectId: string,
    private percentPipe: PercentPipe,
  ) {
    this.allTeamName = translate.instant('projects.allTeamRole');
  }

  private observable: Observable<any[]>;
  private observableVersion: Observable<any[]>;

  /**
   * Возвращает набор членов команды проекта (кэширует).
   *
   * @param projectVersion Версия проекта.
   * */
  public getTeamMembers(
    projectVersion: ProjectVersion = null,
  ): Observable<ProjectTeamMember[]> {
    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      if (this.observable) {
        return this.observable;
      }
    } else {
      if (this.observableVersion) {
        return this.observableVersion;
      }
    }

    const query: any = {
      expand: {
        resource: {
          select: ['id', 'name', 'resourceType'],
        },
      },
      select: ['id', 'isActive', 'description'],
    };
    ProjectVersionUtil.addProjectEntityIdFilter(
      query,
      projectVersion,
      this.projectId,
    );

    const observable = this.data
      .collection('ProjectTeamMembers')
      .query<any>(query)
      .pipe(
        map((data: any[]) => {
          data.forEach((member) => {
            member.name = member.resource?.name ?? member.description;
          });

          return data;
        }),
        shareReplay(),
        map((it) => cloneDeep(it)),
      );

    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      return (this.observable = observable);
    } else {
      return (this.observableVersion = observable);
    }
  }

  /**
   * Сбрасывает кэш членов команды для проекта.
   *
   * @param projectVersion Версия проекта.
   * */
  public reset(projectVersion: ProjectVersion = null) {
    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      this.observable = null;
    } else {
      this.observableVersion = null;
    }
  }

  /**
   * Gets assignments title.
   *
   * @param assignments project task assignments.
   * @param splitCount count of assignments for view in the string.
   * @returns string with names of team members.
   * Names will be without units if `this.assignmentTitleWithoutUnits = true`
   */
  public getAssignmentsString(
    assignments: any[],
    splitCount: number | null = null,
  ): string {
    const names = [];
    if (assignments) {
      assignments
        .sort(naturalSort('projectTeamMember.name'))
        .forEach((assignment, index) => {
          if (splitCount && splitCount > 0 && index > splitCount - 1) {
            return;
          }
          if (assignment.isAllTeamRole) {
            names.unshift(this.allTeamName);
          } else if (assignment.isUnassigned) {
            return;
          } else {
            let correctedName = assignment.projectTeamMember?.name ?? '';
            // Adds line through symbol for separate name in the line
            if (!assignment.projectTeamMember?.isActive) {
              correctedName = StringHelper.getCrossedOutString(correctedName);
            }

            const units =
              assignment.projectTeamMember?.resource?.resourceType !==
                ResourceType.department &&
              typeof assignment.units === 'number' &&
              assignment.units !== 1 &&
              !this.assignmentTitleWithoutUnits
                ? ` [${this.percentPipe
                    .transform(assignment.units)
                    .replace(/\s/g, '')}]`
                : '';

            const nameAndUnits = correctedName + units;
            names.push(nameAndUnits);
          }
        });
    }

    if (splitCount && assignments.length > splitCount) {
      names.push(' ...');
    }
    return names.join('; ');
  }
}
