@switch (column.name) {
  @case ('general') {
    <div class="fw-semibold small trim">
      <span>{{ 'components.resourceTotalCellComponent.props.hours' | translate }}</span>
    </div>
    @if ((projectCardService.project$ | async).financeViewAllowed) {
      <div class="fw-semibold small trim">
        <span>{{ 'components.resourceTotalCellComponent.props.laborCost' | translate }}</span>
      </div>
    }
  }
  @case ('totalHours') {
    <div
      class="total-cell text-truncate cost"
      title="{{ projectResourceService.totalHours | plannerFormat }}"
    >
      {{ projectResourceService.totalHours | plannerFormat }}
    </div>

    @if ((projectCardService.project$ | async).financeViewAllowed) {
      <div
        class="total-cell text-truncate cost"
        title="{{ projectResourceService.totalCost | zeroToNull | number: '1.0-0' }}"
      >
        {{ projectResourceService.totalCost | zeroToNull | number: '1.0-0' }}
      </div>
    }
  }
}
