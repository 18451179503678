<div class="main-wrapper">
  <div class="notifications-header">
    <label class="text-uppercase text-gray">{{ 'shared.notifications.header' | translate }}</label>

    <button
      class="btn btn-link"
      (click)="openUserSettings()"
      title="{{ 'shared.notifications.openSettings' | translate }}"
    >
      <i class="bi bi-gear"></i>
    </button>

    <a
      *ngIf="service.hasUnreadNotifications$ | async"
      class="btn btn-link text-decoration-none text-gray small p-0"
      title="{{ 'shared.notifications.markAllAsRead' | translate }}"
      (click)="service.markAllAsRead()"
    >
      <span>
        {{ 'shared.notifications.markAllAsRead' | translate }}
      </span>
    </a>
  </div>
  <div
    #notificationsWrapper
    id="notifications-wrapper"
    [hidden]="(service.notifications$ | async).length === 0"
  >
    <a
      class="user-notification text-reset text-decoration-none"
      [ngClass]="{ 'fw-semibold': !notification.read }"
      *ngFor="let notification of service.notifications$ | async; trackBy: trackById"
      [uiSref]="
        service.stateBuilder.getStateForWorkflowEntity(
          notification.target.taskId,
          notification.target.entityType,
          notification.target.entityId
        ).state
      "
      [uiParams]="
        service.stateBuilder.getStateForWorkflowEntity(
          notification.target.taskId,
          notification.target.entityType,
          notification.target.entityId
        ).params
      "
      (click)="handleClick(notification.id)"
    >
      <div class="text-gray small">
        {{ notification.created | date: 'short' : this.appService.session.timeZoneOffset }}
      </div>
      <div>
        {{ notification.subject }}
      </div>
      <div class="small">
        {{ notification.message }}
      </div>
    </a>
  </div>
  <div
    id="no-notifications-wrapper"
    class="text-body-secondary text-center text-uppercase"
    *ngIf="(service.notifications$ | async).length === 0"
  >
    {{ 'shared.notifications.hasNoUnreadNotifications' | translate }}
  </div>
</div>
