<div class="menu-wrap">
  @for (item of menuItems; track item.name) {
    @if ((item.subActions && item.subActions.length) || item.subActionsLazyResolver) {
      <wp-loading-indicator
        [loading]="isPending[$index]"
        [isSpinnerMode]="true"
        [spinnerParams]="spinnerParams"
        [isOverlay]="true"
      >
        <button
          type="button"
          class="dropdown-item menu-item d-flex trim"
          title="{{ getLabelString(item.label) | translate }}"
          [disabled]="!canExecute(item)"
          (click)="toggleSubMenu($event, $index, item, collapse)"
          data-test="subMenu"
        >
          @if (item.iconClass) {
            <span [class]="'icon ' + item.iconClass"></span>
          }
          <span class="trim">{{ getLabelString(item.label) | translate }}</span>
          <i
            class="bi ms-auto"
            [ngClass]="isCollapsed[$index] ? 'bi-chevron-down' : 'bi-chevron-up'"
          ></i>
        </button>
      </wp-loading-indicator>

      <div
        class="sub-menu"
        #collapse="ngbCollapse"
        [ngbCollapse]="isCollapsed[$index]"
        (ngbCollapseChange)="onSubActionsCollapseChange($event, $index)"
      >
        @for (subAction of item.subActions; track subAction.name) {
          <ng-container
            [ngTemplateOutlet]="menuItem"
            [ngTemplateOutletContext]="{ item: subAction }"
          />
        }
      </div>
    } @else if (!item.hasOwnProperty('subActions')) {
      <ng-container [ngTemplateOutlet]="menuItem" [ngTemplateOutletContext]="{ item }" />
    }
  }
</div>

<ng-template #menuItem let-item="item">
  <button
    class="dropdown-item menu-item trim"
    (click)="item.handlerFn(context)"
    [disabled]="!canExecute(item)"
    title="{{ getLabelString(item.label) | translate }}"
    [attr.data-test]="item.name"
  >
    @if (item.iconClass) {
      <span [class]="'icon ' + item.iconClass"></span>
    }
    <span class="trim">{{ getLabelString(item.label) | translate }}</span>
  </button>
</ng-template>
