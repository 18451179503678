<wp-loading-indicator [loading]="service.isLoading()" />
<wp-data-not-saved-indicator
  [hidden]="!service.isNotSavedIndicator()"
  [form]="service.contactForm"
/>

<form
  class="form-container"
  [hidden]="service.isLoading()"
  [formGroup]="service.contactForm"
  novalidate
  autocomplete="off"
>
  <div ngbAccordion class="accordion--custom">
    <div ngbAccordionItem [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.groups.mainProperties' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid">
            <div class="form-group" tmtIndicator>
              <label>{{ 'shared2.props.firstName' | translate }}</label>
              <wp-text-box
                formControlName="firstName"
                placeholder="{{ 'shared2.props.firstName' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'shared2.props.organization' | translate }}</label>
              <wp-select-box
                formControlName="organization"
                collection="Organizations"
                placeholder="{{ 'shared2.props.organization' | translate }}"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.email' | translate }}</label>
              <wp-text-box
                formControlName="email"
                placeholder="{{ 'shared2.props.email' | translate }}"
                typeName="email"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.lastName' | translate }}</label>
              <wp-text-box
                formControlName="lastName"
                placeholder="{{ 'shared2.props.lastName' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.role' | translate }}</label>
              <wp-select-box
                formControlName="roleId"
                [isIdMode]="true"
                [directoryId]="systemDirectory.contactRole"
                placeholder="{{ 'shared2.props.role' | translate }}"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.phone' | translate }}</label>
              <wp-text-box
                formControlName="phone"
                placeholder="{{ 'shared2.props.phone' | translate }}"
                typeName="phone"
                [propagationMode]="propagationMode.onExitFromEditing"
                #phone
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.patronymic' | translate }}</label>
              <wp-text-box
                formControlName="patronymic"
                placeholder="{{ 'shared2.props.patronymic' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.position' | translate }}</label>
              <wp-text-box
                formControlName="position"
                placeholder="{{ 'shared2.props.position' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.mobilePhone' | translate }}</label>
              <wp-text-box
                formControlName="mobilePhone"
                placeholder="{{ 'shared2.props.mobilePhone' | translate }}"
                typeName="phone"
                [propagationMode]="propagationMode.onExitFromEditing"
                #mobilePhone
              />
            </div>

            <div class="form-group full-width">
              <label>{{ 'shared2.props.description' | translate }}</label>
              <wp-multiline-text-box
                formControlName="description"
                placeholder="{{ 'shared2.props.description' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <wp-custom-fields
              [formGroup]="service.contactForm"
              [isVisibleHr]="false"
              [entityType]="'Contact'"
              class="full-width"
            />

            <div class="form-check mt-2">
              <input
                formControlName="isActive"
                type="checkbox"
                class="form-check-input"
                id="isActive"
              />
              <label class="form-check-label" for="isActive">
                {{ 'shared2.props.isActive.value' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
