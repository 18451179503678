<wp-loading-indicator [state]="state"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>
<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form">
  <div class="container-fluid">
    <div class="row" formGroupName="passwordSetting">
      <div class="col-6">
        <div class="form-group" tmtIndicator>
          <label>{{
            'settings.settings.securitySettings.passwordSetting.passwordUpdatePeriodDays.label'
              | translate
          }}</label>
          <wp-number-box
            style="width: 100px"
            formControlName="passwordUpdatePeriodDays"
            type="integer"
            [align]="'left'"
            [min]="formValidatorValues.passwordSetting.passwordUpdatePeriodDays.min"
            [max]="formValidatorValues.passwordSetting.passwordUpdatePeriodDays.max"
            placeholder="{{
              'settings.settings.securitySettings.passwordSetting.passwordUpdatePeriodDays.placeholder'
                | translate
            }}"
          ></wp-number-box>
        </div>

        <label class="group mt-3">
          {{
            'settings.settings.securitySettings.passwordSetting.complexity.groupHeader' | translate
          }}
        </label>
        <div class="form-group" tmtIndicator>
          <label>{{
            'settings.settings.securitySettings.passwordSetting.complexity.properties.minPasswordLength.label'
              | translate
          }}</label>
          <wp-number-box
            style="width: 100px"
            formControlName="minPasswordLength"
            type="integer"
            [align]="'left'"
            [min]="formValidatorValues.passwordSetting.minPasswordLength.min"
            [max]="formValidatorValues.passwordSetting.minPasswordLength.max"
            placeholder="{{
              'settings.settings.securitySettings.passwordSetting.complexity.properties.minPasswordLength.placeholder'
                | translate
            }}"
          ></wp-number-box>
        </div>

        <div class="form-check">
          <input
            formControlName="requireDigits"
            type="checkbox"
            class="form-check-input"
            id="requireDigits"
          />
          <label class="form-check-label" for="requireDigits">
            {{
              'settings.settings.securitySettings.passwordSetting.complexity.properties.requireDigits.label'
                | translate
            }}
          </label>
        </div>

        <div class="form-check">
          <input
            formControlName="requireSpecSymbols"
            type="checkbox"
            class="form-check-input"
            id="requireSpecSymbols"
          />
          <label class="form-check-label" for="requireSpecSymbols">
            {{
              'settings.settings.securitySettings.passwordSetting.complexity.properties.requireSpecSymbols.label'
                | translate
            }}
          </label>
        </div>

        <div class="form-check">
          <input
            formControlName="requireCapitalLetters"
            type="checkbox"
            class="form-check-input"
            id="requireCapitalLetters"
          />
          <label class="form-check-label" for="requireCapitalLetters">
            {{
              'settings.settings.securitySettings.passwordSetting.complexity.properties.requireCapitalLetters.label'
                | translate
            }}
          </label>
        </div>
      </div>
      <div class="col-6">
        <label class="group">
          {{ 'settings.settings.securitySettings.passwordSetting.lockout.groupHeader' | translate }}
        </label>
        <div class="form-group" tmtIndicator>
          <label>
            {{
              'settings.settings.securitySettings.passwordSetting.lockout.properties.maxInvalidLoginAttempts.label'
                | translate
            }}
          </label>
          <wp-number-box
            formControlName="maxInvalidLoginAttempts"
            style="width: 100px"
            type="integer"
            [align]="'left'"
            [min]="formValidatorValues.passwordSetting.maxInvalidLoginAttempts.min"
            [max]="formValidatorValues.passwordSetting.maxInvalidLoginAttempts.max"
            placeholder="{{
              'settings.settings.securitySettings.passwordSetting.lockout.properties.maxInvalidLoginAttempts.placeholder'
                | translate
            }}"
          ></wp-number-box>
        </div>
        <div class="form-group" tmtIndicator>
          <label>
            {{
              'settings.settings.securitySettings.passwordSetting.lockout.properties.lockoutMinutes.label'
                | translate
            }}
          </label>
          <wp-number-box
            style="width: 100px"
            formControlName="lockoutMinutes"
            type="integer"
            [align]="'left'"
            [min]="formValidatorValues.passwordSetting.lockoutMinutes.min"
            [max]="formValidatorValues.passwordSetting.lockoutMinutes.max"
            placeholder="{{
              'settings.settings.securitySettings.passwordSetting.lockout.properties.lockoutMinutes.placeholder'
                | translate
            }}"
          ></wp-number-box>
        </div>

        <label class="group mt-3">
          {{ 'settings.settings.securitySettings.mfa' | translate }}
        </label>
        <div class="form-check">
          <input formControlName="useOtp" type="checkbox" class="form-check-input" id="useOtp" />
          <label class="form-check-label" for="useOtp">
            {{ 'settings.settings.securitySettings.useOtp' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="actions">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
  <hr />
</form>
<wp-settings-auth-providers></wp-settings-auth-providers>
