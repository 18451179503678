import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';

import { GridModule } from 'src/app/shared-features/grid/grid.module';

import { SharedModule } from 'src/app/shared/shared.module';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { NavigationItemsComponent } from 'src/app/shared/components/navigations/navigation-items.component';
import { FilterConditionsBuilderComponent } from 'src/app/shared/components/filter-conditions-builder/filter-conditions-builder.component';

import { BOARDS_LIST } from 'src/app/settings-app/boards/model/boards.list';
import { PerformersModule } from 'src/app/settings-app/shared/performers/performers.module';
import { BoardCardComponent } from 'src/app/settings-app/boards/card/board-card.component';
import { BoardCreationComponent } from 'src/app/settings-app/boards/creation/board-creation.component';
import { BoardsListComponent } from 'src/app/settings-app/boards/list/boards-list.component';
import { BoardCardMainComponent } from 'src/app/settings-app/boards/card/board-card-main/board-card-main.component';
import { BoardCardFilterComponent } from 'src/app/settings-app/boards/card/board-card-filter/board-card-filter.component';

@NgModule({
  declarations: [
    BoardsListComponent,
    BoardCreationComponent,
    BoardCardComponent,
    BoardCardMainComponent,
    BoardCardFilterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PerformersModule,
    GridModule,
    EntityListComponent,
    FilterConditionsBuilderComponent,
    NavigationItemsComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.boards',
          url: '/boards',
          component: BoardsListComponent,
          params: {
            navigation: 'settings.boards',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: BOARDS_LIST },
          ],
        },
        {
          name: 'settings.board',
          params: {
            navigation: 'settings.boards',
          },
          redirectTo: 'settings.board.main',
          url: '/boards/{entityId:guid}',
          component: BoardCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
        {
          name: 'settings.board.main',
          url: '/main',
          component: BoardCardMainComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.board.filter',
          url: '/filter',
          component: BoardCardFilterComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class BoardsModule {}
