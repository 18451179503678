@if (isShown) {
  <div
    id="wp-time-entry-info"
    name="area"
    class="popover-box"
    [class.issue-info]="issueMode"
    [ngStyle]="containerStyles"
    (clickOutside)="service.close()"
    [delayClickOutsideInit]="true"
    exclude="[wpTimesheetCell]"
  >
    @if (!issueMode) {
      <ng-container [formGroup]="form">
        <div style="margin-bottom: 7px; position: relative">
          <textarea
            autosize
            [minRows]="4"
            [maxRows]="10"
            rows="4"
            placeholder="{{ 'timesheets.card.entryInfo.comment' | translate }}"
            class="form-control"
            formControlName="description"
          ></textarea>
        </div>

        <!-- TODO: refactor this! Make array for buttons. -->
        <div
          *ngIf="!readonly"
          class="btn-group btn-group-sm btn-group-justified"
          role="group"
          style="margin-bottom: 3px"
        >
          <div class="btn-group btn-group-sm" role="group">
            <button
              style="padding-left: 2px; padding-right: 2px"
              type="button"
              class="btn btn-default"
              title="{{ buttonHints[0] }}"
              (click)="setHours(1)"
            >
              {{ buttonTitles[0] }}
            </button>
          </div>

          <div class="btn-group btn-group-sm" role="group">
            <button
              style="padding-left: 2px; padding-right: 2px"
              type="button"
              class="btn btn-default"
              title="{{ buttonHints[1] }}"
              (click)="setHours(2)"
            >
              {{ buttonTitles[1] }}
            </button>
          </div>

          <div class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              class="btn btn-default"
              title="{{ buttonHints[2] }}"
              (click)="setHours(3)"
            >
              {{ buttonTitles[2] }}
            </button>
          </div>

          <div class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              class="btn btn-default"
              title="{{ buttonHints[3] }}"
              (click)="setHours(4)"
            >
              {{ buttonTitles[3] }}
            </button>
          </div>

          <div class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              class="btn btn-default"
              title="{{ buttonHints[4] }}"
              (click)="setHours(5)"
            >
              {{ buttonTitles[4] }}
            </button>
          </div>

          <div class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              class="btn btn-default"
              title="{{ buttonHints[5] }}"
              (click)="setHours(6)"
            >
              {{ buttonTitles[5] }}
            </button>
          </div>

          <div *ngIf="showStartButton" class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              [disabled]="swExecuted"
              class="btn btn-default"
              title="{{ 'stopwatch.actions.start.hint' | translate }}"
              (click)="start()"
              data-test="startStopwatch"
            >
              <span class="bi bi-play-fill"></span>
            </button>
          </div>
          <div *ngIf="showStopButton" class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              id="action-stop-stopwatch"
              [disabled]="swExecuted"
              class="btn btn-default"
              title="{{ 'stopwatch.actions.stop.hint' | translate }}"
              (click)="stop('Increment')"
            >
              <span class="bi bi-stop-fill" [ngClass]="{ gray: isPause, red: !isPause }"></span>
            </button>
          </div>
        </div>

        <wp-custom-fields
          [formGroup]="form"
          [fields]="timesheetCardService.allocationCustomFields"
          columnClasses="col-12"
        />
      </ng-container>
    }

    @if (issueMode) {
      @if (issuesForm.controls.length) {
        @for (timeAllocation of issueTimeAllocations; track $index) {
          <div class="issue-entry" [formGroup]="issuesForm.at($index)">
            <div class="issue-entry__header">
              <a
                uiSref="issue"
                [uiParams]="{
                  entityId: timeAllocation.issue.id,
                  navigation: navigationService.selectedNavigationItem?.name,
                  routeMode: routeMode.continue,
                  route: navigationService.storedRoute
                }"
              >
                {{ timeAllocation.issue.name }}
              </a>
              <div>
                <span style="white-space: nowrap">{{ timeAllocation.hours | wpWork }}</span>
                <!-- <button
                class="btn btn-icon btn-small"
                (click)="deleteTimeEntry(timeAllocation.id, timeAllocation.hours, $index)"
              >
                <i class="bi bi-trash"></i>
              </button> -->
              </div>
            </div>

            <div class="issue-entry__body">
              <div class="issue-entry__description">
                {{ timeAllocation.description }}
              </div>
              <!-- <wp-custom-fields
                [formGroup]="issuesForm.at($index)"
                [fields]="timesheetCardService.allocationCustomFields"
                [isVisibleHr]="false"
                class="feed-item__custom-fields"
              /> -->
            </div>
          </div>
        }
      } @else {
        <p class="text-body-secondary text-uppercase text-center mt-1 mb-1">
          {{ 'shared.empty' | translate }}
        </p>
      }
    }
  </div>
}
