import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {
  CurrencyPipe,
  DATE_PIPE_DEFAULT_OPTIONS,
  DatePipe,
  DatePipeConfig,
  DecimalPipe,
  PercentPipe,
} from '@angular/common';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
  NgZone,
} from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { UIRouterModule, UrlService } from '@uirouter/angular';
import { NgIdleModule } from '@ng-idle/core';
import { BlockUIModule } from 'ng-block-ui';
import { HotkeyModule } from 'angular2-hotkeys';

import { ApiAuthInterceptor } from './core/interceptors/api-auth.interceptor';
import { ApiResponseInterceptor } from './core/interceptors/api-response.interceptor';
import { AppService } from './core/app.service';
import { NavigationService } from './core/navigation.service';
import { NotificationService } from './core/notification.service';
import { APP_STATES } from './app.states';

import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AnalyticsModule } from './analytics/analytics.module';
import { SettingsAppModule } from './settings-app/settings-app.module';

import { BooleanPipe } from './shared/pipes/boolean.pipe';
import { WorkPipe } from './shared/pipes/work.pipe';
import { BlockUIComponent } from './shared/components/chrome/block-ui/block-ui.component';
import { DebugAppModule } from './debug-app/debug-app.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TimeDurationPipe } from './shared/pipes/time-duration.pipe';
import { CustomFieldValuePipe } from './shared/pipes/custom-field-value.pipe';
import { StopwatchService } from './core/stopwatch.service';
import { environment } from 'src/environments/environment';
import { SharedFeaturesModule } from './shared-features/shared-features.module';
import { QuickStartComponent } from './shared-features/quick-start/quick-start.component';
import { CookieService } from 'ngx-cookie-service';
import configData from '../../config.json';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FinancePipe } from './shared/pipes/finance.pipe';
import { WpCurrencyPipe } from './shared/pipes/currency.pipe';
import { DurationPipe } from './shared/pipes/duration.pipe';
import { ZeroToNullPipe } from './shared/pipes/zero-to-null.pipe';
import { UnsignedPipe } from './shared/pipes/unsigned-pipe';
import { PercentPointPipe } from 'src/app/shared/pipes/percent-point.pipe';
import { UserNotificationService } from 'src/app/core/user-notification.service';
import { WorkflowTasksModule } from './workflow-tasks/workflow-tasks.module';
import { CertificatesModule } from 'src/app/certificates/certificates.module';
import { BookingScheduleModule } from 'src/app/booking/booking-schedule/booking-schedule.module';
import { ResourceRequestsModule } from 'src/app/resource-requests/resource-requests.module';
import { ProjectSummaryModule } from 'src/app/project-summary/project-summary.module';
import { ExpensesRequestsModule } from 'src/app/expenses-requests/expenses-requests.module';
import { TimesheetsModule } from 'src/app/timesheets/timesheets.module';
import { TimeOffRequestsModule } from 'src/app/time-off-requests/time-off-requests.module';
import { TimeOffScheduleModule } from 'src/app/time-off-schedule/time-off-schedule.module';
import { TimeOffBalanceEntriesModule } from 'src/app/time-off-balance-entries/time-off-balance-entries.module';
import { ActsOfAcceptanceModule } from 'src/app/acts-of-acceptance/acts-of-acceptance.module';
import { FinanceModule } from 'src/app/finance/finance.module';
import { BillingModule } from 'src/app/billing/billing.module';
import { ClientsModule } from 'src/app/clients/clients.module';
import { ProjectsModule } from 'src/app/projects/projects.module';
import { GeneralPnlModule } from 'src/app/general-pnl/general-pnl.module';
import { NgxNl2brPipe } from 'ngx-nl2br';
import { SortablejsModule } from 'nxt-sortablejs';
import { WorkflowInstancesModule } from 'src/app/workflow-instances/workflow-instances.module';
import { ResourceSummaryModule } from 'src/app/resource-summary/resource-summary.module';
import { ProgramsModule } from 'src/app/programs/programs.module';
import { ResourcesModule } from 'src/app/resources/resources.module';
import { EmployeesModule } from 'src/app/employees/employees.module';
import { BoardsModule } from 'src/app/boards/boards.module';
import { IssuesModule } from 'src/app/issues/issues.module';
import { ContactsModule } from 'src/app/contacts/contacts.module';
import { DealsModule } from './deals/deal.module';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';

export function initializeApp(
  appService: AppService,
  urlService: UrlService,
  zone: NgZone,
) {
  return () =>
    appService.start().then(() => {
      // Запустить маршрутизатор.

      zone.runOutsideAngular(() => {
        urlService.listen();
      });
      urlService.sync();
    });
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    `.json?v=${configData.version}`,
  );
}

export function getLanguage(appService: AppService) {
  return appService.currentCulture;
}

export function getDefaultCurrencyCode(appService: AppService) {
  return appService.session.configuration.baseCurrencyCode;
}

@NgModule({
  declarations: [AppComponent, BlockUIComponent, QuickStartComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    SortablejsModule.forRoot({ animation: 0 }),
    HttpClientModule,
    NgbModule,
    NgIdleModule.forRoot(),
    HotkeyModule.forRoot(),
    BlockUIModule.forRoot({
      template: BlockUIComponent,
      delayStart: 250,
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'wp',
      separator: '_',
      caseSensitive: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    UIRouterModule.forRoot({
      states: APP_STATES,
      useHash: false,
      deferIntercept: true,
      otherwise: { state: 'start' },
    }),
    SharedModule,
    SharedFeaturesModule,
    ProjectsModule,
    ProgramsModule,
    ClientsModule,
    ContactsModule,
    DealsModule,
    BillingModule,
    AnalyticsModule,
    SettingsAppModule,
    WorkflowTasksModule,
    IssuesModule,
    BoardsModule,
    CertificatesModule,
    EmployeesModule,
    ResourcesModule,
    BookingScheduleModule,
    ResourceRequestsModule,
    ResourceSummaryModule,
    ProjectSummaryModule,
    ExpensesRequestsModule,
    TimesheetsModule,
    TimeOffRequestsModule,
    TimeOffScheduleModule,
    TimeOffBalanceEntriesModule,
    ActsOfAcceptanceModule,
    FinanceModule,
    GeneralPnlModule,
    WorkflowInstancesModule,
    ...(environment.production ? [] : [DebugAppModule]),
    ServiceWorkerModule.register('service-worker.js', {
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    AppService,
    NotificationService,
    BooleanPipe,
    DatePipe,
    WorkPipe,
    PhonePipe,
    DurationPipe,
    WpCurrencyPipe,
    ZeroToNullPipe,
    UnsignedPipe,
    CustomFieldValuePipe,
    TimeDurationPipe,
    DecimalPipe,
    CurrencyPipe,
    FinancePipe,
    PercentPipe,
    PercentPointPipe,
    NgxNl2brPipe,
    StopwatchService,
    NavigationService,
    UserNotificationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppService, UrlService, NgZone, NavigationService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [AppService],
      useFactory: getLanguage,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      deps: [AppService],
      useFactory: getDefaultCurrencyCode,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true,
    },

    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
