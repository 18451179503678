<tmt-form-header [name]="name$ | async"></tmt-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>

<form *ngIf="state == 'Ready'" class="form d-block mt-4" style="width: 600px">
  <div class="form-group">
    <label>{{ 'settings.loginLog.card.props.created' | translate }}</label>
    <div class="form-control-plaintext">
      {{ loginLogEntry.created | date: 'medium' : this.appService.session.timeZoneOffset }}
    </div>
  </div>

  <div class="form-group">
    <label>{{ 'settings.loginLog.card.props.user' | translate }}</label>
    <div class="form-control-plaintext">
      @if (loginLogEntry.user?.name) {
        {{ loginLogEntry.user?.name }}
      } @else {
        {{ 'shared.empty' | translate }}
      }
    </div>
  </div>

  <div class="form-group">
    <label>{{ 'settings.loginLog.card.props.authProvider' | translate }}</label>
    <div class="form-control-plaintext">
      @if (loginLogEntry.authProvider?.name) {
        {{ loginLogEntry.authProvider?.name }}
      } @else {
        {{ 'shared.empty' | translate }}
      }
    </div>
  </div>

  <div class="form-group">
    <label>{{ 'settings.loginLog.card.props.ipAddress' | translate }}</label>
    <div class="form-control-plaintext">
      {{ loginLogEntry.ipAddress }}
    </div>
  </div>

  <div class="form-group">
    <label>{{ 'settings.loginLog.card.props.userAgent' | translate }}</label>
    <div class="form-control-plaintext">
      {{ loginLogEntry.userAgent }}
    </div>
  </div>

  <div class="form-group">
    <label>{{ 'settings.loginLog.card.props.isSuccessful' | translate }}</label>
    <div class="form-control-plaintext">
      {{ loginLogEntry.isSuccessful | wpBoolean }}
    </div>
  </div>
</form>
