import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { AppService } from 'src/app/core/app.service';
import { Deal } from 'src/app/deals/model/deal.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

@Component({
  selector: 'tmt-deal-create-modal',
  templateUrl: './deal-create-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealCreateModalComponent {
  private _isSaving = signal<boolean>(false);
  public isSaving = computed(this._isSaving);
  public creationForm: UntypedFormGroup;
  public baseCurrencyCode: string;
  public readonly appService = inject(AppService);

  private readonly activeModal = inject(NgbActiveModal);
  private readonly customFieldService = inject(CustomFieldService);
  private readonly dataService = inject(DataService);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly notificationService = inject(NotificationService);
  private readonly stateService = inject(StateService);

  constructor() {
    this.creationForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      organizationId: null,
      manager: null,
    });
    this.baseCurrencyCode =
      this.appService.session.configuration.baseCurrencyCode;
    this.creationForm.patchValue({ manager: this.appService.session.user });
  }

  /** Modal submit handler. */
  public ok(): void {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.customFieldService.enrichFormGroup(this.creationForm, 'Deal');
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.creationForm,
      'Deal',
    );

    this._isSaving.set(true);

    const creationFormValue = this.creationForm.value;
    const dealData: Partial<Deal> = {
      name: creationFormValue.name,
      organizationId: creationFormValue.organizationId,
      managerId: creationFormValue.manager?.id,
    };

    this.customFieldService.assignValues(dealData, creationFormValue, 'Deal');

    this.dataService
      .collection('Deals')
      .insert(dealData)
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'components.createDealModalComponent.messages.created',
          );
          this.stateService.go('deal', {
            entityId: response.id,
          });
          this._isSaving.set(false);
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this._isSaving.set(false);
        },
      });
  }

  /** Modal cancel handler. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
