<form [formGroup]="boardCardService.form" class="form mt-3">
  <div class="form-group" tmtIndicator>
    <label>{{ 'shared2.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared2.props.name' | translate }}" />
  </div>

  <div class="form-group" tmtIndicator>
    <label class="control-label">{{
      'components.boardsListComponent.props.entityType.value' | translate
    }}</label>
    <wp-text-box formControlName="entityType" type="text" />
  </div>

  <div class="form-check" title="{{ 'shared2.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared2.props.isActive.value' | translate }}
    </label>
  </div>

  <div class="form-group">
    <label class="group mb-0 mt-3">
      {{ 'components.boardCardPermissionsComponent.groups.header' | translate }}
    </label>
    <tmt-performers
      class="p-0"
      [performerLines]="boardCardService.form.get('team')"
      [readonly]="boardCardService.readonly()"
      [predefinedRoles]="[{ id: 'All', name: 'shared2.props.roleAll' | translate }]"
      [gridHeader]="'components.boardCardPermissionsComponent.groups.gridHeader' | translate"
    />
  </div>

  <tmt-navigation-items
    [readonly]="boardCardService.readonly()"
    [navigations]="boardCardService.form.get('navigations')"
    [navigationResponse]="boardCardService.navigationResponse"
  />
</form>
