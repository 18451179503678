<block-ui>
  <div class="h-100" [ngClass]="{ 'compact-mode': compactMode }">
    <nav
      id="navbar"
      class="navbar fixed-top navbar-expand navbar-dark"
      [class.new-year]="newYearForm.value.background"
    >
      <!-- New year component -->
      @if (newYearForm.value.garland) {
        <tmt-garland />
      }

      <div class="navbar-brand" (click)="showAppMenu($event)" id="navbarTitle">
        <i class="bi bi-list" aria-hidden="true"></i>
        <span>{{ moduleDisplayName }}</span>
      </div>
      <div id="navbarLine" class="navbar-collapse h-100">
        <tmt-route-navigator />

        <ul class="navbar-nav ms-auto h-100">
          <!-- New year dropdown -->
          <li ngbDropdown [autoClose]="'outside'" class="nav-item nav-item--new-year">
            <button ngbDropdownToggle class="btn btn--new-year">
              <span
                [class.shine-animation]="!newYearForm.value.garland && newYearForm.value.background"
                >🎄</span
              >
            </button>
            <form
              ngbDropdownMenu
              aria-labelledby="navbarDropdown"
              [formGroup]="newYearForm"
              class="dropdown-menu dropdown-menu-end"
            >
              <div ngbDropdownItem class="form-check">
                <input
                  formControlName="background"
                  type="checkbox"
                  class="form-check-input"
                  id="background"
                />
                <label class="form-check-label" for="background">
                  {{ 'shared.christmas.background' | translate }}
                </label>
              </div>

              <div ngbDropdownItem class="form-check">
                <input formControlName="snow" type="checkbox" class="form-check-input" id="snow" />
                <label class="form-check-label" for="snow">
                  {{ 'shared.christmas.snow' | translate }}
                </label>
              </div>

              <div ngbDropdownItem class="form-check">
                <input
                  formControlName="garland"
                  type="checkbox"
                  class="form-check-input"
                  id="garland"
                />
                <label class="form-check-label" for="garland">
                  {{ 'shared.christmas.garland' | translate }}
                </label>
              </div>

              <div ngbDropdownItem class="form-check">
                <input
                  formControlName="firework"
                  type="checkbox"
                  class="form-check-input"
                  id="firework"
                />
                <label class="form-check-label" for="firework">
                  {{ 'shared.christmas.firework' | translate }}
                </label>
              </div>
            </form>
          </li>

          <!--Toggle theme button -->
          <!-- <li class="nav-item h-100 create-menu">
            <a class="h-100" role="button" (click)="toggleTheme()">
              <i class="bi bi-moon" aria-hidden="true"> </i>
            </a>
          </li> -->

          <!--Create menu-->
          <li
            class="nav-item h-100 create-menu"
            ngbDropdown
            *ngIf="(createMenuService.items$ | async).length"
          >
            <a class="h-100" ngbDropdownToggle role="button">
              <i class="bi bi-plus-lg" aria-hidden="true"> </i>
            </a>

            <div
              ngbDropdownMenu
              aria-labelledby="navbarDropdown"
              class="dropdown-menu dropdown-menu-end"
            >
              <button
                ngbDropdownItem
                *ngFor="let item of createMenuService.items$ | async"
                (click)="item.handle()"
                [attr.data-test]="item.name"
              >
                {{ item.title }}
              </button>
            </div>
          </li>

          <!--Notifications-->
          <wp-user-notification-indicator />

          <!--Stopwatch-->
          <wp-stopwatch-indicator />

          <!--Help menu-->
          <li class="nav-item help-menu" ngbDropdown>
            <a class="align-items-center" ngbDropdownToggle role="button">
              <i class="bi bi-question" aria-hidden="true"></i>
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="navbarDropdown"
              class="dropdown-menu dropdown-menu-end"
            >
              <a class="dropdown-item" href="{{ 'shared.helpUrl' | translate }}" target="_blank">{{
                'shared.helpMenu.help' | translate
              }}</a>

              @if (appConfigService.config.beacon) {
                <button ngbDropdownItem (click)="openHelp()">
                  {{ 'shared.helpMenu.askQuestion' | translate }}
                </button>
              }

              <button ngbDropdownItem (click)="app.resetApp()">
                {{ 'shared.helpMenu.reset' | translate }}
              </button>

              <div class="dropdown-divider"></div>

              <div class="dropdown-item-text">
                {{ 'shared.helpMenu.build' | translate }}: {{ appConfigService.config.version }}
              </div>
            </div>
          </li>

          <!--User menu-->
          <li class="nav-item user-menu" ngbDropdown>
            <a class="nav-link" ngbDropdownToggle role="button">
              <span
                *ngIf="authService.substitutedEmail"
                title="{{ 'shared.substituteModeIsActive' | translate }}"
                class="bi bi-arrow-left-right"
              >
              </span>
              <img [src]="session.user.id | avatar: 60" class="avatar" />
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="navbarDropdown"
              class="dropdown-menu dropdown-menu-end"
            >
              <p class="dropdown-header trim py-0">
                {{ session.configuration.displayName }}<br />
                {{ session.user.name }}<br />
                {{ session.user.email }}
              </p>
              <div class="dropdown-divider"></div>

              <button ngbDropdownItem (click)="userSettings()" data-test="userSettings">
                {{ 'shared.userMenu.mySettings' | translate }}
              </button>
              <button ngbDropdownItem (click)="loginAsSubstitute()" data-test="loginAsSubstitute">
                {{ 'shared.userMenu.loginAs' | translate }}
              </button>

              <button
                ngbDropdownItem
                *ngIf="authService.substitutedEmail"
                (click)="stopSubstituting()"
                data-test="stopSubstituting"
              >
                {{ 'shared.userMenu.stopSubstituting' | translate }}
              </button>

              <div class="dropdown-divider"></div>
              <button ngbDropdownItem (click)="signOut()" data-test="signOut">
                {{ 'shared.userMenu.signOut' | translate }}
              </button>
            </div>
          </li>
        </ul>
      </div>

      <wp-app-menu (closed)="onAppMenuClosed()" [isClosed]="!isAppMenuVisible" class="app-menu" />
    </nav>

    <div class="h-100 content" (resized)="onMainResized($event)">
      <wp-navigation-panel />
      <tmt-action-panel />
      <div id="main-area" class="main" #appContent>
        <wp-notification />
        <ui-view />
      </div>
    </div>
  </div>
  <wp-stopwatch-popup />
  <tmt-popup-container />
</block-ui>
