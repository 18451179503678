<div tmtFileBoxDnDZone [enabled]="editAllowed && !isContainerMode">
  <div class="file__wrapper file__wrapper--{{ mode }}">
    @if (isAddButtonShown && mode === 'inline' && !isContainerMode && editAllowed) {
      <tmt-file-box-button-add [mode]="mode" />
    }

    @for (file of filesService.files(); track file.id) {
      <wp-loading-indicator
        [loading]="file.dataReadyStatus === 'loading'"
        [isOverlay]="true"
        [isSpinnerMode]="true"
        [spinnerParams]="spinnerParams"
      >
        <button
          @init
          class="file__item btn btn-default"
          [class.file__item--to-remove]="file.afterSaveAction === 'delete'"
          [title]="file.name"
          (click)="openViewer(file.id)"
        >
          <div class="file__item-preview">
            @if (file.template === 'image' && mode === 'preview') {
              <img [src]="file.urlPreview" [alt]="file.name" />
            } @else {
              <i [class]="file.icon"></i>
            }
          </div>

          <a class="file__item-title trim">
            {{ file.shortName }}
          </a>

          <div class="file__item-date">
            {{ file.created | date: 'd MMM' }}
          </div>

          @if (file.dataReadyStatus === 'success') {
            <div class="file__item-action-result text-success">
              <i class="bi bi-check-circle-fill"></i>
            </div>
          }

          @if (file.dataReadyStatus === 'fail') {
            <div class="file__item-action-result text-danger" [ngbTooltip]="file.message">
              <i class="bi bi-x-circle-fill"></i>
            </div>
          }

          <div class="file__item-actions">
            @if (file.afterSaveAction === 'delete') {
              <button
                class="btn btn-sm btn-default"
                [title]="'shared2.actions.abort' | translate"
                (click)="action($event, file, 'abortRemove')"
                data-test="abort"
              >
                <i class="bi bi-arrow-clockwise"></i>
              </button>
            } @else {
              @if (editAllowed) {
                <button
                  class="btn btn-sm btn-default"
                  [title]="'shared2.actions.delete' | translate"
                  (click)="action($event, file, 'remove')"
                  data-test="delete"
                >
                  <i class="bi bi-trash"></i>
                </button>
              }

              <button
                class="btn btn-sm btn-default"
                [title]="'shared2.actions.save' | translate"
                (click)="action($event, file, 'save')"
                data-test="save"
              >
                <i class="bi bi-download"></i>
              </button>
            }
          </div>
        </button>
      </wp-loading-indicator>
    }

    @if (!filesService.files().length && !editAllowed && !isContainerMode) {
      <p class="text-body-secondary text-uppercase">
        {{ 'components.fileBoxComponent.props.empty' | translate }}
      </p>
    }

    @if (isAddButtonShown && mode === 'preview' && !isContainerMode && editAllowed) {
      <tmt-file-box-button-add [mode]="mode" />
    }
  </div>
</div>
