<div class="btn-group" role="group">
  @for (
    mode of [
      { isForecast: true, label: 'forecast' },
      { isForecast: false, label: 'estimate' }
    ];
    track mode.isForecast
  ) {
    <div
      [class.active]="projectResourceDataService.isForecastMode === mode.isForecast"
      (click)="setViewMode(mode.isForecast)"
      class="btn btn-default"
    >
      {{ 'projects.projects.card.resources.view.' + mode.label | translate }}
    </div>
  }
</div>
