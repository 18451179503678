<wp-loading-indicator [loading]="isLoading" [isSpinnerMode]="true" />
<div class="message" *ngIf="hasMessage">
  <i class="bi bi-bar-chart" aria-hidden="true"></i><br />
  <p class="text-uppercase">{{ message }}</p>
</div>

<div
  class="chart"
  [hidden]="isLoading || widget.type === 'Value' || widget.type === 'DoubleValue' || hasMessage"
  [attr.id]="chartId"
></div>

<div *ngIf="widget.type === 'Value'" [hidden]="isLoading || hasMessage" class="single-value">
  <div class="value trim" title="{{ value }}">
    <div class="value-number">{{ value }}</div>
    <div class="value-abbreviation">{{ abbreviation }}</div>
  </div>
  <div class="title trim" title="trim">{{ fieldTitle }}</div>
</div>

<div *ngIf="widget.type === 'DoubleValue'" [hidden]="isLoading || hasMessage" class="double-value">
  <div class="row">
    <div class="col-6">
      <div class="value trim" [title]="valueFirst + ' ' + abbreviation">
        <div class="value-number">{{ valueFirst }}</div>
        <div class="value-abbreviation">{{ abbreviation }}</div>
      </div>
      <div class="title trim">{{ fieldTitleFirst }}</div>
    </div>
    <div class="col-6">
      <div class="value trim" [title]="valueSecond + ' ' + abbreviation">
        <div class="value-number">{{ valueSecond }}</div>
        <div class="value-abbreviation">{{ abbreviation }}</div>
      </div>
      <div class="title trim">{{ fieldTitleSecond }}</div>
    </div>
  </div>

  <ngb-progressbar
    type="info"
    class="mt-3"
    [value]="proportion"
    max="1"
    *ngIf="widget.properties.showProportion"
  >
    {{ proportion | percent: '1.0-2' }}
  </ngb-progressbar>
</div>
