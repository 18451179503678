/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class InteractionsFilterService extends FilterService {
  public override hasDetails = false;
  public override hasViewSelector = false;
  public override placeholder =
    'components.interactionsFilterService.filter.textInput';

  public override getODataFilter(): any {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push(
        this.getTextFilter(
          [
            'description',
            'performer/name',
            'deal/name',
            'organization/name',
            'type/name',
          ],
          this.getClearText(text),
        ),
      );

      result[0].or.push({
        interactionContacts: {
          any: {
            contact: { 'tolower(name)': { contains: text } },
          },
        },
      });
    }

    return result;
  }

  protected override getDefaultValues(): any {
    return {
      text: '',
    };
  }
}
