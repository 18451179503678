<form [formGroup]="service.form" class="accounting-entry-container">
  <div class="d-flex">
    <div class="form-group">
      <label>
        {{ 'finance.entries.card.properties.type' | translate }}
      </label>
      <div class="clearfix"></div>

      <div class="btn-group btn-group-toggle" radioGroup name="radioBasic" formControlName="typeId">
        <label radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" [value]="financialAccountType.revenue.id" />
          <i class="bi bi-arrow-right bi-15 pe-2"></i>
          {{ financialAccountType.revenue.name | translate }}
        </label>

        <label radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" [value]="financialAccountType.expenses.id" />
          <i class="bi bi-arrow-left bi-15 pe-2"></i>
          {{ financialAccountType.expenses.name | translate }}
        </label>
      </div>
    </div>
  </div>

  <!-- Financial Account -->
  <div class="form-group" tmtIndicator>
    <label>
      {{ 'finance.entries.card.properties.account' | translate }}
    </label>
    <wp-select-box
      [allowNull]="false"
      [values]="service.accounts$"
      placeholder="{{ 'finance.entries.card.properties.account' | translate }}"
      formControlName="account"
    />
  </div>

  <!-- Date & Amount -->
  <div class="d-flex" tmtIndicator>
    <div class="form-group">
      <label>
        {{ 'finance.entries.card.properties.date' | translate }}
      </label>
      <wp-date-box
        [allowNull]="false"
        [excludePeriod]="service.closedPeriod"
        formControlName="date"
      />
    </div>

    <div class="form-group ms-4" tmtIndicator>
      <label class="me-2">
        {{ 'finance.entries.card.properties.amount' | translate }}
      </label>
      <i
        class="group-hint bi bi-question-circle text-gray ms-auto"
        [ngbTooltip]="'finance.entries.card.hints.amount' | translate"
      ></i>
      <wp-currency-box
        formControlName="amount"
        [allowNull]="false"
        [isCurrencyEditable]="false"
        [min]="service.minAllowedNumber"
      />
    </div>

    <div class="form-group ms-4">
      <label>
        {{ 'finance.entries.card.properties.amountBC' | translate }}
      </label>
      <wp-number-box
        formControlName="amountBC"
        type="currency"
        [allowNull]="false"
        [readonly]="true"
        [min]="service.minAllowedNumber"
      />
    </div>
  </div>

  <!-- Creation Date & Mode -->
  @if (service.mode !== 'create') {
    <div class="d-flex">
      <div class="form-group">
        <label>
          {{ 'shared.props.created' | translate }}
        </label>
        <wp-date-box [allowNull]="false" [readonly]="true" formControlName="created" />
      </div>

      <div class="form-group ms-4">
        <label>
          {{ 'finance.entries.card.properties.mode' | translate }}
        </label>
        <wp-select-box formControlName="mode" [readonly]="true" />
      </div>
    </div>
  }

  <!-- Project -->
  @if (!(service.isTimeOffLaborCost$ | async)) {
    <div class="form-group" tmtIndicator>
      <label>
        {{ 'finance.entries.card.properties.project' | translate }}
      </label>
      <wp-select-box
        [allowNull]="false"
        collection="Projects"
        [query]="service.projectsQuery"
        placeholder="{{ 'finance.entries.card.properties.project' | translate }}"
        formControlName="project"
      />
    </div>
  }

  <!-- Stage (Task) -->
  @if (!(service.isTimeOffLaborCost$ | async)) {
    <div class="form-group">
      <label>
        {{ 'finance.entries.card.properties.task' | translate }}
      </label>
      <tmt-task-box
        [allowNull]="false"
        [projectId]="service.form.value.project?.id"
        placeholder="{{ 'finance.entries.card.properties.task' | translate }}"
        formControlName="projectTask"
      />
    </div>
  }

  <!-- TimeOff type -->
  @if (service.isTimeOffLaborCost$ | async) {
    <div class="form-group" tmtIndicator>
      <label>
        {{ 'finance.entries.card.properties.timeOffType' | translate }}
      </label>
      <wp-select-box
        collection="timeOffTypes"
        placeholder="{{ 'finance.entries.card.properties.timeOffType' | translate }}"
        formControlName="timeOffType"
      />
    </div>
  }

  <!-- User, Hours & Cost manual -->
  @if ((service.entryMode$ | async).code === accountingEntryMode.manual.code) {
    <ng-container>
      @if ((service.financialAccountType$ | async) === financialAccountType.expenses.id) {
        <div class="d-flex gap-4">
          <div class="form-group user-form-group">
            <label>
              {{ 'finance.entries.card.properties.user' | translate }}
            </label>
            <wp-select-box
              collection="Users"
              placeholder="{{ 'finance.entries.card.properties.user' | translate }}"
              formControlName="user"
              [readonly]="!(this.service.isUserEditable$ | async)"
            />
          </div>

          @if ((service.isLaborCost$ | async) || (service.isTimeOffLaborCost$ | async)) {
            <div class="form-group number-form-group">
              <label>
                {{ 'finance.entries.card.properties.hours' | translate }}
              </label>
              <wp-number-box
                [allowNull]="false"
                [readonly]="!(this.service.isUserEditable$ | async)"
                [min]="service.minAllowedNumber"
                formControlName="hours"
                type="work"
              />
            </div>
          }
        </div>
      }
    </ng-container>
  }

  <!-- User, Hours & Cost automatic -->
  @if ((service.entryMode$ | async).code === accountingEntryMode.automatic.code) {
    <ng-container>
      @if ((service.financialAccountType$ | async) === financialAccountType.expenses.id) {
        <div class="d-flex gap-4">
          <div class="form-group select-form-group">
            <label>
              {{ 'finance.entries.card.properties.user' | translate }}
            </label>
            <wp-select-box
              collection="Users"
              placeholder="{{ 'finance.entries.card.properties.user' | translate }}"
              formControlName="user"
              [readonly]="!(this.service.isUserEditable$ | async)"
            />
          </div>

          @if ((service.isLaborCost$ | async) || (service.isTimeOffLaborCost$ | async)) {
            <div class="form-group number-form-group">
              <label>
                {{ 'finance.entries.card.properties.hours' | translate }}
              </label>
              <wp-number-box
                [allowNull]="false"
                [readonly]="true"
                [min]="service.minAllowedNumber"
                formControlName="hours"
                type="work"
              />
            </div>
          }

          @if ((service.isLaborCost$ | async) || (service.isTimeOffLaborCost$ | async)) {
            <div class="form-group number-form-group">
              <label>
                {{ 'finance.entries.card.properties.costRate' | translate }}
              </label>
              <wp-number-box
                [allowNull]="false"
                [readonly]="true"
                formControlName="costRate"
                type="currency"
              />
            </div>
          }
        </div>
      }
    </ng-container>
  }

  <!-- Department -->
  @if ((service.financialAccountType$ | async) === financialAccountType.expenses.id) {
    <div class="form-group">
      <label>
        {{ 'shared2.props.department' | translate }}
      </label>
      <wp-select-box
        collection="Departments"
        placeholder="{{ 'shared2.props.department' | translate }}"
        formControlName="department"
        [readonly]="true"
      />
    </div>
  }

  <!-- Legal entity & Project cost center -->
  @if ((service.financialAccountType$ | async) === financialAccountType.expenses.id) {
    <div class="d-flex gap-1">
      <div class="form-group select-form-group">
        <label>
          {{ 'finance.entries.card.properties.legalEntity' | translate }}
        </label>
        <wp-select-box
          collection="LegalEntities"
          placeholder="{{ 'finance.entries.card.properties.legalEntity' | translate }}"
          formControlName="legalEntity"
          [readonly]="true"
        />
      </div>

      @if (service.isShownProjectCostCenter$ | async) {
        <div class="form-group select-form-group">
          <label>
            {{ 'finance.entries.card.properties.projectCostCenter' | translate }}
          </label>
          <wp-select-box
            [values]="service.projectCostCenters$ | async"
            placeholder="{{ 'finance.entries.card.properties.projectCostCenter' | translate }}"
            formControlName="projectCostCenter"
            [readonly]="(service.entryMode$ | async).code === accountingEntryMode.automatic.code"
          />
        </div>
      }
    </div>
  }

  <!-- Description -->
  <div class="form-group">
    <label>
      {{ 'shared.props.description' | translate }}
    </label>
    <wp-multiline-text-box
      placeholder="{{ 'shared.props.description' | translate }}"
      formControlName="description"
      rows="3"
    />
  </div>

  <!-- Document & Document Date -->
  @if (service.mode !== 'create') {
    <div class="d-flex gap-4">
      <div class="form-group select-form-group">
        <label>
          {{ 'finance.entries.card.properties.document' | translate }}
        </label>

        @if (service.form.value.document?.id) {
          <a
            class="d-inline-block trim w-100 mt-2 mb-0"
            [uiSref]="service.documentLink"
            [uiParams]="{
              entityId: service.form.value.document?.id,
              navigation: navigationService.selectedNavigationItem?.name,
              routeMode: routeMode.continue,
              route: navigationService.storedRoute
            }"
            title="{{ service.form.value.document?.name }}"
            >{{ service.form.value.document?.name }}</a
          >
        }

        @if (!service.form.value.document?.id) {
          <p class="disabled mt-2 mb-0">
            {{ 'shared.noDisplayData' | translate }}
          </p>
        }
      </div>

      <div class="form-group document-date-form-group">
        <label>
          {{ 'finance.entries.card.properties.documentDate' | translate }}
        </label>
        <wp-date-box [allowNull]="false" [readonly]="true" formControlName="documentDate" />
      </div>
    </div>
  }
</form>
