import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
  signal,
  inject,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  firstValueFrom,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { DateTime } from 'luxon';

import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { Guid } from 'src/app/shared/helpers/guid';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { KeysWithName } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

import { WorkLogService } from '../work-log.service';
import { TimesheetProject } from 'src/app/timesheets/card/shared/timesheet-task/timesheet-project.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';

@Component({
  standalone: true,
  imports: [NgTemplateOutlet, TranslateModule, SharedModule],
  selector: 'tmt-work-log-card',
  styleUrl: './work-log-card.component.scss',
  templateUrl: './work-log-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkLogCardComponent implements OnInit {
  @Input() public timeEntry: Partial<TimeAllocation>;
  @ViewChild('tasksSelect') tasksSelect;
  @Input() public readonly = false;

  public isSaving = signal<boolean>(false);
  public isLoading = signal<boolean>(true);
  public availableFields = signal<KeysWithName<TimeAllocation>[]>([]);
  public availableCustomFields = signal<MetaEntityBaseProperty[]>([]);
  public form: UntypedFormGroup = this.fb.group({
    id: Guid.generate(),
    user: [null, Validators.required],
    date: DateTime.now().toISODate(),
    hours: [null, [Validators.required, Validators.min(0.01)]],
    project: null,
    projectTask: null,
    projectTariff: null,
    projectCostCenter: null,
    activity: null,
    role: null,
    description: null,
  });
  public projects: TimesheetProject[];
  public tasks: ProjectTask[];

  private readonly destroyRef = inject(DestroyRef);

  private get formValues(): any {
    return this.form.getRawValue();
  }
  private templateId: string;

  constructor(
    public workLogService: WorkLogService,
    private appService: AppService,
    private dataService: DataService,
    private customFieldService: CustomFieldService,
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.customFieldService.enrichFormGroup(this.form, 'TimeAllocation');
    const userControl = this.form.get('user');

    if (this.timeEntry) {
      this.templateId = this.timeEntry.template?.id;
      const result = await firstValueFrom(
        this.workLogService.timeEntryCollection
          .entity(this.timeEntry.id)
          .get<TimeAllocation>(this.workLogService.timeEntryQuery)
          .pipe(
            catchError((error: Exception) => {
              this.notificationService.error(error.message);
              return of(null);
            }),
          ),
      );

      const [availableFields, availableCustomFields] =
        this.workLogService.getAvailableFields(result.template);
      this.availableFields.set(availableFields);
      this.availableCustomFields.set(availableCustomFields);

      if (result) {
        this.form.patchValue(result);
      } else {
        this.form.disable();
      }
    } else {
      this.form.patchValue({
        user: this.appService.session.user,
        project: this.workLogService.issueProject,
        projectTask: this.workLogService.issueProjectTask,
      });

      const user = await firstValueFrom(
        this.getUser(this.appService.session.user.id),
      );

      this.templateId = user.timeSheetTemplate?.id;

      await this.workLogService.setDefaultValues(
        this.appService.session.user.id,
        this.formValues.project?.id,
        this.form,
        this.availableFields(),
      );
    }

    if (this.readonly || this.workLogService.readonly) {
      this.form.disable();
    }

    userControl.disable();
    userControl.valueChanges
      .pipe(
        tap(() => {
          this.availableFields.set([]);
          this.availableCustomFields.set([]);
        }),
        switchMap((value) => this.getUser(value.id)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((user) => {
        this.workLogService.dependOnUserFields.forEach((key) => {
          this.form.get(key).setValue(null, { emitEvent: null });
        });
        this.workLogService.setDefaultValues(
          user.id,
          this.formValues.project?.id,
          this.form,
          this.availableFields(),
        );
      });

    this.toggleProjectTaskControl(this.formValues['project']);
    this.form
      .get('project')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.workLogService.dependOnProjectFields.forEach((key) => {
          this.form.get(key).setValue(null, { emitEvent: null });
        });

        this.workLogService.setDefaultValues(
          this.formValues.user?.id,
          value?.id,
          this.form,
          this.availableFields(),
        );

        this.loadTasks();
        this.toggleProjectTaskControl(value);
      });

    this.isLoading.set(false);

    this.loadProjects();
  }

  /** Accepts of modal window.*/
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );

      return;
    }

    this.isSaving.set(true);

    const timeEntry: Partial<TimeAllocation> = this.workLogService.prepareData(
      this.formValues,
    );

    if (this.timeEntry) {
      this.updateTimeEntry(timeEntry);
    } else {
      this.createTimeEntry(timeEntry);
    }
  }

  /** Closes modal window without save. */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  private toggleProjectTaskControl(value: any | null): void {
    if (!value || this.readonly || this.workLogService.readonly) {
      this.form.get('projectTask').disable({ emitEvent: null });
      return;
    }

    this.form.get('projectTask').enable({ emitEvent: null });
  }

  private updateTimeEntry(timeEntry: Partial<TimeAllocation>): void {
    this.workLogService.timeEntryCollection
      .entity(this.form.value.id)
      .patch(timeEntry)
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'components.workLogCardComponent.messages.updated',
          );
          this.activeModal.close({ ...timeEntry, id: this.form.value.id });
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  private createTimeEntry(timeEntry: Partial<TimeAllocation>): void {
    this.workLogService.timeEntryCollection.insert(timeEntry).subscribe({
      next: (timeEntry) => {
        this.notificationService.successLocal(
          'components.workLogCardComponent.messages.created',
        );
        this.activeModal.close(timeEntry);
      },
      error: (error: Exception) => {
        this.notificationService.error(error.message);
        this.isSaving.set(false);
      },
    });
  }

  private getUser(userId: string): Observable<Partial<User>> {
    return this.dataService
      .collection('Users')
      .entity(userId)
      .get<Partial<User>>({
        select: ['id', 'name'],
        expand: {
          timeSheetTemplate: {
            select: ['*'],
            expand: {
              customFields: {
                select: ['*'],
              },
            },
          },
        },
      })
      .pipe(
        tap((value) => {
          const [availableFields, availableCustomFields] =
            this.workLogService.getAvailableFields(value.timeSheetTemplate);
          this.availableFields.set(availableFields);
          this.availableCustomFields.set(availableCustomFields);
        }),
      );
  }

  private loadProjects(): void {
    if (!this.formValues.user?.id || !this.templateId) return;

    this.dataService.model
      .function('GetTimeTrackingProjects')
      .get<TimesheetProject[]>(
        {
          userId: this.formValues.user.id,
          timesheetTemplateId: this.templateId,
        },
        { orderBy: 'name' },
      )
      .subscribe({
        next: (data) => {
          if (!data || data.length === 0) {
            this.projects = [];
            return;
          }
          this.projects = [...data];
          this.loadTasks();
        },
        error: (err) => {
          console.error('Error loading projects:', err);
          this.projects = [];
        },
      });
  }

  private loadTasks() {
    if (!this.formValues.project?.id) return;

    this.dataService.model
      .function('GetTimeTrackingProjectTasks')
      .get<ProjectTask[]>({
        userId: this.formValues.user?.id,
        projectId: this.formValues.project?.id,
        timesheetTemplateId: this.templateId,
      })
      .subscribe({
        next: (data) => {
          const sortedData = data
            .sort((a, b) => (+a.structNumber || 0) - (+b.structNumber || 0))
            .map((item) => ({
              ...item,
              name: item.structNumber
                ? `${item.structNumber}. ${item.name}`
                : item.name,
            }));

          this.tasks = sortedData;

          if (this.tasks?.length && this.tasksSelect?.value == null) {
            this.tasksSelect.value = this.tasks[0];
          }
        },
        error: (err) => {
          console.error('Error loading tasks:', err);
          this.tasks = [];
        },
      });
  }
}
