import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { emailValidator } from 'src/app/shared/validators/email';

@Component({
  selector: 'wp-text-box-test',
  templateUrl: './text-box-test.component.html',
})
export class TextBoxTestComponent {
  form = this.fb.group({
    text: ['', Validators.required],
    url: ['', Validators.required],
    phone: [
      '',
      [
        Validators.required,
        Validators.minLength(Constants.minPhoneLength),
        Validators.maxLength(Constants.maxPhoneLength),
      ],
    ],
    email: ['', [Validators.required, emailValidator()]],
  });

  public formInputParams = this.fb.group({
    readonly: [false],
  });

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.patchValue({
      text: 'Test 1',
      url: 'https://timetta.com',
      phone: '+79999999999',
      email: 'test@test.com',
    });
  }
}
