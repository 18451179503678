@if (!isLoading && !notFound && bookingEntryInfo) {
  <div class="booking-info-container">
    <div class="pb-2">
      <p>
        <a
          class="project-name"
          title="{{ bookingEntryInfo.project.name }}"
          (click)="close()"
          uiSref="project"
          [uiParams]="{ entityId: bookingEntryInfo.project.id }"
        >
          {{ bookingEntryInfo.project.name }}
        </a>
      </p>
    </div>
    <div class="row">
      <div class="col-12">
        <div>
          <small class="text-body-secondary">
            {{ 'resources.booking.entryInfo.projectManager' | translate }}
          </small>
          <p>{{ bookingEntryInfo.projectManager.name }}</p>
        </div>
      </div>

      <div class="col-12">
        <div *ngIf="bookingEntryInfo.client?.name">
          <small class="text-body-secondary">
            {{ 'resources.booking.entryInfo.client' | translate }}
          </small>
          <p>{{ bookingEntryInfo.client?.name }}</p>
        </div>
      </div>

      <div class="col-6">
        <div>
          <small class="text-body-secondary">
            {{ 'resources.booking.entryInfo.projectState' | translate }}
          </small>
          <p class="trim">{{ bookingEntryInfo.projectState.name }}</p>
        </div>

        <div>
          <small class="text-body-secondary">
            {{ 'resources.booking.entryInfo.bookingMethod' | translate }}
          </small>
          <p class="trim">{{ bookingEntryInfo.bookingMethod }}</p>
        </div>
      </div>

      <div class="col-6">
        <div>
          <small class="text-body-secondary">
            {{ 'resources.booking.entryInfo.bookingType' | translate }}
          </small>
          <p class="trim">{{ bookingEntryInfo.bookingType }}</p>
        </div>

        <div>
          <small class="text-body-secondary">
            {{ 'resources.booking.entryInfo.bookingPeriod' | translate }}
          </small>
          <p class="trim">{{ bookingEntryInfo.bookingPeriod }}</p>
        </div>
      </div>
    </div>
  </div>
}

@if (notFound) {
  <div class="text-center text-body-secondary text-uppercase">
    {{ 'shared.entityNotFound.title' | translate }}
  </div>
}

<wp-loading-indicator [loading]="isLoading" [isSpinnerMode]="true" />
