@if (formGroup.value.isTaskGroup) {
  <div
    [title]="control.value | wpCurrency: (projectCardService.project$ | async)?.currency.alpha3Code"
  >
    {{ control.value | wpCurrency: (projectCardService.project$ | async)?.currency.alpha3Code }}
  </div>
} @else {
  <wp-number-box
    [allowNull]="false"
    type="currency"
    [formControl]="control"
    [currencyCode]="(projectCardService.project$ | async)?.currency.alpha3Code"
  />
}
