import { Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectResourceSettings } from 'src/app/projects/card/project-resources/models/project-resource-settings.model';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';

@Component({
  selector: 'wp-project-resources-calendar-toggler',
  templateUrl: './project-resources-calendar-toggler.component.html',
  styleUrls: ['./project-resources-calendar-toggler.component.scss'],
})
export class ProjectResourcesCalendarTogglerComponent {
  constructor(
    public routerGlobals: UIRouterGlobals,
    public projectResourceDataService: ProjectResourceDataService,
    public projectResourceService: ProjectResourceService,
    private localConfigService: LocalConfigService,
    private projectCardService: ProjectCardService,
  ) {}

  /**
   * Sets the view mode for the project resources and reinitialize navigationService
   *
   * @param isForecastMode - A boolean indicating whether the forecast mode should be enabled.
   */
  public setViewMode(isForecastMode: boolean): void {
    if (this.projectResourceDataService.isForecastMode === isForecastMode)
      return;

    const settings = this.localConfigService.getConfig(ProjectResourceSettings);
    settings.isForecastMode = isForecastMode;
    this.localConfigService.setConfig(ProjectResourceSettings, settings);

    this.projectResourceDataService.isForecastMode = isForecastMode;
    this.projectResourceService.initNavigationService(isForecastMode);
    this.projectCardService.reloadTab();
  }
}
