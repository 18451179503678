import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-financial-non-nullable-amount-cell',
  templateUrl: './financial-non-nullable-amount-cell.component.html',
  styleUrls: ['./financial-amount-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialNonNullableAmountCellComponent
  implements GridComponentCell
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  constructor(
    public service: GridService,
    public readonly projectCardService: ProjectCardService,
    private cdr: ChangeDetectorRef,
  ) {
    service.detectChanges$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.cdr.detectChanges());
  }
}
