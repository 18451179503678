<div class="task" [style.padding-left]="30 * groupValue.indent + 'px'">
  @if (groupValue.isTaskGroup) {
    <span
      class="bi {{ groupValue.isExpanded ? 'bi-chevron-down' : 'bi-chevron-right' }}"
      (click)="financialTaskCellService.toggleTask(groupValue.originalId)"
    ></span>
    <div class="trim" [title]="getTaskFullName()">
      {{ getTaskFullName() }}
    </div>
  } @else {
    <tmt-task-box
      [formControl]="control"
      [allowNull]="false"
      [projectId]="entityId"
      [projectVersion]="financialTaskCellService.projectVersion"
    />
  }
</div>
