import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { projectResourcesList } from 'src/app/projects/card/project-resources/models/project-resources.list';
import { ProjectResourcesCalendarCommandService } from 'src/app/projects/card/project-resources/core/project-resources-calendar-command.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import { ProjectResourcesGridDataService } from 'src/app/projects/card/project-resources/core/project-resources-grid-data.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { PlannerCommandService } from 'src/app/shared-features/planner/core/planner-command.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectResourceSettings } from 'src/app/projects/card/project-resources/models/project-resource-settings.model';
import { ProjectResourceRightSideComponent } from 'src/app/projects/card/project-resources/project-resource-right-side/project-resource-right-side.component';
import { ProjectResourceToolbarComponent } from 'src/app/projects/card/project-resources/project-resource-toolbar/project-resource-toolbar.component';
import { ResourceTotalCellComponent } from 'src/app/projects/card/project-resources/resource-total-cell/resource-total-cell.component';

@Component({
  selector: 'tmt-project-resources',
  styleUrl: './project-resources.component.scss',
  templateUrl: './project-resources.component.html',
  providers: [
    SavingQueueService,
    ProjectResourceService,
    ProjectResourceDataService,
    ProjectResourcesGridDataService,
    ProjectResourcesCalendarCommandService,
    ScheduleNavigationService,
    PlannerCommandService,

    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: projectResourcesList },
    GridService,
    ListService,
  ],
})
export class ProjectResourcesComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions = {
    resizableColumns: true,
    selectionType: SelectionType.none,
    rightSideComponent: ProjectResourceRightSideComponent,
    toolbar: ProjectResourceToolbarComponent,
    commands: [{ name: 'setUserView', handlerFn: () => this.setUserView() }],
    view: this.listService.getGridView(),
  };

  public readonly totals = {
    general: ResourceTotalCellComponent,
    totalHours: ResourceTotalCellComponent,
  };

  constructor(
    public dataService: ProjectResourceDataService,
    public gridDataService: ProjectResourcesGridDataService,
    public projectResourceService: ProjectResourceService,
    public projectCardService: ProjectCardService,
    private undoRedoService: UndoRedoService,
    private listService: ListService,
    private localConfigService: LocalConfigService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.dataService.undoRedoSessionId = await firstValueFrom(
      this.undoRedoService.startUndoRedoSession(),
    );
    this.dataService.init();
    this.projectResourceService.init(
      this.localConfigService.getConfig(ProjectResourceSettings).isForecastMode,
    );
  }

  public ngOnDestroy(): void {
    this.undoRedoService.abortUndoRedoSession(
      this.dataService.undoRedoSessionId,
    );
  }

  /** Opens view configuration dialog. */
  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
      },
      () => null,
    );
  }
}
