import { ResourceGeneralCellComponent } from 'src/app/projects/card/project-resources/resource-general-cell/resource-general-cell.component';
import { ResourceTotalHoursCellComponent } from 'src/app/projects/card/project-resources/resource-total-hours-cell/resource-total-hours-cell.component';
import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const projectResourcesList: List = {
  name: 'projectResources',
  version: 1,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'general',
          visibleByDefault: true,
          width: 250,
          totalByDefault: TotalType.Component,
        },
        {
          column: 'totalHours',
          visibleByDefault: true,
          width: 250,
          totalByDefault: TotalType.Component,
        },
      ],
    },
  ],
  columns: [
    <GridComponentColumn>{
      name: 'general',
      header: 'components.projectResourcesList.props.general',
      hint: 'components.projectResourcesList.props.general',
      type: GridColumnType.Component,
      component: ResourceGeneralCellComponent,
      readonlyComponent: ResourceGeneralCellComponent,
      total: TotalType.Component,
    },
    <GridComponentColumn>{
      name: 'totalHours',
      header: 'components.projectResourcesList.props.totalHours.short',
      hint: 'components.projectResourcesList.props.totalHours.value',
      type: GridColumnType.Component,
      component: ResourceTotalHoursCellComponent,
      readonlyComponent: ResourceTotalHoursCellComponent,
      total: TotalType.Component,
    },
  ],
  dataColumns: [],
};
