import {
  GridNavigationColumn,
  GridColumnType,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const CLIENT_LIST: List = {
  version: 1,
  name: 'clients',
  dataCollection: 'OrganizationTotals',
  customFieldEntityType: 'Organization',
  customFieldPrefixForDataField: 'organization.',
  views: [
    {
      name: 'my',
      columns: [
        {
          column: 'organizationName',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'organizationCode',
          visibleByDefault: true,
          width: 1,
        },

        {
          column: 'organizationDescription',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'organizationContact',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'organizationContactEmail',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationSite',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationPhone',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectsCount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'organizationIsActive',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationModifiedBy',
          visibleByDefault: false,
          width: 1,
        },

        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'actualCost',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
      ],
      contextFilter: [
        {
          organization: {
            managerId: {
              type: 'guid',
              value: '#user',
            },
          },
        },
      ],
      order: {
        column: 'organizationName',
        reverse: false,
      },
    },
    {
      name: 'active',
      columns: [
        {
          column: 'organizationName',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'organizationCode',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'organizationManager',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'organizationDescription',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'organizationContact',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'organizationContactEmail',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationSite',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationPhone',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectsCount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'organizationIsActive',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationCreated',
          visibleByDefault: false,
          width: 1,
        },

        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'actualCost',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
      ],
      contextFilter: [{ organization: { isActive: true } }],
      order: {
        column: 'organizationName',
        reverse: false,
      },
    },
    {
      name: 'all',
      columns: [
        {
          column: 'organizationName',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'organizationCode',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'organizationManager',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'organizationDescription',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'organizationContact',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'organizationContactEmail',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationSite',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationPhone',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectsCount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'organizationIsActive',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organizationModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualCost',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: {
        column: 'organizationName',
        reverse: false,
      },
    },
  ],

  columns: [
    <GridNavigationColumn>{
      name: 'organizationName',
      availableTotals: [TotalType.Count],
      type: GridColumnType.Navigation,
      state: 'client',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
    },
    {
      name: 'organizationCode',
      type: GridColumnType.String,
      header: 'shared.columns.code',
      hint: 'shared.columns.code',
    },
    {
      name: 'organizationDescription',
      type: GridColumnType.String,
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
    },
    {
      name: 'organizationManager',
      type: GridColumnType.String,
      header: 'projects.clients.list.columns.manager.header',
      hint: 'projects.clients.list.columns.manager.hint',
    },
    {
      name: 'organizationContact',
      type: GridColumnType.String,
      header: 'projects.clients.list.columns.contact.header',
      hint: 'projects.clients.list.columns.contact.hint',
    },
    {
      name: 'organizationContactEmail',
      type: GridColumnType.String,
      header: 'projects.clients.list.columns.email.header',
      hint: 'projects.clients.list.columns.email.hint',
    },
    {
      name: 'organizationSite',
      type: GridColumnType.String,
      header: 'projects.clients.list.columns.website.header',
      hint: 'projects.clients.list.columns.website.hint',
    },
    {
      name: 'organizationPhone',
      type: GridColumnType.String,
      header: 'projects.clients.list.columns.phone.header',
      hint: 'projects.clients.list.columns.phone.hint',
    },
    {
      name: 'projectsCount',
      type: GridColumnType.Integer,
      availableTotals: [TotalType.Sum],
      header: 'projects.clients.list.columns.projectsCount.header',
      hint: 'projects.clients.list.columns.projectsCount.hint',
    },
    {
      name: 'organizationIsActive',
      type: GridColumnType.Boolean,
      header: 'projects.clients.list.columns.isActive.header',
      hint: 'projects.clients.list.columns.isActive.hint',
    },

    {
      name: 'organizationCreated',
      type: GridColumnType.DateTime,
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
    },
    {
      name: 'organizationCreatedBy',
      type: GridColumnType.String,
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
    },
    {
      name: 'organizationModified',
      type: GridColumnType.DateTime,
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
    },
    {
      name: 'organizationModifiedBy',
      type: GridColumnType.String,
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
    },

    {
      name: 'plannedExpenses',
      header: 'projects.projects.list.columns.plannedExpensesBC.header',
      hint: 'projects.projects.list.columns.plannedExpensesBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedExpensesOverPeriod',
      header:
        'projects.projects.list.columns.plannedExpensesOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedExpensesOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualExpenses',
      header: 'projects.projects.list.columns.actualExpensesBC.header',
      hint: 'projects.projects.list.columns.actualExpensesBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualExpensesOverPeriod',
      header:
        'projects.projects.list.columns.actualExpensesOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualExpensesOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    {
      name: 'actualCost',
      header: 'projects.projects.list.columns.actualCostBC.header',
      hint: 'projects.projects.list.columns.actualCostBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualCostOverPeriod',
      header: 'projects.projects.list.columns.actualCostOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualCostOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedRevenue',
      header: 'projects.projects.list.columns.plannedRevenueBC.header',
      hint: 'projects.projects.list.columns.plannedRevenueBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedRevenueOverPeriod',
      header:
        'projects.projects.list.columns.plannedRevenueOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedRevenueOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualRevenue',
      header: 'projects.projects.list.columns.actualRevenueBC.header',
      hint: 'projects.projects.list.columns.actualRevenueBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualRevenueOverPeriod',
      header: 'projects.projects.list.columns.actualRevenueOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualRevenueOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedProfit',
      header: 'projects.projects.list.columns.plannedProfitBC.header',
      hint: 'projects.projects.list.columns.plannedProfitBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedProfitOverPeriod',
      header: 'projects.projects.list.columns.plannedProfitOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedProfitOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualProfit',
      header: 'projects.projects.list.columns.actualProfitBC.header',
      hint: 'projects.projects.list.columns.actualProfitBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualProfitOverPeriod',
      header: 'projects.projects.list.columns.actualProfitOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualProfitOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedProfitability',
      header: 'projects.projects.list.columns.plannedProfitabilityBC.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'plannedProfitabilityOverPeriod',
      header:
        'projects.projects.list.columns.plannedProfitabilityOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityOverPeriodBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'actualProfitability',
      header: 'projects.projects.list.columns.actualProfitabilityBC.header',
      hint: 'projects.projects.list.columns.actualProfitabilityBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'actualProfitabilityOverPeriod',
      header:
        'projects.projects.list.columns.actualProfitabilityOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualProfitabilityOverPeriodBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'estimatedHours',
      header: 'projects.projects.list.columns.estimatedHours.header',
      hint: 'projects.projects.list.columns.estimatedHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'actualHours',
      header: 'projects.projects.list.columns.actualHours.header',
      hint: 'projects.projects.list.columns.actualHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'actualHoursOverPeriod',
      header: 'projects.projects.list.columns.actualHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.actualHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'plannedHours',
      header: 'projects.projects.list.columns.plannedHours.header',
      hint: 'projects.projects.list.columns.plannedHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'plannedHoursOverPeriod',
      header: 'projects.projects.list.columns.plannedHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
  ],

  dataColumns: [
    {
      column: 'organizationContact',
      field: 'organization.contact',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationContactEmail',
      field: 'organization.contactEmail',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationSite',
      field: 'organization.site',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationPhone',
      field: 'organization.phone',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectsCount',
      field: 'projectsCount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationIsActive',
      field: 'organization.isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationCreated',
      field: 'organization.created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationModified',
      field: 'organization.modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationCreatedBy',
      field: 'organization.createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationModifiedBy',
      field: 'organization.modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'organizationName',
      field: 'organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationCode',
      field: 'organization.code',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationDescription',
      field: 'organization.description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organizationManager',
      field: 'organization.manager.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'plannedExpenses',
      field: 'plannedExpenses',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpensesOverPeriod',
      field: 'plannedExpensesOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpenses',
      field: 'actualExpenses',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpensesOverPeriod',
      field: 'actualExpensesOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'actualCost',
      field: 'actualCost',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostOverPeriod',
      field: 'actualCostOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'plannedRevenue',
      field: 'plannedRevenue',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenueOverPeriod',
      field: 'plannedRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenue',
      field: 'actualRevenue',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenueOverPeriod',
      field: 'actualRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfit',
      field: 'plannedProfit',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitOverPeriod',
      field: 'plannedProfitOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfit',
      field: 'actualProfit',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitOverPeriod',
      field: 'actualProfitOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitability',
      field: 'plannedProfitability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitabilityOverPeriod',
      field: 'plannedProfitabilityOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitability',
      field: 'actualProfitability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitabilityOverPeriod',
      field: 'actualProfitabilityOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'estimatedHours',
      field: 'estimatedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHours',
      field: 'actualHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHoursOverPeriod',
      field: 'actualHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedHours',
      field: 'plannedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedHoursOverPeriod',
      field: 'plannedHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
